import { Component, inject } from '@angular/core';
import { NamedDialog } from '../named-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogComponent } from '../dialog-component';
import { Shell } from '../shell';

@NamedDialog('confirm')
@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent extends DialogComponent {
    private domSanitizer = inject(DomSanitizer);

    init(message: string, callback: (confirmed: boolean) => void, options?: any) {
        this.message = this.domSanitizer.bypassSecurityTrustHtml(message);
        this.callback = callback;

        // This can happen when the dialog is restored from history state.
        if (!this.callback) {
            this.close();
            return;
        }

        if (options) {
            this.title = options.title;
            this.confirmText = options.confirmText;
            this.cancelText = options.cancelText;
        }
    }

    message: string | SafeHtml;
    callback: (confirmed: boolean) => void;
    title: string | SafeHtml;
    confirmText: string;
    cancelText: string;

    async confirm() {
        this.callback(true);
        this.close();
    }

    async cancel() {
        this.callback(false);
        this.close();
    }

    get pageComponentId() {
        return 'confirm';
    }
}

Shell.confirmDialogComponent = ConfirmationDialogComponent;