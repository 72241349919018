"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.observeOn = void 0;
var executeSchedule_1 = require("../util/executeSchedule");
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function observeOn(scheduler, delay) {
  if (delay === void 0) {
    delay = 0;
  }
  return lift_1.operate(function (source, subscriber) {
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      return executeSchedule_1.executeSchedule(subscriber, scheduler, function () {
        return subscriber.next(value);
      }, delay);
    }, function () {
      return executeSchedule_1.executeSchedule(subscriber, scheduler, function () {
        return subscriber.complete();
      }, delay);
    }, function (err) {
      return executeSchedule_1.executeSchedule(subscriber, scheduler, function () {
        return subscriber.error(err);
      }, delay);
    }));
  });
}
exports.observeOn = observeOn;
