import { Component, Input, inject } from "@angular/core";

import { ImageService } from './image-service';
import { PreloadService } from './preload.service';
import { UserAgent } from './user-agent';

interface Source {
    srcset: string;
    sizes?: string;
    type?: string;
    media?: string;
    width?: number;
    height?: number;
}

@Component({
    selector: 'picture[responsive]',
    template: `
        @for (src of sizes; track src) {
        <source
            [attr.srcset]="src.srcset"
            [attr.media]="src.media"
            [attr.type]="src.type"
            [attr.width]="src.width"
            [attr.height]="src.height"
            />
        }
        <img [attr.width]="nativeWidth" [attr.height]="nativeHeight" [attr.src]="legacySrc" [attr.alt]="alt" />
        `,
    styles: [`
        :host {
            display: flex;
            height: fit-content;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    `]
})
export class ResponsivePictureComponent {
    private imageService = inject(ImageService);
    private preloadService = inject(PreloadService);
    private userAgent = inject(UserAgent);

    private _src: string;

    legacySrc: string;

    @Input()
    alt: string;

    @Input()
    get src(): string {
        return this._src;
    }

    set src(value) {
        this._src = value;
        setTimeout(() => this.setup(value));
    }

    sizes: Source[];

    @Input()
    widthFactor = 1;

    @Input()
    mobileWidthFactor = 1;

    @Input()
    width: number = undefined;

    @Input() nativeWidth: number = undefined;
    @Input() nativeHeight: number = undefined;

    get aspectRatio() {
        if (!this.nativeWidth || !this.nativeHeight)
            return undefined;
        return this.nativeWidth / this.nativeHeight;
    }

    setSizes(url: string) {
        const sizes = [1920.0, 1280.0, 640.0, 320.0];
        this.sizes = [
            ...sizes.map(width => ({
                srcset: this.imageService.transform(url, { width, format: 'webp' }),
                type: 'image/webp',
                media: `(min-width: ${ width / this.widthFactor }px)`,
                width: this.aspectRatio ? width : undefined,
                height: this.aspectRatio ? width / this.aspectRatio : undefined
            })),
            ...sizes.map(width => ({
                srcset: this.imageService.transform(url, { width }),
                media: `(min-width: ${ width / this.widthFactor }px)`,
                width: this.aspectRatio ? width : undefined,
                height: this.aspectRatio ? width / this.aspectRatio : undefined
            }))
        ];
    }

    async setup(url: string) {
        if (typeof url !== 'string')
            return;

        if (!this.imageService.canBeProcessed(url)) {
            this.legacySrc = url;
            this.sizes = [{ srcset: url }];
            return;
        }

        if (this.width) {
            let format: 'webp' | 'png' | 'jpeg' = undefined;

            if (await this.userAgent.supportsWebP)
                format = 'webp';

            this.legacySrc = this.imageService.transform(url, { width: this.width, format, quality: 85 });
            this.sizes = [{ srcset: this.legacySrc, width: this.width }];
        } else {
            this.legacySrc = await this.imageService.resolveImage(url, this.widthFactor, this.mobileWidthFactor);
            this.setSizes(url);
        }

        this.preloadService.preload({ as: 'image', href: this.legacySrc });
        for (let source of this.sizes) {
            this.preloadService.preload({ as: 'image', href: source.srcset, media: source.media });
        }
    }
}
