"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.of = void 0;
var args_1 = require("../util/args");
var from_1 = require("./from");
function of() {
  var args = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    args[_i] = arguments[_i];
  }
  var scheduler = args_1.popScheduler(args);
  return from_1.from(args, scheduler);
}
exports.of = of;
