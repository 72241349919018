"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.publishLast = void 0;
var AsyncSubject_1 = require("../AsyncSubject");
var ConnectableObservable_1 = require("../observable/ConnectableObservable");
function publishLast() {
  return function (source) {
    var subject = new AsyncSubject_1.AsyncSubject();
    return new ConnectableObservable_1.ConnectableObservable(source, function () {
      return subject;
    });
  };
}
exports.publishLast = publishLast;
