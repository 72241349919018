import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
    transform(value: number|string): any {
        let number = Number(value);

        if (number > 1_000_000)
            return `${(Math.round(number / 1_000_000 * 10) / 10).toLocaleString()}M`;
        else if (number > 1_000)
            return `${(Math.round(number / 1_000 * 10) / 10).toLocaleString()}K`;

        return value;
    }

}
