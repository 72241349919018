"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeScan = void 0;
var lift_1 = require("../util/lift");
var mergeInternals_1 = require("./mergeInternals");
function mergeScan(accumulator, seed, concurrent) {
  if (concurrent === void 0) {
    concurrent = Infinity;
  }
  return lift_1.operate(function (source, subscriber) {
    var state = seed;
    return mergeInternals_1.mergeInternals(source, subscriber, function (value, index) {
      return accumulator(state, value, index);
    }, concurrent, function (value) {
      state = value;
    }, false, undefined, function () {
      return state = null;
    });
  });
}
exports.mergeScan = mergeScan;
