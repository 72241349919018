"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exhaustMap = void 0;
var map_1 = require("./map");
var innerFrom_1 = require("../observable/innerFrom");
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function exhaustMap(project, resultSelector) {
  if (resultSelector) {
    return function (source) {
      return source.pipe(exhaustMap(function (a, i) {
        return innerFrom_1.innerFrom(project(a, i)).pipe(map_1.map(function (b, ii) {
          return resultSelector(a, b, i, ii);
        }));
      }));
    };
  }
  return lift_1.operate(function (source, subscriber) {
    var index = 0;
    var innerSub = null;
    var isComplete = false;
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (outerValue) {
      if (!innerSub) {
        innerSub = OperatorSubscriber_1.createOperatorSubscriber(subscriber, undefined, function () {
          innerSub = null;
          isComplete && subscriber.complete();
        });
        innerFrom_1.innerFrom(project(outerValue, index++)).subscribe(innerSub);
      }
    }, function () {
      isComplete = true;
      !innerSub && subscriber.complete();
    }));
  });
}
exports.exhaustMap = exhaustMap;
