import { Injectable, NgZone, inject } from '@angular/core';
import { isServerSide } from '@tytapp/environment-utils';

export type TaskHandle = any;

/**
 * This service is used for tasks which should occur periodically. Using this instead of setInterval allows SSR and
 * client hydration to work correctly.
 */
@Injectable()
export class PeriodicTaskService {
    private ngZone = inject(NgZone);

    scheduleOnce(timeout: number, task: () => (void | Promise<void>)): TaskHandle {
        if (isServerSide())
            return;

        return this.ngZone.runOutsideAngular(
            () => setTimeout(() => this.ngZone.runGuarded(() => task()), timeout)
        )
    }

    schedule(interval: number, task: () => (void | Promise<void>)): TaskHandle {
        if (isServerSide())
            return;

        return this.ngZone.runOutsideAngular(
            () => setInterval(() => this.ngZone.runGuarded(() => task()), interval)
        );
    }

    cancel(handle: TaskHandle) {
        clearInterval(handle);
        clearTimeout(handle);
    }
}