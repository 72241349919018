"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidDate = void 0;
function isValidDate(value) {
  return value instanceof Date && !isNaN(value);
}
exports.isValidDate = isValidDate;
