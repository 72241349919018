export interface CallToAction {
    title: string;
    detail?: string;
    url: string;
    actionText: string;
    valueProps?: CallToActionValueProp[];
    theme?: string;
    backgroundImage?: string;
}

export interface CallToActionValueProp {
    img: string;
    text: string;
}

export const COMMUNITY_C2A: CallToAction = {
    title: 'Join our Community for Free',
    url: '/signup',
    actionText: 'Join Now',
    valueProps: [
        { img: '/assets/community/call-to-action-customize.svg', text: 'Customize Your Feed' },
        { img: '/assets/community/call-to-action-keep-watching.svg', text: 'Keep Watching' },
        { img: '/assets/community/call-to-action-keep-participate.svg', text: 'Participate in Polls, Petitions, and Discussions' }
    ]
};

export const MEMBERSHIP_C2A: CallToAction = {
    title: 'Stand with the only news network that stands with you',
    detail: 'Access member exclusive benefits and support the only news network that covers the issues you really care about. Like:',
    url: '/join',
    actionText: 'Support TYT With A Membership',
    valueProps: [
        { img: '/assets/signup-icons/green_deal.svg', text: 'Green New Deal' },
        { img: '/assets/signup-icons/banning_private.svg', text: 'Banning Private Financing of Elections' },
        { img: '/assets/signup-icons/college_for_all.svg', text: 'College For All' },
        { img: '/assets/signup-icons/living_wage.svg', text: 'A Living Wage' },
        { img: '/assets/signup-icons/medicare.svg', text: 'Medicare For All' },
    ]
};

export const DONATION_C2A: CallToAction = {
    title: `Help TYT Support its Mission`,
    url: '/donate',
    theme: 'blue',
    backgroundImage: 'url(/assets/offer-bg-2020.png)',
    actionText: 'Donate Now',
    valueProps: [
        // TODO
    ]
};