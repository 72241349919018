import { EventEmitter, Injectable, inject } from '@angular/core';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';

import { UserAgent } from './user-agent';

let ORIGINAL_WIDTH = isClientSide() ? window.innerWidth : 1920;
let ORIGINAL_HEIGHT = isClientSide() ? window.innerHeight : 1080;

@Injectable()
export class SoftwareKeyboard {
    private userAgent = inject(UserAgent);

    constructor() {
        this.init();
    }

    init() {
        if (isServerSide())
            return;

        if (this.userAgent.os == 'ios') {
            // iOS does not fire onresize when keyboard is shown/hidden,
            // so there's no way to detect the software keyboard being visible.
            // However, the way the keyboard is handled in iOS makes knowing whether its
            // visible not nearly as important as knowing on Android, so this is a no-op.
        } else {

            window.addEventListener('resize', () => {
                this.check();
            });
        }
    }

    public check() {
        if (isServerSide())
            return;

        let newWidth = window.innerWidth;
        let newHeight = window.innerHeight;

        if (newWidth == ORIGINAL_WIDTH && newHeight == ORIGINAL_HEIGHT)
            return;

        if (newWidth == ORIGINAL_HEIGHT && newHeight == ORIGINAL_WIDTH) {
            // Orientation change, skip.
        } else {
            let diff = Math.abs(newHeight - ORIGINAL_HEIGHT);

            if (newWidth == ORIGINAL_WIDTH && diff > 100) {
                // Keyboard change.
                if (newHeight > ORIGINAL_HEIGHT) {
                    // Keyboard hidden
                    //alert('keyboard hidden');
                    this.keyboardChanged.emit(false);
                } else {
                    // Keyboard shown
                    //alert('keyboard shown');
                    this.keyboardChanged.emit(true);
                }

                ORIGINAL_WIDTH = newWidth;
                ORIGINAL_HEIGHT = newHeight;
            }
        }

        // Track our new dimensions

        ORIGINAL_WIDTH = newWidth;
        ORIGINAL_HEIGHT = newHeight;
    }

    keyboardChanged: EventEmitter<boolean> = new EventEmitter<boolean>();


}