import { Injectable, inject } from '@angular/core';
import { isClientSide } from '@tytapp/environment-utils';
import { LoggerService } from './logger.service';

const NULL_TOKEN = '{:null:}';
const PREFIX = `tyt.`;

@Injectable()
export class LocalStorageService {
    private logger = inject(LoggerService);
    readonly isSupported: boolean = this.checkSupport();

    public get<T>(key: string): T {
        if (!this.isSupported) {
            this.logger.warning(`Cannot retrieve key '${key}': LocalStorage is not supported in this environment`);
            return null;
        }

        let item = localStorage.getItem(`${PREFIX}${key}`);
        // FIXME: not a perfect solution, since a valid 'null' string can't be stored
        if (item === undefined)
            return undefined;

        if (item === NULL_TOKEN)
            return null;

        if (item === 'null') {
            this.logger.warning(`LocalStorage: Key '${key}' contains string 'null', converting to null`);
            return null;
        }

        try {
            return JSON.parse(item);
        } catch (e) {
            this.logger.error(`Failed to parse LocalStorage key ${key}: ${e.stack || e.message || e}`);
            return null;
        }
    }

    public set(key: string, value: any): boolean {
        if (value === undefined) {
            this.remove(key);
            return;
        }

        if (value === null)
            value = NULL_TOKEN;
        else
            value = JSON.stringify(value);

        if (!this.isSupported) {
            this.logger.warning(`Cannot set key '${key}': LocalStorage is not supported in this environment`);
            return false;
        }

        try {
            localStorage.setItem(`${PREFIX}${key}`, value);
        } catch (e) {
            this.logger.error(`Caught error while setting key '${key}': ${e.stack || e.message || e}`);
            return false;
        }
        return true;
    }

    public remove(...keys: Array<string>): boolean {
        let result = true;
        keys.forEach((key: string) => {
            if (!this.isSupported) {
                this.logger.warning(`Cannot remove key '${key}': LocalStorage is not supported in this environment`);
                result = false;
                return;
            }

            try {
                localStorage.removeItem(`${PREFIX}${key}`);
            } catch (e) {
                this.logger.error(`Caught error while removing key '${key}': ${e.stack || e.message || e}`);
                result = false;
            }
        });
        return result;
    }

    private checkSupport(): boolean {
        if (!isClientSide())
            return false;

        try {
            let supported = 'localStorage' in window && window['localStorage'] !== null;
            if (supported) {
                // When Safari (OS X or iOS) is in private browsing mode, it
                // appears as though localStorage is available, but trying to
                // call .setItem throws an exception.
                //
                // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
                // to add something to storage that exceeded the quota."
                let key = `${PREFIX}__${Math.round(Math.random() * 1e7)}`;
                localStorage.setItem(key, '');
                localStorage.removeItem(key);
            }

            return supported;
        } catch (e) {
            this.logger.error(`Caught error while checking for LocalStorage support: ${e.stack || e.message || e}`);
            return false;
        }
    }
}