import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../base-component';
import { Notice, NoticeService } from '../notice-service';

@Component({
    selector: 'app-notice',
    templateUrl: './notice.component.html',
    styleUrls: ['./notice.component.scss']
})
export class NoticeComponent extends BaseComponent implements OnInit {

    constructor(
        private noticeService: NoticeService
    ) {
        super();
    }

    init() {
        this.notices = this.noticeService.dequeueNotices();
        this.subscribe(this.noticeService.dismissed, () => {
            this.notices = [];
        });
    }

    notices: Notice[];
}
