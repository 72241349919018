"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.publishBehavior = void 0;
var BehaviorSubject_1 = require("../BehaviorSubject");
var ConnectableObservable_1 = require("../observable/ConnectableObservable");
function publishBehavior(initialValue) {
  return function (source) {
    var subject = new BehaviorSubject_1.BehaviorSubject(initialValue);
    return new ConnectableObservable_1.ConnectableObservable(source, function () {
      return subject;
    });
  };
}
exports.publishBehavior = publishBehavior;
