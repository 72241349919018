import { Platform } from '@angular/cdk/platform';
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@tytapp/express.tokens';

import * as express from './express-ssr';

/**
 * A class for retrieving and doing basic analysis of the browser's user agent
 * string, both from the server-side and the client-side.
 */
@Injectable()
export class UserAgent {
    constructor(
        private platform: Platform,

        @Inject(REQUEST)
        @Optional()
        private request: express.ExpressRequest,

        @Inject(PLATFORM_ID)
        private platformId: any
    ) {
        this.checkWebP();
    }

    toString() {
        if (typeof navigator !== 'undefined') {
            return navigator.userAgent;
        } else if (this.request) {
            return this.request.headers['User-Agent'];
        } else {
            return null;
        }
    }

    checkWebPClient() {
        let elem = document.createElement('canvas');
        if (!!(elem.getContext && elem.getContext('2d')))
            return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
        else
            return false;
    }

    checkWebPServer() {
        if (this.request) {
            let acceptsStr = this.request.header('Accept');
            let accepts: string[] = [];

            if (acceptsStr && acceptsStr !== '')
                accepts = acceptsStr.split(',');

            if (accepts.includes('image/webp')) {
                this.supportsWebP = Promise.resolve(true);
                return;
            }
        }
    }

    checkWebP() {
        this.supportsWebP = Promise.resolve(false);

        if (isPlatformServer(this.platformId)) {
            this.checkWebPServer();
        } else {
            this.supportsWebP = Promise.resolve(this.checkWebPClient());
        }
    }

    supportsWebP: Promise<boolean> = Promise.resolve(false);

    get os() {
        if (this.platform.IOS)
            return 'ios';
        else if (this.platform.ANDROID)
            return 'android';

        let str = this.toString();

        if (!str)
            return 'unknown';

        if (str.indexOf('TYT Native for iOS') >= 0)
            return 'ios';

        if (str.indexOf('TYT Native for Android') >= 0)
            return 'android';

        if (str.indexOf('Xbox One') >= 0)
            return 'xbox-one';

        if (str.indexOf('PlayStation 4') >= 0)
            return 'ps4';

        if (str.indexOf('PLAYSTATION 3') >= 0)
            return 'ps3';

        if (str.indexOf('(PlayStation Vita') >= 0)
            return 'ps-vita';

        if (str.indexOf('Nintendo WiiU') >= 0)
            return 'wii-u';

        if (str.indexOf('Nintendo Wii') >= 0)
            return 'wii';

        if (str.indexOf('Android') >= 0)
            return 'android';

        if (str.indexOf('Linux') >= 0)
            return 'linux';

        if (str.indexOf('iPhone') >= 0 || str.indexOf('iPad') >= 0)
            return 'ios';

        if (str.indexOf('Windows') >= 0)
            return 'windows';

        if (str.indexOf('Mac OS X') >= 0)
            return 'osx';

        return 'unknown';
    }

    get browser() {
        let str = this.toString();

        if (!str)
            return 'unknown';

        if (str.indexOf('TYT Native') >= 0)
            return 'tytnative';

        if (str.indexOf('(Nintendo WiiU)') >= 0)
            return 'wii-u';

        if (str.indexOf('(PlayStation 4') >= 0)
            return 'ps4';

        if (str.indexOf('(PlayStation Vita') >= 0)
            return 'ps-vita';

        if (str.indexOf('PLAYSTATION 3') >= 0)
            return 'ps3';

        if (str.indexOf('Firefox/') >= 0)
            return 'firefox';

        if (str.indexOf('Edge/') >= 0)
            return 'edge';

        if (str.indexOf('Chrome/') >= 0)
            return 'chrome';

        if (str.indexOf('Safari/') >= 0) {
            if (str.indexOf('Android') >= 0) {
                return 'android';
            }

            return 'safari';
        }

        if (str.indexOf('; MSIE ') >= 0)
            return 'ie';

        if (str.indexOf('Presto/') >= 0)
            return 'opera';

        return 'unknown';
    }
}