"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.not = void 0;
function not(pred, thisArg) {
  return function (value, index) {
    return !pred.call(thisArg, value, index);
  };
}
exports.not = not;
