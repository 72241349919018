import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';


@Directive({
    selector: 'input[invalidMessage]'
})
export class NativeInvalidMessageDirective implements OnDestroy {
    private input: HTMLInputElement;

    @Input() invalidMessage: string;

    invalidListener = (e) => {
        this.input.setCustomValidity(this.invalidMessage);
    };

    inputListener = () => {
        this.input.setCustomValidity('');
    }

    constructor(
        element: ElementRef
    ) {
        this.input = element.nativeElement;
        this.input.addEventListener('invalid', this.invalidListener);
        this.input.addEventListener('input', this.inputListener);
    }

    ngOnDestroy() {
        this.input.removeEventListener('invalid', this.invalidListener);
        this.input.removeEventListener('input', this.inputListener);
    }
}
