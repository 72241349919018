@if (visible) {
  <div class="alert alert-debug">
    <div class="titlebar">
      <span class="env">
        @if (environment === 'staging') {
          Staging
        }
        @if (environment === 'development') {
          Development
        }
      </span>
      @if (title) {
        <span class="title">
          &raquo;
          {{ title }}
        </span>
      }
      <div class="spacer"></div>
      <a class="close" aria-label="Close Staging Note" href="javascript:;" (click)="remove()">
        <mat-icon>close</mat-icon>
      </a>
    </div>
    @if (!shown) {
      <div>
        <a href="javascript:;" (click)="shown = true">Show Details</a>
      </div>
    }
    @if (shown) {
      <div class="content-container" [class.visible]="shown" [attr.aria-hidden]="shown ? 'false' : 'true'">
        <ng-content></ng-content>
      </div>
    }
  </div>
}