import { Component, ElementRef, Input } from '@angular/core';
import { environment } from '@tytapp/environment';

import { AppConfig } from '../app-config';
import { BaseComponent } from '../base-component';

@Component({
    selector: 'tyt-staging-note',
    templateUrl: './staging-note.component.html',
    styleUrls: ['./staging-note.component.scss']
})
export class StagingNoteComponent extends BaseComponent {

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private appConfig: AppConfig
    ) {
        super();
    }

    init() {
        this.environment = this.appConfig.environment;
    }

    @Input()
    only: string;

    @Input()
    title: string;

    @Input()
    shown = false;

    environment: string;

    remove() {
        this.elementRef.nativeElement.remove();
    }

    get visible(): boolean {
        if (this.only && this.environment !== this.only)
            return false;

        return environment.showDevNotes;
    }
}
