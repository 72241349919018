"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sequenceEqual = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
var innerFrom_1 = require("../observable/innerFrom");
function sequenceEqual(compareTo, comparator) {
  if (comparator === void 0) {
    comparator = function (a, b) {
      return a === b;
    };
  }
  return lift_1.operate(function (source, subscriber) {
    var aState = createState();
    var bState = createState();
    var emit = function (isEqual) {
      subscriber.next(isEqual);
      subscriber.complete();
    };
    var createSubscriber = function (selfState, otherState) {
      var sequenceEqualSubscriber = OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (a) {
        var buffer = otherState.buffer,
          complete = otherState.complete;
        if (buffer.length === 0) {
          complete ? emit(false) : selfState.buffer.push(a);
        } else {
          !comparator(a, buffer.shift()) && emit(false);
        }
      }, function () {
        selfState.complete = true;
        var complete = otherState.complete,
          buffer = otherState.buffer;
        complete && emit(buffer.length === 0);
        sequenceEqualSubscriber === null || sequenceEqualSubscriber === void 0 ? void 0 : sequenceEqualSubscriber.unsubscribe();
      });
      return sequenceEqualSubscriber;
    };
    source.subscribe(createSubscriber(aState, bState));
    innerFrom_1.innerFrom(compareTo).subscribe(createSubscriber(bState, aState));
  });
}
exports.sequenceEqual = sequenceEqual;
function createState() {
  return {
    buffer: [],
    complete: false
  };
}
