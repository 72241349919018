import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'tyt-form-submit-button',
    templateUrl: './form-submit-button.component.html',
    styleUrls: ['./form-submit-button.component.scss']
})
export class FormSubmitButtonComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
