"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.switchAll = void 0;
var switchMap_1 = require("./switchMap");
var identity_1 = require("../util/identity");
function switchAll() {
  return switchMap_1.switchMap(identity_1.identity);
}
exports.switchAll = switchAll;
