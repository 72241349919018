"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromEventPattern = void 0;
var Observable_1 = require("../Observable");
var isFunction_1 = require("../util/isFunction");
var mapOneOrManyArgs_1 = require("../util/mapOneOrManyArgs");
function fromEventPattern(addHandler, removeHandler, resultSelector) {
  if (resultSelector) {
    return fromEventPattern(addHandler, removeHandler).pipe(mapOneOrManyArgs_1.mapOneOrManyArgs(resultSelector));
  }
  return new Observable_1.Observable(function (subscriber) {
    var handler = function () {
      var e = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        e[_i] = arguments[_i];
      }
      return subscriber.next(e.length === 1 ? e[0] : e);
    };
    var retValue = addHandler(handler);
    return isFunction_1.isFunction(removeHandler) ? function () {
      return removeHandler(handler, retValue);
    } : undefined;
  });
}
exports.fromEventPattern = fromEventPattern;
