/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class ApiConfiguration {
    constructor() {
        this._readyPromise = new Promise<void>((res, rej) => this._markReady = res);
    }

    private _readyPromise : Promise<void>;
    private _markReady : Function;

    basePath = '';
    apiKey: string;
    username: string;
    password: string;
    private _accessToken : string | (() => string);

    globalHeaders : any = {};
    
    get accessToken(): string | (() => string) {
        return this._accessToken;
    }

    set accessToken(value : string | (() => string)) {
        this._accessToken = value;
        this._markReady();
    }

    withCredentials: boolean;
    globalQueryParams: any;

    get tokenReady() : Promise<void> {
        return this._readyPromise;
    }
}