"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.multicast = void 0;
var ConnectableObservable_1 = require("../observable/ConnectableObservable");
var isFunction_1 = require("../util/isFunction");
var connect_1 = require("./connect");
function multicast(subjectOrSubjectFactory, selector) {
  var subjectFactory = isFunction_1.isFunction(subjectOrSubjectFactory) ? subjectOrSubjectFactory : function () {
    return subjectOrSubjectFactory;
  };
  if (isFunction_1.isFunction(selector)) {
    return connect_1.connect(selector, {
      connector: subjectFactory
    });
  }
  return function (source) {
    return new ConnectableObservable_1.ConnectableObservable(source, subjectFactory);
  };
}
exports.multicast = multicast;
