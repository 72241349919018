export function buildBooleanSearchParams<T extends { [k in keyof T]: boolean }>(name: string, values: T) {
    return new URLSearchParams(
    Object.entries(values)
            .map(([item, value]) => [name, `${ value ? '' : '-' }${ item }`])
    );
}


export function parseBooleanSearchParams<T extends { [k in keyof T]: boolean }>(values: string[]): T {
    return Object.fromEntries(
      values.map(value => {
          const item = value.replace(/^-/, '');
          return [item, item === value];
      })
    ) as T;
}
