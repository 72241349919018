import { zeroPad } from './pad';

/**
 * Used to match the behavior of Material's datepicker when generating
 * relative dates, where local midnight (ie 7:00/8:00 in PT) is used.
 * @param date
 * @returns
 */
export function getDateAtLocalMidnight(date: Date) {
    // Important to pass the date in here to account for daylight savings time.
    let midnightString = getLocalMidnightString(date);
    let timeString = `${date.getFullYear()}-${zeroPad(date.getMonth()+1)}-${zeroPad(date.getDate())}T${midnightString}`;

    return new Date(timeString);
}

/**
 * Accept either a US formatted date (MM/DD/YYYY) or an internal date (YYYY-MM-DD) and output an internal date.
 * Doesn't use `Date` so no timezone shenanigans can happen. If date is unparseable or empty (or null/undefined),
 * undefined is returned.
 *
 * @param date
 * @returns
 */
export function conformDate(date: string) {
    if (!date)
        return undefined;

    const US_DATE_FORMAT = /^(\d\d?)\/(\d\d?)\/(\d\d\d\d)$/;
    const INTL_DATE_FORMAT = /^\d\d\d\d-\d\d-\d\d$/;

    if (US_DATE_FORMAT.test(date)) {
        let [ _, month, day, year ] = US_DATE_FORMAT.exec(date);
        return `${year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`;
    } else if (INTL_DATE_FORMAT.test(date)) {
        return date;
    }

    console.warn(`conformDate(): Failed to conform date '${date}'! Returning undefined.`);
    return undefined;
}

export function getLocalMidnightString(date = new Date()) {
    let offset = date.getTimezoneOffset();
    let hourOffset = Math.round(offset / 60);
    let minuteOffset = offset % 60;
    return `${zeroPad(hourOffset)}:${zeroPad(minuteOffset)}:00.000Z`;
}

export function getTodayAtLocalMidnight() {
    return getDateAtLocalMidnight(new Date());
}

export function getTomorrowAtLocalMidnight() {
    return getDateAtLocalMidnight(new Date(new Date().getTime() + 1000 * 60 * 60 * 24));
}

export function getNextMondayAtLocalMidnight() {
    let now = new Date();
    if (now.getDay() === 1) {
        return getDateAtLocalMidnight(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7));
    } else if (now.getDay() === 0) {
        return getDateAtLocalMidnight(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 6));
    } else {
        let daysAway = 8 - now.getDay();
        return getDateAtLocalMidnight(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * daysAway));
    }
}

export function getDateAtUTCMidnight(date: Date) {
    return `${date.getFullYear()}-${zeroPad(date.getMonth()+1)}-${zeroPad(date.getDate())}T00:00:00.000Z`;
}

export function getDateString(date: Date) {
    return `${date.getFullYear()}-${zeroPad(date.getMonth()+1)}-${zeroPad(date.getDate())}`;
}

export function getTomorrowDateString() {
    return getDateString(getTomorrowAtLocalMidnight());
}

export function getTodayDateString() {
    return getDateString(getTodayAtLocalMidnight());
}
export function getNextMondayDateString() {
    return getDateString(getNextMondayAtLocalMidnight());
}

/**
 * Accepts ie '2022-06-01' and produces a Date object representing local midnight
 * on that date.
 * @param str
 */
export function parseDateAtLocalMidnight(str: string) {
    // If the incoming date has a time part
    // (ie 2022-06-01T12:34:56 or so), then parse it
    // first as a normal date and get its date string out
    // so we can use that to find our local midnight time.

    if (!/^\d\d\d\d-\d\d-\d\d$/.test(str)) {
        str = getDateString(new Date(str));
    }
    return new Date(`${str}T00:00:00`);
}


export function formatDurationPT(duration: number) {
    let hours = Math.floor(duration / (60 * 60));
    duration -= hours * 60 * 60;
    let minutes = Math.floor(duration / 60);
    duration -= minutes * 60;

    return `PT${zeroPad(hours)}H${zeroPad(minutes)}M${zeroPad(Math.floor(duration))}S`;
}


export function dateToUrl(strDate: string): string {
    const [date] = strDate.split('T');
    return date.replace(/-/g, '/');
}
