import { FieldError } from './field-error';

export class Validation {
    type: Function;
    message: string;
}

// @dynamic
export function Validates(type: any) {
    return (target: any) => {
        ValueValidationRegistry.instance().register(type, target);
    };
}

export class ValueValidationRegistry {
    private validatableClasses: any = {};

    private static _instance = null;

    public static instance(): ValueValidationRegistry {
        if (!this._instance)
            this._instance = new ValueValidationRegistry();

        return this._instance;
    }

    register(type: any, validatable: any) {
        this.validatableClasses[type] = validatable;
    }

    get(type: any) {
        return this.validatableClasses[type];
    }

    getForValue(value: any, type = null): Validatable {
        if (type == null)
            type = value.constructor;

        let validatorClass = this.get(value.constructor);

        if (!validatorClass)
            throw new Error(`Cannot find a validator for type ${type.name}`);

        return new validatorClass(value);
    }
}

export interface Validatable {
    validate(): FieldError[] | boolean | string;
}

export abstract class ValueValidator implements Validatable {
    constructor(public value: any) { }
    abstract validate(): FieldError[] | boolean | string;
}

export class NonEmptyValidator extends ValueValidator {
    validate() {
        return this.value !== null && this.value !== undefined && this.value !== '';
    }
}

export class SixCharacterLength extends ValueValidator {
    validate() {
        return (this.value !== null && this.value !== undefined && this.value.length > 5) ? true : 'Password must be six characters or more';
    }
}

export class NonNullValidator extends ValueValidator {
    validate() {
        return this.value !== null && this.value !== undefined;
    }
}

export class EmailValidator extends ValueValidator {
    validate() {
        // please don't make it more complicated than this, you won't get it right anyway
        // https://stackoverflow.com/questions/156430/regexp-recognition-of-email-address-hard

        return /.+@.+/.test(this.value) ? true : 'Invalid email address';
    }
}

@Validates(Boolean)
export class BooleanValidator extends ValueValidator {
    validate() {
        return this.value === true || this.value === false;
    }
}

@Validates(Number)
export class NumberValidator extends ValueValidator {
    validate() {
        return !isNaN(parseFloat(this.value));
    }
}

export class ObjectValidator extends ValueValidator {
    validate() {
        let fieldErrors: FieldError[] = [];
        let validatedFields = this.value.constructor.prototype.__validatedFields;

        if (!validatedFields) {
            return;
        }

        for (let key of validatedFields) {
            let value = this.value[key];
            let validationEnabled = Reflect.getMetadata('validation:enabled', this.value, key);
            let validatorType = Reflect.getMetadata('validation:type', this.value, key);

            if (!validationEnabled)
                continue;

            if (validatorType == null) {
                let type = Reflect.getMetadata('design:type', this.value, key);
                validatorType = ValueValidationRegistry.instance().get(type);
            }

            if (!(validatorType instanceof Function)) {
                console.error('Invalid validator class. Provided validator for ' + key + ' was:');
                console.dir(validatorType);
                continue;
            }

            let options = <ValidationOptions>Reflect.getMetadata('validation:options', this.value, key);
            let message = options ? options.message : null;

            //if (!message)
            //    message = 'This value is invalid';

            let fields = [];

            let field = options ? options.field : null;

            if (options && options.field)
                fields.push(options.field);
            if (options && options.fields)
                fields = fields.concat(options.fields);

            if (fields.length == 0)
                fields.push(key);

            let validator = new validatorType(value);
            let result = validator.validate();

            if (result !== true) {
                if (typeof result === 'string')
                    message = result;

                for (let field of fields) {
                    fieldErrors.push({
                        field,
                        message
                    });
                }
            }
        }

        if (fieldErrors.length == 0)
            return true;

        return fieldErrors;
    }
}

export class MonthValidator extends ValueValidator {
    validate() {
        if (this.value === null || this.value === undefined || this.value == '')
            return true;

        if (isNaN(parseInt(this.value)))
            return '(1 - 12)';

        if (parseInt(this.value) < 1 || parseInt(this.value) > 12)
            return '(1 - 12)';

        return true;
    }
}

export class DayOfMonthValidator extends ValueValidator {
    validate() {
        if (this.value === null || this.value === undefined || this.value == '')
            return true;

        if (isNaN(parseInt(this.value)))
            return '(1 - 31)';

        if (parseInt(this.value) < 1 || parseInt(this.value) > 31)
            return '(1 - 31)';

        return true;
    }
}

export class YearValidator extends ValueValidator {
    validate() {
        if (this.value === null || this.value === undefined || this.value == '')
            return true;

        if (isNaN(parseInt(this.value)))
            return 'Must be a number';

        if (parseInt(this.value) < 1850)
            return 'Too far in the past';

        return true;
    }
}

export interface ValidationOptions {
    message?: string;
    field?: string;
    fields?: string[];
    class?: any;
}

export function Validated(options: ValidationOptions = null) {

    return (target, propertyKey) => {
        if (Reflect.defineMetadata)
            Reflect.defineMetadata(`validation:enabled`, true, target, propertyKey);

        if (!target.__validatedFields)
            target.__validatedFields = [];

        target.__validatedFields.push(propertyKey);

        let cls = options ? options.class : null;

        if (cls != null)
            if (Reflect.defineMetadata)
                Reflect.defineMetadata(`validation:type`, cls, target, propertyKey);
        if (options != null)
            if (Reflect.defineMetadata)
                Reflect.defineMetadata(`validation:options`, options, target, propertyKey);
    };

}