import { Component } from '@angular/core';

export interface Award {
    franchiseName: string;
    logo: string;
    logoAlt: string;
    url?: string;
    name: string;
    subline: string;
    year: string;
    width: number;
    height: number;
}

@Component({
    selector: 'tyt-awards',
    template: `
        <div class="awards">
        @for (award of awards; track award) {
          <div class="award">
            <img [src]="award.logo" [alt]="award.logoAlt" class="award-image" />
            <div class="details">
              <div class="franchise">{{award.franchiseName}}</div>
              <div class="name">{{award.name}}</div>
              <div class="subline">
                {{award.subline ? award.subline + ',' : ''}}
                {{award.year}}
              </div>
            </div>
          </div>
        }
        </div>
  `,
    styleUrls: ['./awards.component.scss'],
})
export class TytAwardsComponent {
    awards: Award[] = [
        {
            logo: '/assets/awards/tyt-shorty.png',
            logoAlt: 'News & Media',
            franchiseName: 'TYT',
            name: 'Shorty Audience Honor',
            subline: 'Shorty Silver Honor',
            year: '2024',
            width: 723,
            height: 400
        },
        {
            logo: '/assets/awards/tdr-shorty.png',
            logoAlt: 'News & Politics Podcast',
            franchiseName: 'TYT',
            name: 'The Damage Report',
            subline: 'Shorty Audience Honor',
            year: '2024',
            width: 723,
            height: 400
        },
        {
            logo: '/assets/awards/tyt-shorty.png',
            logoAlt: 'Social Activism',
            franchiseName: 'TYT',
            name: 'Indisputable',
            subline: 'Shorty Audience Honor',
            year: '2024',
            width: 723,
            height: 400
        },
        {
            logo: '/assets/awards/webby.svg',
            logoAlt: 'Webby Awards logo',
            franchiseName: 'The Damage Report',
            name: 'Social Media Presence',
            subline: 'Official Webby Honoree',
            year: '2021',
            width: 723,
            height: 400
        },
        {
            logo: '/assets/awards/shorty.svg',
            logoAlt: 'Shorty Awards logo',
            franchiseName: 'The Young Turks',
            name: 'Audience Honor for Live Event Coverage',
            subline: 'Shorty Awards',
            year: '2021',
            width: 723,
            height: 398
        },
        {
            logo: '/assets/awards/webby.svg',
            logoAlt: 'Webby Awards logo',
            franchiseName: 'The Young Turks',
            name: 'News and Politics: Series and Channel',
            subline: 'Official Webby Honoree',
            year: '2019',
            width: 723,
            height: 398
        },
        {
            logo: '/assets/awards/shorty.svg',
            logoAlt: 'Shorty Awards logo',
            franchiseName: 'The Young Turks',
            name: 'Best in Overall YouTube Presence',
            subline: 'Audience Honor',
            year: '2017',
            width: 723,
            height: 398
        },
        {
            logo: '/assets/awards/webby.svg',
            logoAlt: 'Webby Awards logo',
            franchiseName: 'The Young Turks',
            name: 'Best in Overall YouTube Presence',
            subline: 'Audience Honor',
            year: '2017',
            width: 723,
            height: 398
        },
        {
            logo: '/assets/awards/streamy.svg',
            logoAlt: 'Streamy Awards logo',
            franchiseName: 'The Young Turks',
            name: 'Best News & Cultural Channel',
            subline: null,
            year: '2017',
            width: 723,
            height: 398
        },
        {
            logo: '/assets/awards/aha.svg',
            logoAlt: 'American Humanist Association logo',
            franchiseName: 'The Young Turks',
            name: 'Good Without A God',
            subline: 'Advocating Progressive Values and Equality for Humanists, Atheists, and Freethinkers',
            year: '2017',
            width: 723,
            height: 398
        },
    ];

    constructor() { }
}
