import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'emailLink' })
export class EmailLinkPipe {
    constructor(private _sanitizer: DomSanitizer) { }

    transform(value: any): string {
        const emails = this.extractEmails(value);

        emails.forEach((email) => {
            value = value.replace(email, `<a href="mailto:${email}">${email}</a>`);
        });

        return value;
    }

    extractEmails(text) {
        return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9-]+)/gi) ?? [];
    }
}
