import { Injectable } from '@angular/core';

export interface PasswordStrengthResult {
    strengthCode: 'VERY_WEAK' | 'WEAK' | 'REASONABLE' | 'STRONG' | 'VERY_STRONG';
}

export interface PasswordCharsets {
    number: boolean;
    lower: boolean;
    upper: boolean;
    punctuation: boolean;
    symbol: boolean;
    other: string;
}

@Injectable()
export class PasswordStrength {
    constructor() {
    }

    public check(password): PasswordStrengthResult {

        // tai-password-strength is great, but the bundle size is
        // huge. we should look for a slimmer alternative to provide
        // more than simple length validation for "strength", but
        // for now this should suffice

        if (!password || password.length < 4)
            return { strengthCode: 'VERY_WEAK' };

        if (password.length < 6)
            return { strengthCode: 'WEAK' };

        if (password.length < 8)
            return { strengthCode: 'REASONABLE' };

        if (password.length < 12)
            return { strengthCode: 'STRONG' };

        return { strengthCode: 'VERY_STRONG' };
    }
}