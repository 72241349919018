import { NgZone } from '@angular/core';

export async function preloadImage(url: string) {
    if (url.startsWith('data:'))
        return;

    await new Promise<void>((resolve, reject) => {
        let img = document.createElement('img');
        img.src = url;
        img.style.display = 'none';
        img.addEventListener('load', () => (img.remove(), resolve()));
        img.addEventListener('error', () => reject(new Error(`Failed to load image ${url}`)));
        document.body.appendChild(img);
    });
}

export async function loadScript(url: string, options: { async?: boolean } = {}) {
    let script = document.createElement('script');

    script.src = url;
    script.async = options.async ?? true;

    document.head.append(script);

    await new Promise<void>((resolve, reject) => {
        script.addEventListener('load', () => resolve());
        script.addEventListener('error', () => reject(`Error occurred while loading script ${url}`));
    });
}

export function getStyleFromUrl(url: string, fallbackUrl?: string) {
    return fallbackUrl ? `url(${url}), url(${fallbackUrl})` : `url(${url})`;
}

export function isElementVisible(element: HTMLElement) {
    let rect = element.getBoundingClientRect();
    let verticallyAligned = (rect.top >= 0 && rect.top <= window.innerHeight) || (rect.bottom >= 0 && rect.bottom <= window.innerHeight);
    let horizontallyAligned = (rect.right >= 0 && rect.right <= window.innerWidth) || (rect.left >= 0 && rect.left <= window.innerWidth);

    if (rect.width == 0 && rect.height == 0)
        verticallyAligned = horizontallyAligned = false;

    return verticallyAligned && horizontallyAligned;
}

/**
 * Resolves when the given element becomes visible to the user.
 * - If the element is already visible, it resolves immediately
 * - If the user agent does not support IntersectionObserver, it resolves immediately
 *
 * @param ngZone
 * @param element
 * @returns
 */
export async function waitUntilVisible(ngZone: NgZone, element: HTMLElement): Promise<void> {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            if (isElementVisible(element) || typeof IntersectionObserver === 'undefined') {
                resolve();
                return;
            }

            ngZone.runOutsideAngular(() => {
                let observer: IntersectionObserver;
                observer = new IntersectionObserver(async ([ entry ]) => {
                    if (entry?.isIntersecting) {
                        observer.unobserve(element);
                        ngZone.runGuarded(() => setTimeout(async () => resolve()));
                    }
                }, {
                    rootMargin: '1000px',
                    threshold: 0
                });
                observer.observe(element);
            });
        });
    });
}

export function isNewTabClick(event: MouseEvent) {
    return event.ctrlKey || event.shiftKey || event.altKey || event.metaKey;
}