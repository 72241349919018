"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterObject = void 0;
/**
 * Create a new object which has a subset of keys.
 *
 * @param object
 * @param props
 * @returns
 */
function filterObject(object, props) {
  return props.reduce(function (o, p) {
    return p in object ? o[p] = object[p] : undefined, o;
  }, {});
}
exports.filterObject = filterObject;
