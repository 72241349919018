import { inject, Injectable } from '@angular/core';
import { environment } from '@tytapp/environment';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';

import { LoggerService } from './logger.service';
import { buildQuery } from './url-utils';
import { UserAgent } from './user-agent';
import { ViewportSize } from './viewport-size';

export interface ImageTransformation {
    format?: 'png' | 'jpeg' | 'webp';
    width?: number;
    widthFactor?: number;
    quality?: number;
}

@Injectable()
export class ImageService {
    private viewportSize = inject(ViewportSize);
    private userAgent = inject(UserAgent);
    private logger = inject(LoggerService);

    nextImageId = 1000;

    readonly sizes = {
        original: 1920,
        xhd: 1920,
        hd: 1920,
        hd720: 1280,
        sd: 1137,
        sm: 551,
        xs: 250
    };

    readonly dynamicProcessingOrigins = [
        environment.urls.cdn,
        'https://dcfimages.tyt.com',
        'https://app.staging.tyt.com',
        'https://tyt.com'
    ];

    canBeProcessed(url: string) {
        if (url.endsWith('.svg'))
            return false;

        return this.dynamicProcessingOrigins.some(domain => url?.startsWith(domain));
    }

    getIdealWidth(widthFactor: number, mobileWidthFactor: number = undefined) {
        mobileWidthFactor ??= widthFactor;
        return this.sizes[this.selectAssetSize(widthFactor, mobileWidthFactor)] ?? 1920;
    }

    /**
     * Select an appropriate asset size based on the width of the target
     * display and the given width factor (default 1 = 100%).
     * Width factor is from 0 to 1 and represents the percent of the
     * overall screen width that the image will be stretched onto.
     *
     * @param widthFactor
     */
    selectAssetSize(widthFactor: number, mobileWidthFactor: number = undefined) {
        mobileWidthFactor ??= widthFactor;
        let inverseWidthFactor = 1 / widthFactor;

        if (this.viewportSize.width() < 500) {
            inverseWidthFactor = 1 / mobileWidthFactor;
        }

        let width = this.viewportSize.width();

        if (width > inverseWidthFactor * 2560) // 4K class
            return 'original';
        else if (width > inverseWidthFactor * 1920) // 1440p class
            return 'xhd';
        else if (width > inverseWidthFactor * 1280) // 1080p class
            return 'hd';
        else if (width > inverseWidthFactor * 1137) // 720p class
            return 'hd720';
        else if (width > inverseWidthFactor * 551) //  640p class
            return 'sd';
        else if (width > inverseWidthFactor * 250) //  310p class
            return 'sm';
        else //  140p class
            return 'xs';
    }

    async resolveImage(url: string, widthFactor: number, mobileWidthFactor: number = undefined) {
        mobileWidthFactor ??= widthFactor;

        // If its invalid, return a blank gif
        if (!url || typeof url !== 'string') {
            // TODO: Technically we should be specifying a mimetype here. However, Angular 14 has a bug (fixed or at
            // least rewritten in later versions) where the server-side style parsing blindly splits the `style`
            // attribute by semicolons, which would be valid except for url(). For instance:
            // style="background-image: url(data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==); border: none"
            // If you split the attribute value by ';', you will think that `base64` starts a new style key/value, but it doesn't.
            //
            // return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
            return 'data:base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
        }

        if ((/\/\/images\.contentful/.test(url) || /images\.ctfassets/.test(url))) {
            // Contentful URL. These should have been rewritten to dcfimages on the backend!
            // We can do it now, but let's report a warning.

            let urlObject = new URL(url);
            urlObject.host = 'dcfimages.tyt.com';

            this.logger.warning(`[ResponsiveBackgroundImage] Rewriting Contentful CDN URL on the fly to dcfimages! URL was: ${url} -- New URL: ${urlObject.toString()}`);
            url = urlObject.toString();
        }

        // Some images can be processed using the Image API.
        // This allows us to dynamically resize them for the user's device/situation and
        // even change the format to WebP if the device supports it.
        //
        // * NOTE: This can also work for internal assets (`src/assets`) if we are running on the actual
        //   TYT.com or the web staging environment. Other environments do not likely need this sort of optimization
        //   (ie native or devserver). See `dynamicProcessingOrigins` for which domains support processing.
        // * Unsupported URLs will be returned without alteration.

        return this.transform(url, {
            width: isServerSide() ? undefined : this.getIdealWidth(widthFactor, mobileWidthFactor),
            widthFactor: isServerSide() ? widthFactor : undefined,
            format: (await this.userAgent.supportsWebP) ? 'webp' : undefined,
            quality: 85
        });
    }

    transform(url: string, transformation: ImageTransformation) {
        if (!this.canBeProcessed(url))
            return url;

        // If we are on staging or production TYT.com (on the web, as opposed to native or dev or whatever else), then
        // we can use the image API to optimize the images we load (on both SSR and CSR). To do that, we'll turn absolute
        // origin local URLs into full absolute URLs so they can be picked up in the Image API section below.

        if (!environment.isNativeBuild && typeof url === 'string' && url.startsWith('/assets')) {
            let origin = isClientSide() ? location.origin : environment.urls.webRoot;
            if (['https://app.staging.tyt.com', 'https://tyt.com'].includes(origin)) {
                url = `${origin}${url}`;
            }
        }

        let pathName = url.replace(/^https?:\/\//, '');
        return `${environment.urls.api}/images/${pathName}${pathName.includes('?') ? '&' : '?'}${buildQuery({
            w: transformation.width,
            wf: transformation.widthFactor,
            fm: transformation.format,
            q: transformation.quality
        })}`
    }
}