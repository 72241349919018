import { Directive, ElementRef, Input, NgZone, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';

import { getStyleFromUrl, preloadImage, waitUntilVisible } from './dom-utils';
import { ImageService } from './image-service';
import { PreloadService } from './preload.service';

/**
 * Implements responsive image loading for background-image
 */
@Directive({
    selector: '[responsiveBackgroundImage]',
    inputs: ['responsiveBackgroundImage'],
    host: {
        '[style.transition]': '"0.5s opacity ease-in"',
        '[class.responsive-background]': 'true',
        '[class.switching-background]': 'switching',
        '[style.opacity]': 'switching ? 0 : 1',
        '[class.waiting-for-intersect]': 'waitingForIntersect',
        '[style.background-image]': 'backgroundImageStyle'
    }
})
export class ResponsiveBackgroundImageDirective {

    // Services

    private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private domSanitizer = inject(DomSanitizer);
    private ngZone = inject(NgZone);
    private imageService = inject(ImageService);
    private preloadService = inject(PreloadService);

    // State

    private _url: string;
    private switchTimeout;
    private visible: boolean = false;
    private get element() { return this.elementRef?.nativeElement; }

    // Inputs

    @Input() defaultImage: string;
    @Input() get responsiveBackgroundImage(): any { return this._url; }
    set responsiveBackgroundImage(url: any) {
        if (this._url === url)
            return;

        this._url = url;

        if (isClientSide())
            this.fadeSwitch()
        else
            this.run();
    }

    /**
     * Whether to use the Intersection Observer API to detect when the image is nearly visible before
     * starting the load process. On server side builds, this does nothing. Instead the developer should
     * take care not to send any elements that are invisible at all.
     */
    @Input() whenVisible: boolean = true;
    @Input() widthFactor: number = 1.0;
    @Input() mobileWidthFactor: number = 1.0;

    // Host Bindings

    switching: boolean = false;
    waitingForIntersect = false;
    backgroundImageStyle: any;

    // Logic

    async fadeSwitch() {
        if (!this.visible) {
            this.run();
            return;
        }

        await preloadImage(await this.resolveUrl(this.responsiveBackgroundImage)).catch(e => {});

        clearTimeout(this.switchTimeout);
        this.switching = true;
        this.switchTimeout = setTimeout(async () => (await this.setupImage(), this.switching = false), 500);
    }

    private async run() {
        if (isServerSide() || !this.whenVisible) {
            await this.setupImage();
            return;
        }

        this.waitingForIntersect = true;
        await waitUntilVisible(this.ngZone, this.element);
        this.waitingForIntersect = false;
        await this.setupImage();
    }

    /**
     * Setup the image to be shown on the background-image property.
     */
    private async setupImage() {
        this.visible = true;

        let url = await this.resolveUrl(this.responsiveBackgroundImage);

        this.preloadService.preload({ as: 'image', href: url });
        this.backgroundImageStyle = this.domSanitizer.bypassSecurityTrustStyle(
            getStyleFromUrl(url, this.defaultImage)
        );
    }

    private async resolveUrl(value) {
        return this.imageService.resolveImage(
            value,
            this.widthFactor,
            this.mobileWidthFactor
        );
    }
}
