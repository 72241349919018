"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.skipUntil = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
var innerFrom_1 = require("../observable/innerFrom");
var noop_1 = require("../util/noop");
function skipUntil(notifier) {
  return lift_1.operate(function (source, subscriber) {
    var taking = false;
    var skipSubscriber = OperatorSubscriber_1.createOperatorSubscriber(subscriber, function () {
      skipSubscriber === null || skipSubscriber === void 0 ? void 0 : skipSubscriber.unsubscribe();
      taking = true;
    }, noop_1.noop);
    innerFrom_1.innerFrom(notifier).subscribe(skipSubscriber);
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      return taking && subscriber.next(value);
    }));
  });
}
exports.skipUntil = skipUntil;
