import { Component, OnInit, Input, Optional } from '@angular/core';

import { FormComponent } from '../form/form.component';
import { ErrorProducer } from '../error-producer';

@Component({
    selector: 'error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
    constructor(
        @Optional() readonly form: FormComponent
    ) {
    }

    @Input('field')
    public field: string = null;

    @Input('producer')
    public producer: ErrorProducer = this.form;

    ngOnInit() {

    }

    get message() {
        if (!this.producer)
          return undefined;

        if (this.field) {
            let fieldError = (this.producer.fieldErrors || []).find(x => x.field == this.field);
            if (!fieldError)
                return '';

            return fieldError.message;
        }

        return this.producer.errorMessage || '';
    }

    get hasError() {
        if (!this.producer)
          return undefined;

        if (this.field) {
            let fieldError = (this.producer.fieldErrors || []).find(x => x.field == this.field);
            return fieldError != null;
        }

        return this.producer.error && this.producer.errorMessage;
    }

}
