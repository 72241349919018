import { Directive, Input } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { FormComponent } from './form/form.component';

export class TYTErrorStateMatcher implements ErrorStateMatcher {
    constructor(private formComponent: FormComponent, private name: string) {
    }

    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        if (!control)
            return false;

        return (control?.invalid && (control?.dirty || control?.touched))
            || this.formComponent.hasFieldError(this.name);
    }
  }


@Directive({
    selector: '[tytInput]'
})
export class TYTInputDirective {
    constructor(private form: FormComponent, private input: MatInput) {
    }

    //@Input() input: MatInput;
    @Input() name: string;

    ngOnInit() {
        this.input.errorStateMatcher = new TYTErrorStateMatcher(this.form, this.name);

    }
}