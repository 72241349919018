import { Injectable, Injector, inject } from "@angular/core";
import { Router } from "@angular/router";
import { REQUEST } from '@tytapp/express.tokens';
import { consoleImage } from './console-image';
import { environment } from '@tytapp/environment';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';
import { deepCopy } from './deep-copy';
import { rightPad } from './pad';
import { style, styled } from './ansi-style';
import { DevToolsService } from './dev-tools.service';
import { DevToolsAction } from '@tytapp/app/dev';

export interface LoggerSettings {
    includeRequest?: boolean;
    includeDate?: boolean;
    includeSeverity?: boolean;
    includeSource?: boolean;
    source?: string;
}

export type LogSeverity = 'error' | 'warn' | 'log' | 'info' | 'debug';

function devSetting<T>(name: string, defaultValue: boolean): boolean {
    if (isClientSide() && localStorage[`tyt:logging:${name}`] !== undefined)
        return localStorage[`tyt:logging:${name}`] === '1';
    return defaultValue;
}

function devToggle(id: string, label: string): DevToolsAction {
    return {
        type: 'action',
        id,
        label: `Exclude ${label}`,
        init(action, injector) {
            let service = injector.get(LoggerService);
            action.label = `${service[action.id] ? 'Exclude' : 'Include'} ${label}`;
        },

        handler(action, injector) {
            let service = injector.get(LoggerService);
            let value = !service[action.id];
            service[action.id] = value;
            if (isClientSide())
                localStorage[`tyt:logging:${action.id}`] = value ? 1 : 0;
            action.init?.(action, injector);
        },
    }
}

@Injectable()
export class LoggerService implements Required<LoggerSettings> {
    private request = inject(REQUEST, { optional: true });
    private router = inject(Router, { optional: true });

    /**
     * Retrieve the root LoggerService instance. This is used by the objects created by configure().
     */
    private root = this;

    registerDevTools(devTools: DevToolsService) {
        devTools.rootMenu.items.push(
            {
                id: 'logging',
                type: 'menu',
                label: 'Logging',
                icon: 'code',
                items: [
                    devToggle(`includeAppId`, `App ID`),
                    devToggle(`includeDate`, `Dates`),
                    devToggle(`includeRequest`, `Requests`),
                    devToggle(`includeSeverity`, `Severity`),
                    devToggle(`includeSource`, `Source`),
                ]
            }
        );
    }

    includeAppId = devSetting('includeAppId', false);
    includeRequest = devSetting('includeRequest', true);
    includeDate = devSetting('includeDate', true);
    includeSeverity = devSetting('includeSeverity', true);
    includeSource = devSetting('includeSource', true);

    appID = 'root';
    source = 'tyt';

    static readonly global = Injector
        .create({ providers: [ { provide: LoggerService, useClass: LoggerService  } ]})
        .get(LoggerService)
    ;

    public async clientLogImage(url: string, backgroundColor?: string, scale?: number) {
        await consoleImage(url, backgroundColor, scale);
    }

    public clientLogWithStyles(message: string, style: string) {
        if (isServerSide())
            return;

        console.log(message, style);
    }

    public configure(settings: LoggerSettings): LoggerService {
        let logger = { ...this };
        Object.setPrototypeOf(logger, this);
        Object.assign(logger, settings);
        return logger;
    }

    public logObject(message: string, object: any, severity: LogSeverity = 'log') {
        if (isServerSide()) {
            let strRep = '[unknown]';
            try {
                strRep = JSON.stringify(strRep);
            } catch (e) {
                strRep = `[JSON Failed: ${e.message}]`;
            }

            this.log(`${message}: ${strRep}`, undefined, severity);
        } else {
            console.groupCollapsed(this.prefixMessage(message, severity));
            console.dir(object);
            console.groupEnd();
        }
    }

    public prefixMessage(message: string, severity: LogSeverity) {
        let logPrefixParts: string[] = [];

        if (this.includeAppId && this.appID) {
            logPrefixParts.push(styled(style.$magenta(rightPad(this.appID, 8))));
        }

        if (this.includeSource) {
            logPrefixParts.push(styled(style.$cyan(rightPad(this.source, 15))));
        }

        if (this.includeSeverity) {
            if (['debug', 'info', 'log'].includes(severity)) {
                logPrefixParts.push(styled(style.$gray(`${rightPad(severity, 5)}`)));
            } else {
                logPrefixParts.push(`${rightPad(severity || 'log', 5)}`);
            }
        }

        if (this.includeDate)
            logPrefixParts.push(styled(style.$gray(`${new Date().toISOString()}`)));

        if (this.includeRequest) {
            if (Zone.current.get('requestId'))
                logPrefixParts.push(`${Zone.current.get('requestId')}`);

            if (this.request)
                logPrefixParts.push(`${this.request.ip} ${this.request.method} ${this.request.url}`);
            else if (this.router)
                logPrefixParts.push(`(client) VIEW ${this.router.routerState.snapshot.url}`);
        }

        if (logPrefixParts.length === 0)
            return message;

        return `${logPrefixParts.join('  ')}  ${message}`;
    }

    public log(message: string, object?: any, severity: LogSeverity  = 'log') {
        if (object) {
            this.logObject(message, object, severity);
            return;
        }

        message = this.prefixMessage(message, severity);

        switch (severity) {
            case 'error':
                console.error(message);
                break;
            case 'warn':
                console.warn(message);
                break;
            case 'info':
                console.info(message);
                break;
            case 'debug':
                console.debug(message);
                break;
            case 'log':
            default:
                console.log(message);
        }
    }

    public error(message: string, error?: any);
    public error(error: Error);
    public error(...args: any[]) {
        let message: string;
        let error: any;

        if (typeof args[0] === 'string') {
            message = args.shift();
        }

        error = args[0];

        if (message && error) {
            this.log(`${message}: ${error.stack || error.message || JSON.stringify(error)}`, undefined, 'error');
        } else if (error) {
            this.log(error.stack || error.message || JSON.stringify(error), undefined, 'error');
        } else {
            this.log(message, undefined, 'error');
        }
    }

    public warning(message: string, object?: any) { this.log(message, object, 'warn'); }
    public info(message: string, object?: any) { this.log(message, object, 'info'); }
    public debug(message: string, object?: any) {
        if (isServerSide() && !environment.serverLogging.verbose)
            return;
        if (isClientSide() && !environment.logging.verbose)
            return;

        this.log(message, object, 'debug');
    }

    public inspect(value: any) {
        console.dir(value);
    }
}