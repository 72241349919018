"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeout = exports.TimeoutError = void 0;
var async_1 = require("../scheduler/async");
var isDate_1 = require("../util/isDate");
var lift_1 = require("../util/lift");
var innerFrom_1 = require("../observable/innerFrom");
var createErrorClass_1 = require("../util/createErrorClass");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
var executeSchedule_1 = require("../util/executeSchedule");
exports.TimeoutError = createErrorClass_1.createErrorClass(function (_super) {
  return function TimeoutErrorImpl(info) {
    if (info === void 0) {
      info = null;
    }
    _super(this);
    this.message = 'Timeout has occurred';
    this.name = 'TimeoutError';
    this.info = info;
  };
});
function timeout(config, schedulerArg) {
  var _a = isDate_1.isValidDate(config) ? {
      first: config
    } : typeof config === 'number' ? {
      each: config
    } : config,
    first = _a.first,
    each = _a.each,
    _b = _a.with,
    _with = _b === void 0 ? timeoutErrorFactory : _b,
    _c = _a.scheduler,
    scheduler = _c === void 0 ? schedulerArg !== null && schedulerArg !== void 0 ? schedulerArg : async_1.asyncScheduler : _c,
    _d = _a.meta,
    meta = _d === void 0 ? null : _d;
  if (first == null && each == null) {
    throw new TypeError('No timeout provided.');
  }
  return lift_1.operate(function (source, subscriber) {
    var originalSourceSubscription;
    var timerSubscription;
    var lastValue = null;
    var seen = 0;
    var startTimer = function (delay) {
      timerSubscription = executeSchedule_1.executeSchedule(subscriber, scheduler, function () {
        try {
          originalSourceSubscription.unsubscribe();
          innerFrom_1.innerFrom(_with({
            meta: meta,
            lastValue: lastValue,
            seen: seen
          })).subscribe(subscriber);
        } catch (err) {
          subscriber.error(err);
        }
      }, delay);
    };
    originalSourceSubscription = source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      timerSubscription === null || timerSubscription === void 0 ? void 0 : timerSubscription.unsubscribe();
      seen++;
      subscriber.next(lastValue = value);
      each > 0 && startTimer(each);
    }, undefined, undefined, function () {
      if (!(timerSubscription === null || timerSubscription === void 0 ? void 0 : timerSubscription.closed)) {
        timerSubscription === null || timerSubscription === void 0 ? void 0 : timerSubscription.unsubscribe();
      }
      lastValue = null;
    }));
    !seen && startTimer(first != null ? typeof first === 'number' ? first : +first - scheduler.now() : each);
  });
}
exports.timeout = timeout;
function timeoutErrorFactory(info) {
  throw new exports.TimeoutError(info);
}
