import { CommonModule as ACommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { AppConfig } from './app-config';
import { AsDatePipe } from './as-date.pipe';
import { AsPipe } from './as.pipe';
import { AutolinkPipe } from './autolink.pipe';
import { AutosizeDirective } from './autosize.directive';
import { Base64 } from './base64';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { Cookies } from './cookies';
import { DateAsUTCDirective } from './date-as-utc.directive';
import { DialogGuard } from './dialog-guard';
import { DurationPipe } from './duration.pipe';
import { EmailLinkPipe } from './emailLink.pipe';
import { ErrorHighlightDirective } from './error-highlight-directive';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { FilterPipe } from './filter.pipe';
import { FormGetDirective } from './form-get/form-get.directive';
import { FormHandler } from './form-handler';
import { FormSubmitButtonComponent } from './form-submit-button/form-submit-button.component';
import { FormComponent } from './form/form.component';
import { FormatToHtmlPipe } from './format-to-html.pipe';
import { HashElementsService } from './hash-elements.service';
import { Head } from './head';
import { ImageService } from './image-service';
import { IntersectionObserverComponent } from './intersection-observer.component';
import { IsoDatePipe } from './iso-date.pipe';
import { LinksToHtmlPipe } from './links-to-html.pipe';
import { LinksToRouterDirective } from './links-to-router.directive';
import { LoadGuardComponent } from './load-guard/load-guard.component';
import { LocalStorageService } from './local-storage.service';
import { LoggerService } from './logger.service';
import { MarkdownToHtmlPipe } from './markdown-to-html.pipe';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { NativeInvalidMessageDirective } from './native-invalid-message.directive';
import { NetworkInformation } from './network-information';
import { NewlinesToHtmlPipe } from './newlines-to-html.pipe';
import { NoticeService } from './notice-service';
import { NoticeComponent } from './notice/notice.component';
import { OpenGraph } from './opengraph';
import { PageContextComponent } from './page-context.component';
import { PasswordStrength } from './password-strength';
import { PeriodicTaskService } from './periodic-tasks.service';
import { PlainTextToHtmlPipe } from './plaintext-to-html.pipe';
import { PreloadService } from './preload.service';
import { Redirection } from './redirection';
import { RepeatPipe } from './repeat.pipe';
import { RequestParams } from './request-params';
import { ResponsiveBackgroundImageDirective } from './responsive-background-image.directive';
import { ResponsivePictureComponent } from './responsive-picture.component';
import { Retry } from './retry';
import { SectionLinkComponent } from './section-link/section-link.component';
import { Shell } from './shell';
import { ShortNumberPipe } from './short-number.pipe';
import { SoftwareKeyboard } from './software-keyboard';
import { SpinnerComponent } from './spinner/spinner.component';
import { StagingNoteComponent } from './staging-note/staging-note.component';
import { SummarizePipe } from './summarize.pipe';
import { TimestampComponent } from './timestamp.component';
import { TrustHtmlPipe } from './trust-html.pipe';
import { TrustUrlPipe } from './trust-url.pipe';
import { TYTInputDirective } from './tyt-input.directive';
import { UniversalLinkDirective } from './universal-link.directive';
import { UserAgent } from './user-agent';
import { ViewportSize } from './viewport-size';
import { DialogBackerComponent } from './dialog-backer.component';
import { DevToolsService } from './dev-tools.service';
import { HostApi } from './host-api.service';
import { IFrameResizer } from './iframe-resizer';
import { VersionService } from './version.service';
import { TrustResourceUrlPipe } from './trust-resource-url.pipe';
import { IframeAutoResizeDirective } from './iframe-auto-resize.directive';
import { HostComponent } from './host/host.component';
import { TytAwardsComponent } from './awards/awards.component';

const COMPONENTS = [
    SectionLinkComponent,
    FormComponent,
    SpinnerComponent,
    LoadGuardComponent,
    StagingNoteComponent,
    ResponsiveBackgroundImageDirective,
    DateAsUTCDirective,
    AutolinkPipe,
    DurationPipe,
    AsDatePipe,
    TrustHtmlPipe,
    AsPipe,
    HostComponent,
    TrustUrlPipe,
    IframeAutoResizeDirective,
    TrustResourceUrlPipe,
    FilterPipe,
    MarkdownToHtmlPipe,
    IsoDatePipe,
    NewlinesToHtmlPipe,
    ShortNumberPipe,
    SummarizePipe,
    LinksToHtmlPipe,
    FormSubmitButtonComponent,
    NoticeComponent,
    ResponsivePictureComponent,
    EmailLinkPipe,
    PlainTextToHtmlPipe,
    FormatToHtmlPipe,
    PageContextComponent,
    TimestampComponent,
    DialogBackerComponent,
    MessageDialogComponent,
    AutosizeDirective,
    ConfirmationDialogComponent,
    MessageDialogComponent,
    IntersectionObserverComponent,
    LinksToRouterDirective,
    UniversalLinkDirective,
    FormGetDirective,
    RepeatPipe,
    TytAwardsComponent,

    // Validation
    ErrorMessageComponent,
    ErrorHighlightDirective,
    NativeInvalidMessageDirective,
    TYTInputDirective
];

export type AudienceType = 'visitor' | 'registered' | 'member' | 'expired member' | 'staff' | 'auto';

@NgModule({
    imports: [
        HttpClientModule,
        FormsModule,
        RouterModule,
        ACommonModule,
        MatIconModule,
        MatButtonModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class TYTCommonModule {
    static forRoot(): ModuleWithProviders<TYTCommonModule> {
        return {
            ngModule: TYTCommonModule,
            providers: [
                Shell,
                NetworkInformation,
                LocalStorageService,
                Cookies,
                Base64,
                UserAgent,
                FormHandler,
                SoftwareKeyboard,
                RequestParams,
                OpenGraph,
                Head,
                HostApi,
                AppConfig,
                ViewportSize,
                DialogGuard,
                Redirection,
                LoggerService,
                NoticeService,
                ImageService,
                PasswordStrength,
                PreloadService,
                PeriodicTaskService,
                Retry,
                HashElementsService,
                IFrameResizer,
                DevToolsService,
                VersionService,

                {
                    provide: APP_INITIALIZER,
                    useFactory: () => () => {},
                    deps: [HashElementsService],
                    multi: true
                },

                // These pipes are used by other pipes, so they
                // must be providers as well.
                MarkdownToHtmlPipe,
                PlainTextToHtmlPipe,
            ]
        };
    }
}
