"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.interval = void 0;
var async_1 = require("../scheduler/async");
var timer_1 = require("./timer");
function interval(period, scheduler) {
  if (period === void 0) {
    period = 0;
  }
  if (scheduler === void 0) {
    scheduler = async_1.asyncScheduler;
  }
  if (period < 0) {
    period = 0;
  }
  return timer_1.timer(period, period, scheduler);
}
exports.interval = interval;
