import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linksToHtml'
})
export class LinksToHtmlPipe implements PipeTransform {

    constructor() { }

    transform(value: string): any {
        return value.replace(
            /(https?:\/\/[A-Za-z0-9/\.\?&%_-]+)/g,
            `<a class="has-url-text" target="_blank" href="$1">$1</a>\n`
        );
    }
}