/**
 * CAUTION: Do not use this with objects that may be cyclical!
 *
 * @param a
 * @param b
 * @returns
 */
export function deepEqual(a: any, b: any, comparator: (a: any, b: any) => boolean = (a, b) => a === b) {
    if (comparator(a, b))
        return true;

    if (Array.isArray(a) !== Array.isArray(b))
        return false;

    if (Array.isArray(a)) {
        let arrayA = a as any[];
        let arrayB = b as any[];
        return arrayA.every((v, i) => deepEqual(v, arrayB[i], comparator));
    }

    if (typeof a !== typeof b)
        return false;

    if (typeof a === 'object') {
        Array.from(new Set<string>(Object.keys(a).concat(Object.keys(b)))).every(key => {
            return deepEqual(a[key], b[key], comparator)
        });
    }

    return false;
}

/**
 * CAUTION: Do not use this with objects that may be cyclical!
 *
 * @param a
 * @param b
 * @returns
 */
export function deepEqualLoose(a: any, b: any) {
    return deepEqual(a, b, (a, b) => a == b);
}
