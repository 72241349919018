"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.connectable = void 0;
var Subject_1 = require("../Subject");
var Observable_1 = require("../Observable");
var defer_1 = require("./defer");
var DEFAULT_CONFIG = {
  connector: function () {
    return new Subject_1.Subject();
  },
  resetOnDisconnect: true
};
function connectable(source, config) {
  if (config === void 0) {
    config = DEFAULT_CONFIG;
  }
  var connection = null;
  var connector = config.connector,
    _a = config.resetOnDisconnect,
    resetOnDisconnect = _a === void 0 ? true : _a;
  var subject = connector();
  var result = new Observable_1.Observable(function (subscriber) {
    return subject.subscribe(subscriber);
  });
  result.connect = function () {
    if (!connection || connection.closed) {
      connection = defer_1.defer(function () {
        return source;
      }).subscribe(subject);
      if (resetOnDisconnect) {
        connection.add(function () {
          return subject = connector();
        });
      }
    }
    return connection;
  };
  return result;
}
exports.connectable = connectable;
