import { Injectable, Type, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApiPostableSubject, ApiSubject, ApiUser } from '@tytapp/api';
import { environment } from '@tytapp/environment';
import { isClientSide } from '@tytapp/environment-utils';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import { AppConfig } from './app-config';
import type { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DialogComponent } from './dialog-component';
import { LoggerService } from './logger.service';
import type { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { deepEqual } from './deep-equal';

export interface SiteWideAlert {
    id: string;
    type?: 'info' | 'warning' | 'danger';
    message: string;
    url?: string;
    handler?: () => void;
    internal?: boolean;
    backgroundColor?: string;
    textColor?: string;
    icon?: string;
    onDismiss?: () => void;
}

type Constructor<T> = {
    new(...args): T
}

@Injectable()
export class Shell {
    private appConfig = inject(AppConfig);
    private titleService = inject(Title);
    private router = inject(Router);
    private logger = inject(LoggerService);

    constructor() {
        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart) {
                this.isChrome = false;
                this.noLayout = false;
                this.pageFlags = [];

                if (isClientSide()) {
                    const url = new URL(ev.url, location.origin);
                    if (url.pathname !== location.pathname) {
                        this.hideDialog();
                    }

                    this._urlHistory.push(ev.url);
                    if (this._urlHistory.length > 5)
                        this._urlHistory = this._urlHistory.slice(this._urlHistory.length - 5);
                }
            } else if (ev instanceof NavigationEnd) {
                this._firstRoute = false;
            }
        });

        if (isClientSide()) {
            window.addEventListener('resize', this.onWindowResize);
        }
    }

    private _urlHistory: string[] = [];
    private onWindowResize = () => this.calculateSiteWideAlertOffset();

    calculateSiteWideAlertOffset() {
        if (!isClientSide()) {
            this.siteWideAlertOffset = `0`;
            return;
        }

        if (window.innerWidth <= 450)
            this.siteWideAlertOffset = `0`;
        else
            this.siteWideAlertOffset = `${Math.min(this.alerts.length, 1)*50}px`;
    }

    siteWideAlertOffset: string;
    get urlHistory() {
        return this._urlHistory.slice();
    }

    public get features() {
        return this.appConfig.appStatus.features;
    }

    public async waitForFeatures() {
        await this.appConfig.appStatusReady;
    }

    public async hasFeature(name: string) {
        return await this.appConfig.featureEnabled(name);
    }

    public hasFeatureSync(name: string) {
        return this.appConfig.featureEnabledSync(name);
    }

    public get firstRoute() {
        return this._firstRoute;
    }

    private _alerts: SiteWideAlert[] = [];
    private _alertsChanged = new BehaviorSubject<SiteWideAlert[]>([]);

    /**
     * Fires true when a ServiceWorker app update is available and has been installed.
     */
    updateAvailable = new ReplaySubject<boolean>(1);

    get alerts() {
        return this._alerts;
    }

    public get alertsChanged() {
        return this._alertsChanged;
    }

    addAlert(alertObj: SiteWideAlert) {
        alertObj.icon = 'podcasts';
        let filteredAlerts = this._alerts.filter(x => x.id !== alertObj.id);

        filteredAlerts.push(alertObj);

        this._alerts = filteredAlerts;
        this._alertsChanged.next(this._alerts);
        this.calculateSiteWideAlertOffset();
    }

    removeAlert(id: string) {
        // To avoid ChangedAfterCheck...
        setTimeout(() => {
            let filteredAlerts = this._alerts.filter(x => x.id !== id);
            this._alerts = filteredAlerts;
            this._alertsChanged.next(this._alerts);
        })
    }

    dismissAlert(id: string) {
        let alert = this._alerts.find(x => x.id === id);

        this.removeAlert(id);

        if (alert.onDismiss) {
            alert.onDismiss();
        }
    }

    private _titleChanged = new Subject<string>();
    private _title: string;

    get titleChanged() { return this._titleChanged.asObservable(); }
    get title() { return this._title; }

    set title(value) {
        let siteName = environment.isNativeBuild ? 'TYT' : 'TYT.com';

        value = value? `${value} - ${siteName}` : siteName;
        this._title = value;
        this._titleChanged.next(value);
        this.titleService.setTitle(value);
    }

    pageFlags : string[] = [];

    addPageFlag(flag : string) {
        this.logger.info(`Adding page flag '${flag}'`);
        this.pageFlags.push(flag);
    }

    private _firstRoute: boolean = true;
    private _isChrome: boolean = false;
    private _noLayout: boolean = false;
    private _dialogClosable: boolean = true;

    isChromeChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    noLayoutChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dialogChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dialogClosableChanged: BehaviorSubject<boolean> = new BehaviorSubject(true);

    private _dialog: any = null;

    get dialog(): any {
        return this._dialog;
    }

    static namedDialogs: Record<string, () => Promise<Constructor<DialogComponent>>> = {};

    /**
     * Register a named dialog. This is useful when a pair of dialogs must reference each other.
     * @param name
     * @param cls
     */
    private static registerNamedDialog<T extends DialogComponent>(name: string, loader: () => Promise<Type<T>>) {
        this.namedDialogs[name] = loader;
    }

    static registerBuiltInDialog<T extends DialogComponent>(name: string, type: Type<T>) {
        this.namedDialogs[name] = async () => type;
    }

    /**
     * Register a lazy-loaded dialog. This is required to enable proper functioning of the history API (ie back/forward
     * within the browser). IMPORTANT: Please consult the "Dialogs" section of the README for important steps.
     *
     * @param name
     * @param cls
     */
    static registerLazyDialog<T extends DialogComponent, ModuleT>(name: string, loader: () => Promise<ModuleT>, locator: (mod: ModuleT) => Type<T>) {
        this.registerNamedDialog(name, () => loader().then(mod => locator(mod)));
    }

    /**
     * Convenience method for registering many lazy dialogs that share the same source module in a single go
     * @param callback
     */
    static registerLazyDialogs<T extends DialogComponent, ModuleT>(loader: () => Promise<ModuleT>, callback: (r: (name: string, locator: (mod: ModuleT) => Type<T>) => void) => void) {
        callback((name, locator) => this.registerLazyDialog(name, loader, locator));
    }

    /**
     * Retrieve a named dialog
     * @param nameOrClass
     */
    static async getDialogByName(name: string): Promise<Constructor<DialogComponent>> {
        if (!this.namedDialogs[name])
            throw new Error(`No registered dialog named '${name}'`);
        let dialogCls = await this.namedDialogs[name]();

        return dialogCls;
    }

    static getNameForDialog(cls: any) {
        return Reflect.getMetadata('dialog:name', cls);
    }

    dialogInstantly: boolean = false;

    set dialog(value: any) {
        if (deepEqual(this._dialog, value))
            return;

        this._dialog = value;
        this.dialogChanged.next(value);
    }

    animateToDialog(cls: Function, ...args) {
        this.dialog = [cls, args];
    }

    static messageDialogComponent: typeof MessageDialogComponent;
    static confirmDialogComponent: typeof ConfirmationDialogComponent;

    async alert(title: string, message: string) {
        return new Promise<void>(resolve => {
            this.showDialog(Shell.messageDialogComponent, title, message, () => resolve());
        });
    }

    async confirm(title: string, message: string) {
        return new Promise<boolean>(resolve => {
            this.showDialog(Shell.confirmDialogComponent, message, (confirmed) => {
                resolve(confirmed);
            }, { title });
        });
    }

    async showDialog<T extends DialogComponent>(cls: Constructor<T>, ...args: Parameters<T['init']>) {
        if (!cls)
            throw new Error(`No dialog class passed on showDialog(), this is an error. There could be a cyclical dependency.`);

        setTimeout(() => {
            this.dialogInstantly = false;
            this.dialog = [cls, args];
        });
    }

    hideDialog() {
        this.dialog = null;
        this.dialogClosable = true;
    }

    set chromeless(value : boolean) {
        this._chromeless = value;
        this._chromelessChanged.next(this._chromeless);
    }

    private _chromeless = false;
    private _chromelessChanged = new BehaviorSubject<boolean>(false);

    get chromelessChanged() {
        return this._chromelessChanged;
    }

    set noLayout(value: boolean) {
        this._noLayout = value;
        setTimeout(() => this.noLayoutChanged.next(this._noLayout));
    }

    get noLayout() {
        return this._noLayout;
    }

    get isChrome() {
        return this._isChrome;
    }

    public get rootUrl(): string { return environment.urls.root; }
    public get platformUrl(): string { return environment.urls.platform; }

    set isChrome(value: boolean) {
        this._isChrome = value;

        if (isClientSide() && window.document) {
            if (value) {
                window.document.documentElement.classList.add("chrome");
            } else {
                window.document.documentElement.classList.remove("chrome");
            }
        }

        this.isChromeChanged.next(this._isChrome);
    }

    urlForSubject(subject: ApiSubject | ApiPostableSubject) {
        if ('post_id' in subject && subject.post_id)
            return `/nation/posts/${subject.post_id}`;
        else if (subject.type === 'CMS::Show')
            return `/shows/${subject.slug || subject.uuid}`;
        else if (subject.type === 'CMS::Topic')
            return `/topics/${subject.slug || subject.uuid}`;
        else if (subject.type === 'ChooseOnePoll')
            return `/polls/${subject.slug || subject.uuid}`;
        else if (subject.type === 'Petition')
            return `/campaigns/${subject.slug || subject.uuid}`;
        else if (subject.type === 'User')
            return `/@${subject.slug ?? `-${subject.id}`}`;
        else if (subject.type === 'CMS::Announcement')
            return `/about/announcement/${subject.slug ?? subject.uuid}`;

        return undefined;
    }

    private _userChanged = new Subject<ApiUser>();
    private _userChanged$ = this._userChanged.asObservable();

    /**
     * Used to react to the current user changing by services that the UserService itself
     * depends on.
     */
    get userChanged() { return this._userChanged$; }

    /**
     * Called by the UserService to inform the Shell that the current user has changed.
     * Needed due to the reverse dependency here.
     * @param user
     */
    notifyUserChanged(user: ApiUser) {
        this._userChanged.next(user);
    }

    set dialogClosable(value: boolean) {
        this._dialogClosable = value;
        setTimeout(() => this.dialogClosableChanged.next(this._dialogClosable));
    }

    get dialogClosable() {
        return this._dialogClosable;
    }


    private _pageReady = new Subject<void>();
    private _pageReady$ = this._pageReady.asObservable();
    get pageReady() { return this._pageReady$; }

    pageBecomeReady() {
        this._pageReady.next();
    }
}
