import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { environment } from '@tytapp/environment';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';

import { REQUEST, RESPONSE } from '../express.tokens';
import { Base64 } from './base64';
import { LoggerService } from './logger.service';


@Injectable()
export class Cookies {
    private base64 = inject(Base64);
    private logger = inject(LoggerService);
    private platformID = inject(PLATFORM_ID);
    private request = inject(REQUEST, { optional: true });
    private response = inject(RESPONSE, { optional: true });

    public all(): Record<string, string> {
        let cookies: Record<string, string> = {};

        if (isClientSide() && environment.cookies.storeInLocalStorage) {
            return Object.keys(window.localStorage)
                .filter(x => x.startsWith('cookie:'))
                .map(key => key.replace(/^cookie:/, ''))
                .reduce((o, key) => (o[key] = localStorage[`cookie:${key}`], o), {})
            ;
        }

        if (isServerSide()) {
            cookies = this.request?.cookies || {};
        } else {
            cookies = {};

            document.cookie.split('; ').map(x => x.split('=', 2)).forEach(x => cookies[x[0]] = decodeURIComponent(x[1]));
        }

        return cookies;
    }

    public remove(...names: string[]) {
        if (isClientSide() && environment.cookies.storeInLocalStorage) {
            for (let name of names)
                delete localStorage[`cookie:${name}`]
            return;
        }

        if (isPlatformServer(this.platformID)) {
            for (let name of names)
                this.response?.clearCookie(name, { path: '/', domain: environment.auth.domain });
        } else {
            for (let name of names)
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${environment.auth.domain}`;
        }
    }

    /**
     * Get a cookie
     * @param name
     */
    public get<T>(name: string): T {
        if (isClientSide() && environment.cookies.storeInLocalStorage) {
            if (!(`cookie:${name}` in localStorage))
                return null;
            return JSON.parse(this.base64.decode(localStorage[`cookie:${name}`]));
        }

        let cookies = this.all();

        if (!cookies[name])
            return null;

        try {
            return JSON.parse(this.base64.decode(cookies[name]));
        } catch (e) {
            this.logger.error(`Failed to decode cookie '${name}' with value '${cookies[name]}': ${e}`);
            return null;
        }
    }

    /**
     * Set a cookie
     * @param name
     * @param value
     * @param expires
     */
    public set<T>(name: string, value: T, expires: number = null, rawValue: boolean = false) {
        //this.logger.info("Setting cookie "+name);
        if (isClientSide() && environment.cookies.storeInLocalStorage) {
            localStorage[`cookie:${name}`] = this.base64.encode(JSON.stringify(value));
            return;
        }

        let encodedValue: string;

        if (rawValue)
            encodedValue = value as any;
        else
            encodedValue = this.base64.encode(JSON.stringify(value));

        if (expires === null)
            expires = 1000 * 60 * 60 * 24 * 7;

        let expiryDate = new Date(Date.now() + expires);
        let domain = environment.auth.domain;

        // Standard browser-side path

        if (isPlatformBrowser(this.platformID)) {

            let cookieString = `${name}=${encodedValue}; path=/; ${domain ? 'domain=' + domain + '; ' : ''}expires=${expiryDate.toUTCString()}`;
            document.cookie = cookieString;

            return;
        }

        // Special case for server-side

        if (isPlatformServer(this.platformID)) {

            // If we don't have access to response,
            // fail out, we cannot set a cookie.

            if (!this.response) {
                if (environment.showDevTools) {
                    this.logger.warning(`[Cookies] Cannot set cookie '${name}' in this configuration (no access to web request/response)`);
                } else {
                    this.logger.error('[Cookies] Missing HTTP response injectable while attempting to set cookie (issue with ngengine)');
                    this.logger.error('[Cookies] Request: ' + (this.request ? 'Present' : 'Not Present'));
                    this.logger.error('[Cookies] Response: ' + (this.response ? 'Present' : 'Not Present'));
                }
                return;
            }

            if (this.response.headersSent) {
                this.logger.warning('Cannot set cookie, headers already sent.');
                return;
            }

            let opts: any = { path: '/', expires: expiryDate };
            if (domain)
                opts.domain = domain;

            this.response.cookie(name, encodedValue, opts);

            return;
        }

        throw new Error("No cookie provider available.");
    }
}