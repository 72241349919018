"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.throwIfEmpty = void 0;
var EmptyError_1 = require("../util/EmptyError");
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function throwIfEmpty(errorFactory) {
  if (errorFactory === void 0) {
    errorFactory = defaultErrorFactory;
  }
  return lift_1.operate(function (source, subscriber) {
    var hasValue = false;
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      hasValue = true;
      subscriber.next(value);
    }, function () {
      return hasValue ? subscriber.complete() : subscriber.error(errorFactory());
    }));
  });
}
exports.throwIfEmpty = throwIfEmpty;
function defaultErrorFactory() {
  return new EmptyError_1.EmptyError();
}
