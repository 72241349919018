"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.take = void 0;
var empty_1 = require("../observable/empty");
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function take(count) {
  return count <= 0 ? function () {
    return empty_1.EMPTY;
  } : lift_1.operate(function (source, subscriber) {
    var seen = 0;
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      if (++seen <= count) {
        subscriber.next(value);
        if (count <= seen) {
          subscriber.complete();
        }
      }
    }));
  });
}
exports.take = take;
