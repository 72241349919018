"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.publish = void 0;
var Subject_1 = require("../Subject");
var multicast_1 = require("./multicast");
var connect_1 = require("./connect");
function publish(selector) {
  return selector ? function (source) {
    return connect_1.connect(selector)(source);
  } : function (source) {
    return multicast_1.multicast(new Subject_1.Subject())(source);
  };
}
exports.publish = publish;
