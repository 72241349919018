import { DOCUMENT } from '@angular/common';
import { Injectable, RendererFactory2, inject } from '@angular/core';
import { isClientSide } from '@tytapp/environment-utils';

@Injectable()
export class PreloadService {
    private doc = inject(DOCUMENT);
    private rendererFactory = inject(RendererFactory2);
    private renderer = this.rendererFactory.createRenderer(this.doc, null);

    preload(asset: { href: string, as: 'style' | 'script' | 'fetch' | 'font' | 'image' | 'track', media?: string }) {
        if (isClientSide())
            return;

        if (asset.href.startsWith('data:'))
            return;

        let link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'preload');
        this.renderer.setAttribute(link, 'href', asset.href);
        this.renderer.setAttribute(link, 'as', asset.as);
        if (asset.media)
            this.renderer.setAttribute(link, 'media', asset.media);

        this.renderer.appendChild(this.doc.head, link);
    }
}