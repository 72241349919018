import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@tytapp/environment';
import { isClientSide } from '@tytapp/environment-utils';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { DevToolsService } from './dev-tools.service';
import { LoggerService } from './logger.service';

export interface Message {
    type: string;
    /**
     * When true, this is a noisy, frequent message. To avoid overwhelming logging, we don't trace these by default.
     */
    noisy?: boolean;
}

export interface Request {
    type: string;
    request_id: string;
}

export interface Response {
    type: 'result';
    request_type: string;
    request_id: string;
    error_message?: string;
}

export interface PlatformCapabilitiesMessage {
    type: 'platform_capabilities',
    platform: 'web' | 'ios' | 'android' | 'appletv' | 'androidtv' | 'roku';
    bundle_identifier: string;
    version: string;
    build_type: 'release' | 'preRelease' | 'unknown' | 'emulated' | 'web';
    capabilities: string[];
    settings: Record<string,any>;
}

export interface NavigateMessage {
    type: 'navigate';
    url: string;
}

export interface PostMessageReceiver {
    postMessage(message: any);
    addEventListener(type: 'message', handler: (event: MessageEvent<any>) => void);
}

export function isMessageOfType<T extends Message>(message: Message, type: T['type']): message is T {
    return message.type === type;
}

/**
 * Implements the TYT web core host API, as described in
 * https://docs.google.com/document/d/1aKhepTmweYx9d1quRqXtCOgX1l63L8cdj_tkbo8adOA/edit?usp=sharing
 *
 * This is used to enable TYT.com to be embedded within a native application, either directly from the web or by
 * including the TYT.com build within the app's distribution.
 */
@Injectable()
export class HostApi {
    instanceId = uuid();
    instanceName = 'root';

    private ngZone = inject(NgZone);
    private devTools = inject(DevToolsService);
    private rootLogger = inject(LoggerService);
    private logger = this.rootLogger.configure({ source: `hostapi:${this.instanceName}`, includeDate: false, includeRequest: false });

    constructor() {
        let enableApi = false;

        this.devTools.appendInto('global', [
            {
                type: 'action',
                label: 'Capabilities',
                icon: 'auto_awesome',
                handler: (_, i) => i.get(Router).navigateByUrl(`/engineering/capabilities`)
            },
            {
                type: 'action',
                label: 'Crash Host',
                icon: 'code',
                handler: (_, i) => i.get(HostApi).sendMessage({ type: 'crash' })
            }
        ])

        if (isClientSide()) {
            let url = new URL(window.location.href);
            let isHosted = false;

            if (url.searchParams.get('hosted') === '1') {
                // This means we are hosted by a containing app
                isHosted = true;

                if (url.searchParams.get('instanceName')) {
                    this.instanceName = url.searchParams.get('instanceName');
                    this.rootLogger.appID = this.instanceName;
                }
                if (url.searchParams.get('instanceId'))
                    this.instanceId = url.searchParams.get('instanceId');

                this.logger.source = `hostapi:${this.instanceName}`;

                if (url.searchParams.get('persistHosting') !== '0')
                    localStorage['tyt:hosted'] = '1';
            } else if (localStorage['tyt:hosted'] === '1') {
                isHosted = true;
            }

            if (isHosted) {
                if (!environment.showDevTools) {
                    // SECURITY: It is essential that the production version of this API only
                    // be available from https://mobile.tyt.com, which is not a deployed copy of the
                    // app. That URL is used within the native app shells. This guard prevents credential
                    // stealing by malicious sites which host TYT.com in an iframe and pretend to be the
                    // mobile app by implementing the host API.

                    enableApi = window.location.origin === 'https://mobile.tyt.com' || window.location.origin === 'tyt://mobile.tyt.com';
                } else {
                    // The staging/development versions allow the API regardless of where it is loaded from.
                    enableApi = true;
                }
            }
            if (enableApi && !window['hostListener'] && window.parent === window) {
                this.logger.error(`Requested, but no hostListener, not in iframe.`);
                this.logger.error(`Host API will be disabled.`);

                if (environment.showDevTools) {
                    debugger;
                    alert(`Failed to communicate with app shell. Host listener unavailable.`);
                }
                enableApi = false;
            }

            this.apiEnabled = enableApi;

            window['devReceiveHostMessage'] = obj => this._messageReceived.next(obj);

            if (this.apiEnabled) {

                let handler = async (ev: MessageEvent<any>) => {
                    if (!this.apiEnabled)
                        return;

                    if (ev.data.startsWith('[iFrame'))
                        return;

                    let event: Message;
                    try {
                        event = JSON.parse(ev.data);
                    } catch (e) {
                        this.logger.error(`Error parsing message received from app shell:`);
                        this.logger.error(ev.data);
                        this.logger.error(e);

                        throw new Error(`App shell sent message containing invalid JSON.`);
                    }

                    if (!event.noisy) {
                        this.logger.info(`Received message, type: ${event.type}`, event);
                    }

                    if (event.type === 'ready') {
                        this.logger.error(
                            `Error: Received 'ready' message from Host API. This message is intended to be sent from the web core. `
                            + `It is possible that all outgoing messages are arriving as incoming messages.`
                        );
                    }

                    // It is important that we never relay messages to listeners until after platform capabilities have
                    // arrived. The PC message is used to resolve the ready promise. Delaying messages like this allows
                    // listeners to be added after the platform capabilities is received, so that messages aren't lost.
                    // The first use case of this was the Native Google Cast support- without this, the first castStateChanged
                    // event is lost, since on the native side the native cast provider waits for the host API to be "ready",
                    // but that just means that the PC message has been *sent*. On the web side, the NativeGoogleCastPlugin
                    // is constructed immediately after the relevant PC feature check happens, but by then the initial messages
                    // have already arrived. By delaying these messages until after the PC event arrives and is processed,
                    // we ensure that the PC message is always fully processed before we allow normal messages from the native host
                    // to be received by the web host.

                    if (!isMessageOfType<PlatformCapabilitiesMessage>(event, 'platform_capabilities'))
                        await this._ready;

                    this.ngZone.run(() => this._messageReceived.next(event));
                }

                this.logger.info(`Listening for events on ${this.hostListener ? 'hostListener' : 'window'}`);

                // On both iOS and Android we use the hostListener object.
                if (this.hostListener) {
                    //this.hostListener.addEventListener('message', handler);
                    this.hostListener['onmessage'] = handler;
                } else {
                    // This warning made sense before we started using the host API in the web core itself
                    //this.logger.error(`Warning: No hostListener is available, communication with host app may be impossible.`);
                    window.addEventListener('message', handler);
                }
            }
        }
    }

    get hostListener(): PostMessageReceiver {
        return window['hostListener'];
    }

    apiEnabled = false;

    sendRequest<Req extends Request, Res extends Response>(requestInit: Omit<Req, 'request_id'>): Promise<Res> {
        return new Promise<Res>((resolve, reject) => {
            let requestId = uuid();
            this.messageReceived
                .pipe(filter((r: Res) => r.type === 'result' && r.request_id === requestId))
                .pipe(take(1))
                .subscribe(r => {
                    r.error_message ? reject(new Error(r.error_message)) : resolve(<Res>r);
                });
            this.sendMessage({ ...requestInit, request_id: requestId });
        });
    }

    async sendMessage<T extends Message = Message>(message: T) {
        if (!this.apiEnabled)
            return;

        if (message.type !== 'ready')
            await this._ready;

        if (!message.noisy)
            this.logger.info(`Sending message, type: ${message.type}`, message);

        if (this.hostListener)
            this.hostListener.postMessage(JSON.stringify(message));
        else if (window !== window.parent)
            window.parent.postMessage(JSON.stringify(message), '*');
        else
            this.logger.error(`Failed to send: No hostListener and no parent window`);
    }

    markCapabilitiesReady: () => void;
    capabilitiesReady = new Promise<void>(resolve => this.markCapabilitiesReady = resolve);
    capabilities: string[] = [];

    addCapability(capability: string) {
        if (!this.capabilities.includes(capability))
            this.capabilities.push(capability);
    }

    removeCapability(capability: string) {
        let index = this.capabilities.indexOf(capability);
        if (index >= 0)
            this.capabilities.splice(index, 1);
    }

    private _capabilitiesOverridden = false;
    get capabilitiesOverridden() { return this._capabilitiesOverridden; }

    async hasCapability(capability: string) {
        await this.capabilitiesReady;
        return this.hasCapabilitySync(capability);
    }

    hasCapabilitySync(capability: string) {
        return this.capabilities.includes(capability);
    }

    private _nonOverriddenCapabilities: string[];

    get nonOverriddenCapabilities() {
        return this._nonOverriddenCapabilities ?? this.capabilities;
    }

    private enableCapabilitiesOverrides() {
        // Note that we only enable capabilities override on the root layer of a multi-layer hosting setup (ie when
        // TYT.com on the web is embedding itself). Otherwise, we would lose the custom capabilities requested by the
        // <tyt-host /> component.
        if (isClientSide() && localStorage['tyt:capabilities:override'] && this.instanceName === 'root') {
            try {
                const capMessage = JSON.parse(localStorage['tyt:capabilities:override']) as PlatformCapabilitiesMessage;

                // Legacy overrides are not supported (was raw string array before)
                if (Array.isArray(capMessage))
                    return;

                this._nonOverriddenCapabilities = this.capabilities;
                this._capabilitiesMessage = capMessage;
                this.capabilities = capMessage.capabilities;
                this._capabilitiesOverridden = true;

                this.logger.info(`Capabilities overridden from ${this._nonOverriddenCapabilities.join(', ')} to ${this.capabilities.join(', ')}`);
            } catch (e) {
                this.logger.error(`Failed to parse overridden capabilities from tyt:capabilities:override: ${e.message}`);
            }
        }
    }

    async isCapabilityEmulated(cap: string) {
        await this.capabilitiesReady;
        return this.capabilities.includes(cap)
            && !this.nonOverriddenCapabilities.includes(cap);
    }

    private _fireReady: () => void;
    private _ready = new Promise<void>(resolve => this._fireReady = resolve);

    async handshake(): Promise<PlatformCapabilitiesMessage> {
        if (!this.apiEnabled) {
            // Default capabilities (web)
            this.capabilities = [
                'web_billing:membership',
                'web_billing:contribution',
                'web_billing:gift'
            ];
            this.enableCapabilitiesOverrides();
            this.markCapabilitiesReady();
            return undefined;
        }

        return new Promise((resolve, reject) => {
            let timeout = setTimeout(() => {
                reject(new Error(`The host app did not send the platform_capabilities message in time (within 10 seconds)`));
            }, 10*1000);

            this.messageReceived
                .pipe(filter(v => isMessageOfType<PlatformCapabilitiesMessage>(v, 'platform_capabilities')))
                .pipe(take(1))
                .subscribe((capMessage: PlatformCapabilitiesMessage) => { // TODO: In TS 5.5 we can remove the type here
                    clearTimeout(timeout);
                    this.logger.info(`Received capabilities.`, capMessage);

                    this._capabilitiesMessage = capMessage;
                    this.capabilities = capMessage.capabilities;
                    this.enableCapabilitiesOverrides();
                    this.markCapabilitiesReady();
                    resolve(capMessage);
                    setTimeout(() => this._fireReady(), 100);
                })
            ;

            this.sendMessage({ type: 'ready' });
        });
    }

    private _capabilitiesMessage: PlatformCapabilitiesMessage;
    get capabilitiesMessage() { return this._capabilitiesMessage; }

    private _messageReceived = new Subject<Message>();

    get messageReceived() { return this._messageReceived.asObservable(); }

    messageOfType<T extends { type }>(type: T['type']) {
        return <Observable<T>>this.messageReceived.pipe(filter(m => m.type === type));
    }

    overrideCapabilities(message: PlatformCapabilitiesMessage) {
        localStorage['tyt:capabilities:override'] = JSON.stringify(message);
    }

    clearCapabilityOverrides() {
        delete localStorage['tyt:capabilities:override'];
    }
}