"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.windowWhen = void 0;
var Subject_1 = require("../Subject");
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
var innerFrom_1 = require("../observable/innerFrom");
function windowWhen(closingSelector) {
  return lift_1.operate(function (source, subscriber) {
    var window;
    var closingSubscriber;
    var handleError = function (err) {
      window.error(err);
      subscriber.error(err);
    };
    var openWindow = function () {
      closingSubscriber === null || closingSubscriber === void 0 ? void 0 : closingSubscriber.unsubscribe();
      window === null || window === void 0 ? void 0 : window.complete();
      window = new Subject_1.Subject();
      subscriber.next(window.asObservable());
      var closingNotifier;
      try {
        closingNotifier = innerFrom_1.innerFrom(closingSelector());
      } catch (err) {
        handleError(err);
        return;
      }
      closingNotifier.subscribe(closingSubscriber = OperatorSubscriber_1.createOperatorSubscriber(subscriber, openWindow, openWindow, handleError));
    };
    openWindow();
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      return window.next(value);
    }, function () {
      window.complete();
      subscriber.complete();
    }, handleError, function () {
      closingSubscriber === null || closingSubscriber === void 0 ? void 0 : closingSubscriber.unsubscribe();
      window = null;
    }));
  });
}
exports.windowWhen = windowWhen;
