/**
 * Display an image in the console.
 */
export async function consoleImage(url: string, backgroundColour: string = 'transparent', scale: number = 1) {
    return new Promise<void>(resolve => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = (): void => {
            const c = document.createElement('canvas');
            const ctx = c.getContext('2d');
            if (ctx) {
                c.width = img.width;
                c.height = img.height;
                ctx.fillStyle = backgroundColour;
                ctx.fillRect(0, 0, c.width, c.height);
                ctx.drawImage(img, 0, 0);
                const dataUri = c.toDataURL('image/png');
                console.log(`%c                                                                 `,
                    `
                        font-size: 300%;
                        padding: 4em 0 0 0;
                        background-image: url(${dataUri});
                        background-repeat: no-repeat;
                        background-size: contain;
                        color: transparent;
                    `
                );

                resolve();
            }
        };
        img.src = url;
    });
};