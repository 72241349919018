"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createErrorClass = void 0;
function createErrorClass(createImpl) {
  var _super = function (instance) {
    Error.call(instance);
    instance.stack = new Error().stack;
  };
  var ctorFunc = createImpl(_super);
  ctorFunc.prototype = Object.create(Error.prototype);
  ctorFunc.prototype.constructor = ctorFunc;
  return ctorFunc;
}
exports.createErrorClass = createErrorClass;
