import { Location } from '@angular/common';
import { Directive, HostBinding, HostListener, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@tytapp/environment';
import { rewriteUrl } from '@tytapp/environment-utils';

import { isNewTabClick } from './dom-utils';
import { isExternalLink } from './url-utils';
import { LoggerService } from './logger.service';

/**
 * Provides universal hyperlinking (both internal and external). Use in place of routerLink.
 */
@Directive({ selector: 'a[url]' })
export class UniversalLinkDirective {
    private router = inject(Router);
    private location = inject(Location);
    private _target: string;
    private logger = inject(LoggerService).configure({ source: 'ulink' });

    get isExternal() {
        if (!this.href)
            return false;
        return !this.href.startsWith('/')
            && !this.href.startsWith(rewriteUrl(environment, environment.urls.webRoot));
    }

    @HostBinding('attr.href') href: string = null;
    @HostBinding('attr.target') @Input()
    get target() {
        if (this._target === undefined && this.isExternal)
            return '_blank';

        return this._target;
    }
    set target(value) { this._target = value; }

    @HostBinding('class.universal-link') isUniversalLink = true;
    @Input() set url(url: string) { this.href = this.rewriteUrl(url); }
    get url() { return this.href; }

    get shouldOpenInNewTab() { return this.target && this.target !== '_self' };

    private rewriteUrl(url: string) {
        if (!url)
            return url;

        if (url.startsWith('#'))
            url = `${this.location.path()}${url}`;
        return rewriteUrl(environment, url);
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): boolean {
        if (!this.href)
            return;

        let url = rewriteUrl(environment, this.href);
        if (!url) {
            this.logger.error(`Received null URL after rewrite`, { original: this.href, rewritten: url });
            return;
        }

        let skipHandling = !url || event.button !== 0 || isNewTabClick(event) || this.shouldOpenInNewTab || isExternalLink(url);
        if (url.startsWith('//') || url.includes('://')) {
            let urlObject = new URL(url);
            urlObject.hostname = 'example.com';
            urlObject.protocol = 'http:';
            urlObject.port = '';
            url = urlObject.toString().replace(/^http:\/\/example.com/, '');
        }

        this.logger.info(`Handling link click`, {
            original: this.href,
            rewritten: url,
            button: event.button,
            isNewTabClick: isNewTabClick(event),
            openInNewTab: this.shouldOpenInNewTab,
            external: isExternalLink(url),
            skipHandling
        });

        if (skipHandling)
            return true;

        this.router.navigateByUrl(url);
        return false;
    }
}

