"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.never = exports.NEVER = void 0;
var Observable_1 = require("../Observable");
var noop_1 = require("../util/noop");
exports.NEVER = new Observable_1.Observable(noop_1.noop);
function never() {
  return exports.NEVER;
}
exports.never = never;
