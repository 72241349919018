import { Injectable } from '@angular/core';
import { ApiConnectionError } from '@tytapp/api/requester';
import { isServerSide } from '@tytapp/environment-utils';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { LoggerService } from './logger.service';

export interface RetryDecision {
    (status: number, statusText: string, xhr: XMLHttpRequest, tryCount: number): Promise<boolean>;
}

export interface Retryable<T> {
    (...args): Promise<T>;
}

export interface ErrorHandler {
    (statusCode: number, statusText: string, xhr: XMLHttpRequest): void;
}

export class RetryFailure extends Error {
    constructor(public status, public statusText, public xhr) {
        super("Failed to fetch resource");
    }
}

@Injectable()
export class Retry {
    constructor(
        private logger: LoggerService
    ) {
    }

    public async standard<T>(f: Retryable<T>, errorHandler = null) {
        // On the server-side, we don't have a lot of request time to deal with, so
        // our retry time is going to be much less, starting off with 12ms, then 120ms,
        // then 1200ms
        if (isServerSide())
            return await this.many(f, 3, 12, errorHandler);
        return await this.many(f, 3, 100, errorHandler);
    }

    /**
     * Calculate how long we should wait before the next retry, based on how many tries we've done
     * and what the base delay is, along with a 25% random jitter to avoid synchronized API server
     * slamming. Limited to a maximum of 10 seconds (plus jitter).
     *
     * @param initialDelay The amount of time (in milliseconds) before the first retry (jitter will be added to this even on first attempt)
     * @param tryCount Will be '1' on the first retry, '2' on the second retry, etc
     */
    public determineDelay(initialDelay: number, tryCount: number) {
        let maxTime: number = 10 * 1000;

        // On the server-side, we should not wait for longer than 2 seconds, which is already excessively
        // long in the context of constructing a final page render for the client.
        // On the client-side however, we can be more lenient and allow for longer delays before we get the
        // content.

        if (isServerSide())
            maxTime = 2 * 1000;
        else
            maxTime = 6 * 1000;

        let deterministicTime = initialDelay * Math.pow(10, Math.min(3, Math.max(0, tryCount - 1)));
        deterministicTime = Math.min(maxTime, deterministicTime);

        let randomTime = Math.random() * deterministicTime * 0.25;
        return deterministicTime + randomTime;
    }

    public async forever<T>(f: Retryable<T>, initialDelay: number = 1000, errorHandler = null): Promise<T> {
        return this.retry(f, (status, statusText, xhr, tryCount) => {

            if (errorHandler) {
                errorHandler();
            } else {
                this.logger.error(`Failed to fetch data (${status} ${statusText || 'No Response'})`);
                this.logger.error('Request that caused this:');
                this.logger.inspect(xhr);
            }

            // Delay

            if (initialDelay == 0)
                return Promise.resolve(true);

            let finalDelay = this.determineDelay(initialDelay, tryCount);
            this.logger.info(`                     (Delaying retry by ${finalDelay} ms)`);

            return of(true)
                .pipe(delay(finalDelay))
                .toPromise();
        });
    }

    public async many<T>(f: Retryable<T>, count: number, initialDelay: number = 5000, errorHandler: ErrorHandler = null): Promise<T> {
        return await this.retry(f, (status, statusText, xhr, tryCount) => {

            if (errorHandler) {
                errorHandler(status, statusText, xhr);
            } else {
                this.logger.error(`[Retryable] Failed to fetch data (${status} ${statusText || 'No Response'})`);
                this.logger.info('Request that caused this:');
                this.logger.inspect(xhr);
            }

            // If we have no more retries available, give up: halt further retries

            if (--count < 0)
                return Promise.resolve(false);

            // If we are configured to have no delay between retries, then try again right away.

            if (initialDelay == 0)
                return Promise.resolve(true);

            // Calculate an exponential drop off before trying again

            let finalDelay = this.determineDelay(initialDelay, tryCount);

            this.logger.info('Delaying retry by ' + finalDelay + ' ms');

            // We do want to retry, but we want to wait at least finalDelay before we do so
            return of(true)
                .pipe(delay(finalDelay))
                .toPromise();
        });
    }

    public async once<T>(f: Retryable<T>, delay: number = 5000, errorHandler: ErrorHandler = null): Promise<T> {
        return await this.many(f, 1, delay, errorHandler);
    }

    public async twice<T>(f: Retryable<T>, delay: number = 5000, errorHandler: ErrorHandler = null): Promise<T> {
        return await this.many(f, 1, delay, errorHandler);
    }

    public async retry<T>(f: Retryable<T>, discrim: RetryDecision, tryCount = 0): Promise<T> {
        try {
            return await f();
        } catch (e) {
            if (!e)
                throw e;

            let retryCapable = false;
            let xhr: XMLHttpRequest = null;
            let status: number = -1;
            let statusText: string = null;

            if (typeof ProgressEvent !== 'undefined' && e instanceof ProgressEvent) {
                // ProgressEvent as provided by XHR
                // Sometimes e.currentTarget is null, so don't crash on that.

                xhr = <any>e.currentTarget;
                status = xhr?.status;
                statusText = xhr?.statusText;
            } else if ('headers' in e) {
                // Response as provided by Angular HTTP
                xhr = <any>e?.['_body']?.currentTarget;
                status = e?.status;
                statusText = e?.statusText;
            } else if ('requestDetails' in e) {
                xhr = null;
                status = e.requestDetails?.status;
                statusText = e.requestDetails?.statusText;
            } else if (e instanceof ApiConnectionError) {
                xhr = null;
                status = 0;
                statusText = undefined;
            } else {
                this.logger.error(`[Retryable] Caught non-retryable error during operation: ${e instanceof Error ? e.stack : JSON.stringify(e)}`);
                this.logger.error("[Retryable] Failed operation was: " + f.toString());

                // Not a known error type, throw it normally and do not retry.
                throw e;
            }

            if (status === 0) {
                // Failed to connect to API
                retryCapable = true;
            } else if (status >= 500 && status < 600) {
                // Server side error
                retryCapable = true;
            }

            if (retryCapable) {
                tryCount += 1;
                let willRetry = await discrim(e.status, e.statusText, xhr, tryCount);

                if (!willRetry) {
                    this.logger.error(`[Retryable] Error response from HTTP request: ${status} ${statusText} during operation ${f} (${tryCount} attempts, will not retry)`);
                    throw new RetryFailure(status, statusText, xhr);
                } else {
                    this.logger.error(`[Retryable] Error response from HTTP request: ${status} ${statusText} during operation ${f} (${tryCount} attempts, will retry)`);
                }

                return await this.retry(f, discrim, tryCount);
            } else {
                this.logger.error(`[Retryable] Error response (non-retryable) from HTTP request: ${status} ${statusText} during operation ${f}`);
                throw e;
            }
        }
    }
}