import { Component, NgZone, OnDestroy, OnInit, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { Head } from './head';
import { LoggerService } from './logger.service';
import { Redirection } from './redirection';
import { Retry } from './retry';
import { Shell } from './shell';

@Component({
    template: ''
})
export class BaseComponent implements OnInit, OnDestroy {
    protected zone = inject(NgZone);
    protected meta = inject(Meta);
    protected router = inject(Router);
    protected head = inject(Head);
    protected redirection = inject(Redirection);
    protected logger = inject(LoggerService).configure({ source: `appcomp` });
    protected retry = inject(Retry);
    protected shell = inject(Shell);

    constructor(
    ) {
        let proto = Object.getPrototypeOf(this);
        let properties = Object.getOwnPropertyNames(proto);

        let usesLifecycleEvents =
            properties.indexOf('ngOnInit') >= 0
            || properties.indexOf('ngOnDestroy') >= 0
            ;

        if (usesLifecycleEvents) {
            throw new Error(`Component ${this.constructor.name} which inherits BaseComponent is not allowed to declare the ngOnInit and/or ngOnDestroy lifecycle events. Instead implement init() and/or destroy()`);
        }

    }

    private _onInitHappened = false;
    private _initArgs = [];
    private _initialized = false;
    private _destroyed = false;

    set initArguments(args: any[]) {

        this._initArgs = args;
    }

    ngOnInit() {
        this.fireInit();
    }

    /**
     * Fires the component init() method. Can be overridden by subclasses to wrap their own subclasses init() cycle.
     */
    fireInit() {
        this._initialized = true;
        this.init.apply(this, this._initArgs);
    }

    /**
     * Subscribe to the given observable for the lifetime of this component (ie unsubscribed automatically when component is destroyed).
     */
    subscribe<T>(observable: Observable<T>, action: (value: T) => void): void {
        this._subscriptions.push(observable.subscribe(action));
    }

    private _subscriptions: Subscription[] = [];

    ngOnDestroy() {
        if (!this._initialized || this._destroyed)
            return;

        this._destroyed = true;
        this._subscriptions.forEach(sub => sub.unsubscribe());
        this._subscriptions = [];
        this.destroy();
    }

    /**
     * Override this method to initialize the component.
     */
    init(...args) { }

    /**
     * Override this method to specify actions to happen when the component is destroyed.
     */
    destroy() { }
}