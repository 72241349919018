import { Component, ElementRef, Input, NgZone, Output } from '@angular/core';
import { isServerSide } from '@tytapp/environment-utils';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'tyt-intersection-observer',
    template: `<ng-content></ng-content>`,
    styles: [``]
})
export class IntersectionObserverComponent {
    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone
    ) {
    }

    @Output() visibleChanged = new BehaviorSubject<boolean>(false);
    @Output() becameVisible = new Subject<void>();
    @Output() becameInvisible = new Subject<void>();
    @Output() firstBecameVisible = new Subject<void>();

    @Input() mode: 'initial' | 'always' = 'initial';

    private _wasVisible = false;
    private _wasEverVisible = false;
    private observer: IntersectionObserver;

    ngOnInit() {
        if (isServerSide())
            return;

        let element = this.elementRef.nativeElement;

        this.ngZone.runOutsideAngular(() => {
            this.observer = new IntersectionObserver(events => {
                let event = events[events.length - 1];
                if (this._wasVisible !== event.isIntersecting) {
                    this.ngZone.runGuarded(() => {
                        this.visibleChanged.next(event.isIntersecting);
                        this._wasVisible = event.isIntersecting;
                        if (event.isIntersecting)
                            this.becameVisible.next();
                        else
                            this.becameInvisible.next();
                    })
                }

                if (!this._wasEverVisible && event.isIntersecting) {
                    this.ngZone.runGuarded(() => {
                        this.firstBecameVisible.next();
                        this._wasEverVisible = true;
                    });

                    if (this.mode === 'initial') {
                        this.observer.disconnect();
                        this.observer = null;
                    }
                }
            });
        });
        this.observer.observe(element);
    }

    ngOnDestroy() {
        this.observer?.disconnect();
    }
}