"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.subscribeOn = void 0;
var lift_1 = require("../util/lift");
function subscribeOn(scheduler, delay) {
  if (delay === void 0) {
    delay = 0;
  }
  return lift_1.operate(function (source, subscriber) {
    subscriber.add(scheduler.schedule(function () {
      return source.subscribe(subscriber);
    }, delay));
  });
}
exports.subscribeOn = subscribeOn;
