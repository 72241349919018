import { inject, Injectable } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { isServerSide } from '@tytapp/environment-utils';

import { Shell } from './shell';


@Injectable({ providedIn: 'root' })
export class HashElementsService {
    shell = inject(Shell);
    viewportScroller = inject(ViewportScroller);

    constructor() {
        if (isServerSide())
            return;

        this.shell.pageReady.subscribe(() => {
            if (location.hash) {
                setTimeout(() => {
                    this.viewportScroller.scrollToAnchor(location.hash.slice(1));
                });
            }
        });
    }
}
