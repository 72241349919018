"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateTimestampProvider = void 0;
exports.dateTimestampProvider = {
  now: function () {
    return (exports.dateTimestampProvider.delegate || Date).now();
  },
  delegate: undefined
};
