"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toArray = void 0;
var reduce_1 = require("./reduce");
var lift_1 = require("../util/lift");
var arrReducer = function (arr, value) {
  return arr.push(value), arr;
};
function toArray() {
  return lift_1.operate(function (source, subscriber) {
    reduce_1.reduce(arrReducer, [])(source).subscribe(subscriber);
  });
}
exports.toArray = toArray;
