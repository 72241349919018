"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildQuery = void 0;
/**
 * Create a query string from the given set of key/value pairs.
 * Unlike using `new URLSearchParams({ ... })` directly, parameters with
 * undefined values are excluded.
 * @param query
 */
function buildQuery(query) {
  var params = new URLSearchParams();
  for (var _i = 0, _a = Object.entries(query); _i < _a.length; _i++) {
    var _b = _a[_i],
      key = _b[0],
      value = _b[1];
    if (value === undefined) continue;
    params.append(key, value);
  }
  return params.toString();
}
exports.buildQuery = buildQuery;
