import { Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
    name: 'as',
    pure: true,
})
export class AsPipe implements PipeTransform {

    transform<T>(value: any, _type: Type<T> | T): T;
    transform<T>(value: any, _type: 'any'): any;
    transform<T>(value: any, _type: 'number'): number;
    transform<T>(value: any, _type: 'string'): string;
    transform<T>(value: any, _type: 'boolean'): boolean;
    transform<T>(value: any, type: any): any {
        if (type === 'number') return Number(value);
        if (type === 'string') return String(value);
        if (type === 'boolean') return !!value;
        return value;
    }

}