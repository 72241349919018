<div class="standard-page-layout no-badge">
  <div class="row text-center">
    <div class="mx-auto text-left">
      <div>
        <h1>{{title}}</h1>

        <p class="main" [innerHTML]="message"></p>

        <div class="actions">
          <button type="button" mat-raised-button color="primary" (click)="close()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>