"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.concatAll = void 0;
var mergeAll_1 = require("./mergeAll");
function concatAll() {
  return mergeAll_1.mergeAll(1);
}
exports.concatAll = concatAll;
