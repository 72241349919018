import type { environment } from '@tytapp/environment';

const hasWindow = typeof globalThis['window'] !== 'undefined';
const hasWorkerScope = typeof globalThis['WorkerGlobalScope'] !== 'undefined';

/**
 * Returns true if we are running in the browser in a non-worker state.
 * @returns
 */
export const isClientSide = (hasWindow && !hasWorkerScope) ? () => true : () => false;

/**
 * Returns true if we are running in SSR.
 * @returns
 */
export const isServerSide = (!hasWindow && !hasWorkerScope) ? () => true : () => false;

/**
 * Returns true if we are running in a Web Worker on the client side.
 * @returns
 */
export const isWorkerSide = (!hasWindow && hasWorkerScope) ? () => true : () => false;

/**
 * Returns true if we are not online.
 * @returns
 */
export function isOffline() {
    return !isOnline();
}

export class OfflineError extends Error {
    constructor(readonly cause: string) {
        super('No network connection is available.');
    }
}

/**
 * Check the connectivity status of the client. On the server side this is always true. On the client/worker sides this is
 * true when navigator.onLine is true. On the client side (non-worker) supports overriding online state with a localStorage
 * value.
 * @returns
 */
export function isOnline() {
    if (isClientSide() && localStorage['tyt:online'] !== undefined) {
        return localStorage['tyt:online'] === '1';
    }


    if (isClientSide() || isWorkerSide()) {
        return navigator.onLine;
    }

    return true;
}

/**
 * Similar to isOnline(), except this will show an alert dialog when the user is not online. Use this for a consistent
 * error experience when checking for online status.
 * @returns
 */
export function networkAvailableForAction() {
    if (!isOnline()) {
        showErrorDueToOffline();
        return false;
    }

    return true;
}

export function showErrorDueToOffline() {
    alert(
        `A network connection is required to perform this action. `
        + `Please check your Internet connectivity and try again.`
    );
}

/**
 * Takes a URL that may be targeting a different environment (ie staging/production/dev, web/mobile) and conforms
 * it to the current origin so that it can be interpreted as a local link.
 * @param env
 * @param urlString
 * @returns
 */
export function rewriteUrl(env: typeof environment, urlString: string) {
    if (typeof urlString !== 'string')
        return urlString;

    if (!urlString || urlString.startsWith('javascript:') || urlString.startsWith('mailto:'))
        return urlString;

    let originalUrl = urlString;

    urlString = rewriteUrlToLocalOrigin(env, env.urls.canonicalRoot, urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'https://tyt.com', urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'http://localhost:4200', urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'http://mobile.tyt.com', urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'https://mobile.tyt.com', urlString);
    urlString = rewriteUrlToLocalOrigin(env, 'tyt://mobile.tyt.com', urlString);

    //alert(`rewrote from ${originalUrl} -> ${urlString}`);

    return urlString;
}

function rewriteUrlToLocalOrigin(env: typeof environment, rootUrlString: string, urlString: string) {
    let origin = isClientSide() ? location.origin : env.urls.webRoot;

    if (urlString.startsWith('/')) {
        urlString = `${origin}${urlString}`;
    }

    let url: URL;

    try {
        url = new URL(urlString);
    } catch (e) {
        console.error(`rewriteUrlToLocalOrigin(): Failed to parse URL '${urlString}': ${e.message}`);
        return urlString;
    }

    let rootUrl = new URL(rootUrlString);

    if (url.origin === rootUrl.origin && origin !== rootUrl.origin) {
        url = new URL(`${url.pathname}${url.search ? `${url.search}` : ``}`, origin);
        return url.toString();
    }

    return urlString;
}
