import { Injectable } from '@angular/core';
import { isClientSide } from '@tytapp/environment-utils';

import { parseQuery } from './url-utils';

@Injectable()
export class RequestParams {
    get params() {
        if (!isClientSide())
            return {};

        return parseQuery(location.search);
    }
}