"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.switchScan = void 0;
var switchMap_1 = require("./switchMap");
var lift_1 = require("../util/lift");
function switchScan(accumulator, seed) {
  return lift_1.operate(function (source, subscriber) {
    var state = seed;
    switchMap_1.switchMap(function (value, index) {
      return accumulator(state, value, index);
    }, function (_, innerValue) {
      return state = innerValue, innerValue;
    })(source).subscribe(subscriber);
    return function () {
      state = null;
    };
  });
}
exports.switchScan = switchScan;
