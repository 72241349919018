"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scheduleAsyncIterable = void 0;
var Observable_1 = require("../Observable");
var executeSchedule_1 = require("../util/executeSchedule");
function scheduleAsyncIterable(input, scheduler) {
  if (!input) {
    throw new Error('Iterable cannot be null');
  }
  return new Observable_1.Observable(function (subscriber) {
    executeSchedule_1.executeSchedule(subscriber, scheduler, function () {
      var iterator = input[Symbol.asyncIterator]();
      executeSchedule_1.executeSchedule(subscriber, scheduler, function () {
        iterator.next().then(function (result) {
          if (result.done) {
            subscriber.complete();
          } else {
            subscriber.next(result.value);
          }
        });
      }, 0, true);
    });
  });
}
exports.scheduleAsyncIterable = scheduleAsyncIterable;
