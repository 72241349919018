"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timestamp = void 0;
var dateTimestampProvider_1 = require("../scheduler/dateTimestampProvider");
var map_1 = require("./map");
function timestamp(timestampProvider) {
  if (timestampProvider === void 0) {
    timestampProvider = dateTimestampProvider_1.dateTimestampProvider;
  }
  return map_1.map(function (value) {
    return {
      value: value,
      timestamp: timestampProvider.now()
    };
  });
}
exports.timestamp = timestamp;
