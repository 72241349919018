"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommentsOrder = void 0;
var VALID_ORDERS = ['newest', 'oldest', 'likes'];
exports.CommentsOrder = {
  NEWEST: 'newest',
  OLDEST: 'oldest',
  LIKES: 'likes',
  options: VALID_ORDERS
};
