import { Component, Input } from "@angular/core";
import { PageComponent } from "./page-component";

@Component({
    selector: 'page-context',
    template: '<ng-content></ng-content>'
})
export class PageContextComponent {
    @Input()
    page: PageComponent;
}