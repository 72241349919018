import { Component, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { DialogComponent } from '../dialog-component';
import { NamedDialog } from '../named-dialog';
import { Transfer } from '../page-component';
import { Shell } from '../shell';

@NamedDialog('message')
@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent extends DialogComponent {
    private domSanitizer = inject(DomSanitizer);

    get pageComponentId() {
        return 'message';
    }

    @Transfer()
    title: string = '';

    @Transfer()
    message: any = '';

    callback: () => void;

    init(title: string, message?: string | any, callback?: () => void) {
        this.title = title;

        if (typeof message == 'object' && message.type == 'html') {
            this.message = this.domSanitizer.bypassSecurityTrustHtml(message.content);
        } else {
            this.message = message;
        }

        this.callback = callback;
    }

    onClosed(): void {
        this.callback?.();
    }
}

Shell.messageDialogComponent = MessageDialogComponent;