import { Directive, ElementRef, Input, OnDestroy, OnInit, inject } from '@angular/core';

import { ErrorProducer } from './error-producer';
import { FormComponent } from './form/form.component';
import { LoggerService } from './logger.service';

@Directive({
    selector: '[errorHighlight]'
})
export class ErrorHighlightDirective implements OnInit, OnDestroy {
    private element: ElementRef<HTMLInputElement | HTMLTextAreaElement> = inject(ElementRef);
    private form = inject(FormComponent, { optional: true });
    private logger = inject(LoggerService);

    @Input() errorHighlight: string;
    @Input() name: string;

    private _sub;

    get field() {
        return this.errorHighlight || this.name;
    }

    @Input()
    producer: ErrorProducer = this.form;

    ngOnInit() {
        this._sub = this.producer.errorChanged.subscribe(() => this.update());
        this.element.nativeElement.addEventListener('focus', this._onFocus = () => this.onFocus());
    }

    ngOnDestroy() {
        if (this._sub)
            this._sub.unsubscribe();
    }

    private _onFocus;

    onFocus() {

        let errorInfo: HTMLElement = <HTMLElement>this.element.nativeElement.previousElementSibling;

        if (!errorInfo) {
            this.logger.error(`ErrorHighlight: Could not locate label element!`);
            return;
        }

        if (!errorInfo.classList.contains('error-info'))
            errorInfo = null;

        if (errorInfo) {
            errorInfo.remove();
        }
    }

    update() {
        let hasError = false;
        let errorMessage = "Invalid value";
        let el: HTMLElement = this.element.nativeElement;
        let errorInfo: HTMLElement = <HTMLElement>el.previousElementSibling;

        if (errorInfo && !errorInfo.classList.contains('error-info')) {
            errorInfo = null;
        }

        if (this.field) {
            let fieldError = null;
            if (this.producer.fieldErrors)
                fieldError = this.producer.fieldErrors.find(x => x.field == this.field);

            if (fieldError) {
                errorMessage = fieldError.message;
                hasError = true;
            } else {
                hasError = false;
            }
        } else {
            hasError = this.producer.error;
        }

        if (hasError) {
            this.element.nativeElement.classList.add("error");

            if (errorMessage)
                this.element.nativeElement.title = errorMessage;

            if (errorMessage) {
                if (!errorInfo) {
                    errorInfo = document.createElement('div');
                    errorInfo.classList.add('error-info');
                    el.parentElement.insertBefore(errorInfo, el);
                }

                errorInfo.innerHTML = errorMessage;
                errorInfo.style.top = el.offsetTop + el.offsetHeight + 'px';
                errorInfo.style.left = el.offsetLeft + 'px';
            }

            if (this.producer.fieldErrors[0]?.field === this.field) {
                setTimeout(() => {
                    this.element.nativeElement.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth'
                    });
                }, 300);
            }

        } else {
            this.element.nativeElement.classList.remove("error");
            this.element.nativeElement.title = "";

            if (errorInfo)
                errorInfo.remove();
        }
    }
}