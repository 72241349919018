"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapTo = void 0;
var map_1 = require("./map");
function mapTo(value) {
  return map_1.map(function () {
    return value;
  });
}
exports.mapTo = mapTo;
