"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CDNProvider = void 0;
var CDNProvider = /** @class */function () {
  function CDNProvider() {}
  return CDNProvider;
}();
exports.CDNProvider = CDNProvider;
