"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.startWith = void 0;
var concat_1 = require("../observable/concat");
var args_1 = require("../util/args");
var lift_1 = require("../util/lift");
function startWith() {
  var values = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    values[_i] = arguments[_i];
  }
  var scheduler = args_1.popScheduler(values);
  return lift_1.operate(function (source, subscriber) {
    (scheduler ? concat_1.concat(values, source, scheduler) : concat_1.concat(values, source)).subscribe(subscriber);
  });
}
exports.startWith = startWith;
