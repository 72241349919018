"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.expand = void 0;
var lift_1 = require("../util/lift");
var mergeInternals_1 = require("./mergeInternals");
function expand(project, concurrent, scheduler) {
  if (concurrent === void 0) {
    concurrent = Infinity;
  }
  concurrent = (concurrent || 0) < 1 ? Infinity : concurrent;
  return lift_1.operate(function (source, subscriber) {
    return mergeInternals_1.mergeInternals(source, subscriber, project, concurrent, undefined, true, scheduler);
  });
}
exports.expand = expand;
