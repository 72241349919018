"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.skip = void 0;
var filter_1 = require("./filter");
function skip(count) {
  return filter_1.filter(function (_, index) {
    return count <= index;
  });
}
exports.skip = skip;
