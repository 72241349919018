import { Directive, HostListener, inject, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { QueryParamsHandling, Router } from '@angular/router';
import { Subject } from 'rxjs';


@Directive({
    selector: 'form[action][method="get"]:not([ngSubmit]):not([submit]):not([onSubmit])'
})
export class FormGetDirective {
    private ngForm = inject(NgForm);
    private router = inject(Router);

    @Input()
    queryParamsHandling: QueryParamsHandling;

    @Input()
    queryParamsReviser: (params: URLSearchParams) => void;

    @Output()
    formGetSubmitted = new Subject<URLSearchParams>();

    @HostListener('ngSubmit', ['$event'])
    onNgSubmit(event: Event) {
        if (this.ngForm.form.valid) {
            const form = event.target as HTMLFormElement;

            const searchParams = new URLSearchParams(new FormData(form) as any);
            if (this.queryParamsHandling === 'merge') {
                this.mergeQueryParams(searchParams);
            }

            this.queryParamsReviser?.(searchParams);

            this.router.navigateByUrl(`${ new URL(form.action).pathname }?${ searchParams.toString() }`);

            this.formGetSubmitted.next(searchParams);
        }
    }

    mergeQueryParams(params: URLSearchParams) {
        // @ts-ignore .keys not recognized because of the js version.
        const takenKeys = new Set(params.keys());

        new URLSearchParams(location.search).forEach((value, key) => {
            if (!takenKeys.has(key)) {
                params.append(key, value);
            }
        });
    }
}
