import { Pipe, PipeTransform, inject } from '@angular/core';

import { MarkdownToHtmlPipe } from './markdown-to-html.pipe';
import { PlainTextToHtmlPipe } from './plaintext-to-html.pipe';

/**
 * A convenience filter which invokes either PlainTextToHtmlPipe or MarkdownToHtmlPipe depending on the format
 * parameter.
 */
@Pipe({
    name: 'formatToHtml'
})
export class FormatToHtmlPipe implements PipeTransform {
    private markdownToHtml = inject(MarkdownToHtmlPipe);
    private plainTextToHtml = inject(PlainTextToHtmlPipe);

    transform(value: string, format: 'plain' | 'markdown'): string {
        if (!value)
            return '';

        if (format === 'plain')
            return this.plainTextToHtml.transform(value);
        else if (format === 'markdown')
            return this.markdownToHtml.transform(value);

        // SECURITY: Do not return the value alone, as this pipe is often paired with trustHtml,
        // and that could be an XSS risk.

        return `Invalid format '${format}'`;
    }
}
