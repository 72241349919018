"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.skipWhile = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function skipWhile(predicate) {
  return lift_1.operate(function (source, subscriber) {
    var taking = false;
    var index = 0;
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      return (taking || (taking = !predicate(value, index++))) && subscriber.next(value);
    }));
  });
}
exports.skipWhile = skipWhile;
