
/**
 * Visit the given object and all sub-objects within its graph (by iterating all keys and all sub-keys recursively).
 * @param object
 * @param visitor
 * @returns
 */
export function visitAllSubObjects(object: object | object[], visitor: (object: object) => boolean, visited: object[] = []) {
    if (visited.includes(object))
        return true;

    visited.push(object);

    if (!Array.isArray(object) && !visitor(object))
        return false;

    for (let key of Object.keys(object)) {
        if (object[key] && typeof object[key] === 'object') {
            visitAllSubObjects(object[key], visitor, visited);
        }
    }

    return true;
}