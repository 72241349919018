"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arrRemove = void 0;
function arrRemove(arr, item) {
  if (arr) {
    var index = arr.indexOf(item);
    0 <= index && arr.splice(index, 1);
  }
}
exports.arrRemove = arrRemove;
