"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEmpty = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function isEmpty() {
  return lift_1.operate(function (source, subscriber) {
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function () {
      subscriber.next(false);
      subscriber.complete();
    }, function () {
      subscriber.next(true);
      subscriber.complete();
    }));
  });
}
exports.isEmpty = isEmpty;
