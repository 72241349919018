"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFunction = void 0;
function isFunction(value) {
  return typeof value === 'function';
}
exports.isFunction = isFunction;
