import { Component, ElementRef, Input, OnInit, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorProducer } from '../error-producer';
import { FieldError } from '../field-error';
import { FormHandler, FormHandlingComponent } from '../form-handler';
import { LoggerService } from '../logger.service';
import { ObjectValidator } from '../validation';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, ErrorProducer {
    private formHandler = inject(FormHandler);
    private element: ElementRef<HTMLElement> = inject(ElementRef);
    private logger = inject(LoggerService);

    async ngOnInit() {
        if (!this.handler)
            return;

        if (!this.action)
            this.action = this.formHandler.currentPath;

        await this.formHandler.handle(this.handler);

        if (!this.isServer)
            this.populateServerFormData();
    }

    ngAfterViewInit() {
        if (!this.isServer)
            this.focusSelectedElement();
    }

    readonly isServer = this.formHandler.isServer;

    errorMessage: string;
    fieldErrors: FieldError[];
    errorChanged = new BehaviorSubject<boolean>(false);

    @Input() action: string = null;
    @Input() handler: FormHandlingComponent;

    public _error: boolean = false;
    public get error() { return this._error; }
    public set error(value: boolean) {
        this._error = value;
        this.errorChanged.next(value);
    }

    get hasErrors() {
        return this.fieldErrors.length > 0;
    }

    public clearErrors() {
        this.errorMessage = '';
        this.fieldErrors = [];
        this.error = false;
    }

    public removeFieldError(field: string) {
        if (!this.fieldErrors)
            this.fieldErrors = [];

        this.fieldErrors = this.fieldErrors.filter(x => x.field != field);
        this.error = this.fieldErrors.length > 0;
    }

    public getFieldError(field: string) {
        return this.fieldErrors?.find(x => x.field === field)?.message;
    }

    public hasFieldError(field: string) {
        return !!this.fieldErrors?.find(x => x.field === field);
    }

    public addFieldError(field: string, message?: string) {
        if (!this.fieldErrors)
            this.fieldErrors = [];

        this.removeFieldError(field);
        this.fieldErrors.push({
            field, message
        });
        this.error = true;
    }

    validate(message: string = null) {
        if (!this.handler)
            throw new Error(`You must attach a [handler] to the form component to use the validate() method`);

        let validator = new ObjectValidator(this.handler);
        let result = validator.validate();

        if (result === true)
            return result;

        let fieldErrors = [];

        if (result instanceof Array)
            fieldErrors = result;

        this.showError(message || 'Please correct errors below.', fieldErrors);
    }

    public showError(message: string, fieldErrors?: FieldError[]) {

        this.logger.error('Error shown');
        this.logger.error('Message: ' + message);
        this.logger.inspect(fieldErrors);

        this.errorMessage = message;
        if (fieldErrors !== undefined)
            this.fieldErrors = fieldErrors;
        this.error = true;
    }

    populateServerFormData() {
        let formData = window['__ssrFormData'];
        if (!formData)
            return;

        let el: Element = this.element.nativeElement;
        let formEl: HTMLFormElement = el.querySelector('form');
        let thisFormData = formData[this.action];
        if (!thisFormData)
            return;

        this.formHandler.applyData(this.handler, thisFormData);
    }

    focusSelectedElement() {
        let formData = window['__ssrFormData'];
        if (!formData)
            return;

        let el: Element = this.element.nativeElement;
        let formEl: HTMLFormElement = el.querySelector('form');

        // Focus...

        if (formData['focused']) {
            let [action, inputName] = formData['focused'];
            if (action == this.action) {
                let input: HTMLInputElement = <any>formEl.querySelector(`[name=${inputName}]`);
                input.focus();
            }
        }
    }

    sendSubmit() {
        if (this.handler)
            this.handler.submit();
    }
}
