import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@tytapp/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { LoggerService } from './logger.service';

export interface OpenGraphMetadata {
    title?: string;
    type?: string;
    description?: string;
    url?: string;
    locale?: string;
    localeAlternate?: string;
    siteName?: string;

    image?: string;
    imageType?: string;
    imageWidth?: number;
    imageHeight?: number;

    audio?: string;
    audioType?: string;

    video?: string;
    videoWidth?: number;
    videoHeight?: number;
    videoType?: string;
    articlePublishedTime?: string;
    articleModifiedTime?: string;
}

@Injectable()
export class OpenGraph {
    constructor(
        @Inject(DOCUMENT)
        private _doc: any,
        private meta: Meta,
        private router: Router,
        rendererFactory: RendererFactory2,
        private logger: LoggerService
    ) {
        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart)
                this.clearMetadata();
        });

        this.renderer2 = rendererFactory.createRenderer(this._doc, null);
    }

    renderer2: Renderer2;

    public clearMetadata() {
        this.meta.removeTag('property^="og:"');
        this.meta.removeTag('property^="twitter:"');
    }

    private _metadataChanged = new BehaviorSubject<OpenGraphMetadata>(null);

    get metadataChanged(): Observable<OpenGraphMetadata> {
        return this._metadataChanged;
    }

    private setProperty(prop: string, value: any) {
        if (value === undefined)
            return;

        this.meta.addTag({
            property: `og:${prop}`,
            content: `${value}`
        });
    }

    private _previousLdJsonNode: any;

    setLdJson(value: any) {
        try {
            if (this._previousLdJsonNode) {
                this.renderer2.removeChild(this.renderer2.parentNode(this._previousLdJsonNode), this._previousLdJsonNode);
            }

            let script = this.renderer2.createElement('script');
            this.renderer2.setAttribute(script, 'type', 'application/ld+json');
            let text = this.renderer2.createText(JSON.stringify(value, undefined, 2));
            this.renderer2.appendChild(script, text);
            this.renderer2.appendChild(this._doc.head, script);

            this._previousLdJsonNode = script;
        } catch (e) {
            this.logger.error(`Failed to set LD+JSON:`);
            this.logger.error(e);
        }
    }

    private setTwitterProperty(prop: string, value: any) {
        if (value === undefined)
            return;

        const tag = {
            property: `twitter:${ prop }`,
            content: `${ value }`
        };

        const selector = `property="${ tag.property }"`;
        if (this.meta.getTag(selector)) {
            this.meta.removeTag(selector);
        }

        this.meta.addTag(tag);
    }

    private metadata: OpenGraphMetadata;

    public setMetadata(metadata: OpenGraphMetadata) {
        this.metadata = metadata;
        this._metadataChanged.next(metadata);

        // OG
        this.setProperty('title', metadata.title);
        this.setProperty('type', metadata.type);
        this.setProperty('description', metadata.description);
        this.setProperty('url', metadata.url);
        this.setProperty('locale', metadata.locale);
        this.setProperty('locale:alternate', metadata.localeAlternate);
        this.setProperty('site_name', metadata.siteName);

        this.setProperty('image', metadata.image);
        this.setProperty('image:type', metadata.imageType);
        this.setProperty('image:width', metadata.imageWidth);
        this.setProperty('image:height', metadata.imageHeight);

        this.setProperty('audio', metadata.audio);
        this.setProperty('audio:type', metadata.audioType);

        this.setProperty('video', metadata.video);
        this.setProperty('video:width', metadata.videoWidth);
        this.setProperty('video:height', metadata.videoHeight);
        this.setProperty('video:type', metadata.videoType);
        this.setProperty('article:published_time', metadata.articlePublishedTime);
        this.setProperty('article:modified_time', metadata.articleModifiedTime);

        // TWITTER
        this.setTwitterProperty('title', metadata.title);
        this.setTwitterProperty('card', 'summary_large_image');
        this.setTwitterProperty('site', '@TYT');
        this.setTwitterProperty('description', metadata.description);
        this.setTwitterProperty('image', metadata.image);

    }

    public setTwitterPlayerMetadata(params: { [k: string]: string }) {
        const searchParams = new URLSearchParams(params);
        this.setTwitterProperty('card', 'player');
        this.setTwitterProperty('site', 'tyt');
        this.setTwitterProperty('player', `${ environment.urls.webRoot }/assets/twitter-player.html?${ searchParams.toString() }`);
        this.setTwitterProperty('player:width', '480');
        this.setTwitterProperty('player:height', '480');
    }
}
