"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilterMode = void 0;
var VALID_FILTERS = ['all', 'mine', 'threads', 'my-likes'];
exports.FilterMode = {
  ALL: 'all',
  MINE: 'mine',
  THREADS: 'threads',
  MY_LIKES: 'my-likes',
  options: VALID_FILTERS
};
