import { HostBinding, Component } from '@angular/core';
import { PageComponent } from './page-component';

/**
 * Base type for Dialog components.
 * IMPORTANT: Review the "Dialogs" section of README.md for important steps.
 */
@Component({
    template: ''
})
export class DialogComponent extends PageComponent {
    @HostBinding('class.dialog-content')
    isDialogContent: boolean = true;

    public init(...args) {

    }

    /**
     * Called when the dialog is closed or replaced. Override to do something useful.
     */
    onClosed() {

    }

    public showDialog(name: string | any, ...args: any[]) {
        this.shell.showDialog(name, ...args);
    }

    public closeBack() {
        history.back();
    }

    public close() {
        this.shell.hideDialog();
    }
}