"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.switchMapTo = void 0;
var switchMap_1 = require("./switchMap");
var isFunction_1 = require("../util/isFunction");
function switchMapTo(innerObservable, resultSelector) {
  return isFunction_1.isFunction(resultSelector) ? switchMap_1.switchMap(function () {
    return innerObservable;
  }, resultSelector) : switchMap_1.switchMap(function () {
    return innerObservable;
  });
}
exports.switchMapTo = switchMapTo;
