import { Pipe } from "@angular/core";

@Pipe({ name: 'asDate' })
export class AsDatePipe {
    constructor() {

    }

    transform(value: any, format?: string) {
        if (!value) {
            return null;
        }

        let splitDate = value.split('T')[0];

        if (!splitDate) {
            return value;
        }

        let yearMonthDay = splitDate.split('-').map(x => parseInt(x));
        return new Date(yearMonthDay[0], yearMonthDay[1] - 1, yearMonthDay[2]);
    }
}