import { Pipe } from "@angular/core";

@Pipe({ name: 'duration' })
export class DurationPipe {
    constructor() {

    }

    private pad(n: number, width: number, z?: string) {
        z = z || '0';
        let ns = n + '';
        return ns.length >= width ? n : new Array(width - ns.length + 1).join(z) + n;
    }

    transform(value: number, format?: string) {
        if (!value)
            return "00:00";

        let parts = [];
        let short = false;
        let includeSeconds = false;

        if (format == 'short-seconds') {
            short = true;
            includeSeconds = true;
        } else if (format == 'short') {
            short = true;
        } else if (format == 'seconds') {
            includeSeconds = true;
        }

        if (value > 60 * 60) {
            let hours = Math.floor(value / (60 * 60));

            if (short)
                parts.push(`${this.pad(hours, 2)}`);
            else
                parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);

            value -= hours * 60 * 60;
        }

        if (value > 60 || short) {
            let minutes = Math.floor(value / 60);
            value -= minutes * 60;
            if (short)
                parts.push(`${this.pad(minutes, 2)}`);
            else
                parts.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
        }

        if (includeSeconds) {
            let seconds = Math.floor(value);
            if (short)
                parts.push(`${this.pad(seconds, 2)}`);
            else
                parts.push(`${seconds} second${seconds !== 1 ? 's' : ''}`);
        }

        if (short)
            return parts.join(':');
        return parts.join(', ');
    }
}