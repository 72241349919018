"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scheduleIterable = void 0;
var Observable_1 = require("../Observable");
var iterator_1 = require("../symbol/iterator");
var isFunction_1 = require("../util/isFunction");
var executeSchedule_1 = require("../util/executeSchedule");
function scheduleIterable(input, scheduler) {
  return new Observable_1.Observable(function (subscriber) {
    var iterator;
    executeSchedule_1.executeSchedule(subscriber, scheduler, function () {
      iterator = input[iterator_1.iterator]();
      executeSchedule_1.executeSchedule(subscriber, scheduler, function () {
        var _a;
        var value;
        var done;
        try {
          _a = iterator.next(), value = _a.value, done = _a.done;
        } catch (err) {
          subscriber.error(err);
          return;
        }
        if (done) {
          subscriber.complete();
        } else {
          subscriber.next(value);
        }
      }, 0, true);
    });
    return function () {
      return isFunction_1.isFunction(iterator === null || iterator === void 0 ? void 0 : iterator.return) && iterator.return();
    };
  });
}
exports.scheduleIterable = scheduleIterable;
