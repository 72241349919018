
function zp(number: number, digits = 2) {
    let str = '' + number;
    while (str.length < 2)
        str = '0' + str;
    return str;
}

export function formatDateUS(date: Date) {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export function formatTimeUS(date: Date) {
    let h = date.getHours(), m = date.getMinutes();

    if (h >= 12)
        return `${h > 12 ? h - 12 : h}:${zp(m)} PM`;
    else
        return `${h ? h : 12}:${zp(m)} AM`;
}

export function formatDateTimeUS(date: Date) {
    return `${formatDateUS(date)} ${formatTimeUS(date)}`;
}