"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationsProvider = void 0;
var NotificationsProvider = /** @class */function () {
  function NotificationsProvider() {}
  return NotificationsProvider;
}();
exports.NotificationsProvider = NotificationsProvider;
