import { Component, Input, ViewContainerRef } from '@angular/core';
import { PageComponent } from '../page-component';

@Component({
    selector: 'load-guard',
    templateUrl: './load-guard.component.html',
    styleUrls: ['./load-guard.component.scss']
})
export class LoadGuardComponent {

    constructor(
        private viewContainer: ViewContainerRef
    ) { }

    get loadError(): boolean {
        let page = this.page;
        if (!page)
            return;

        return this.page.loadError;
    }

    @Input()
    host: PageComponent;

    get page() {
        return this.host;
    }

}
