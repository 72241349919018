"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.firstValueFrom = void 0;
var EmptyError_1 = require("./util/EmptyError");
var Subscriber_1 = require("./Subscriber");
function firstValueFrom(source, config) {
  var hasConfig = typeof config === 'object';
  return new Promise(function (resolve, reject) {
    var subscriber = new Subscriber_1.SafeSubscriber({
      next: function (value) {
        resolve(value);
        subscriber.unsubscribe();
      },
      error: reject,
      complete: function () {
        if (hasConfig) {
          resolve(config.defaultValue);
        } else {
          reject(new EmptyError_1.EmptyError());
        }
      }
    });
    source.subscribe(subscriber);
  });
}
exports.firstValueFrom = firstValueFrom;
