"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defer = void 0;
var Observable_1 = require("../Observable");
var innerFrom_1 = require("./innerFrom");
function defer(observableFactory) {
  return new Observable_1.Observable(function (subscriber) {
    innerFrom_1.innerFrom(observableFactory()).subscribe(subscriber);
  });
}
exports.defer = defer;
