"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iif = void 0;
var defer_1 = require("./defer");
function iif(condition, trueResult, falseResult) {
  return defer_1.defer(function () {
    return condition() ? trueResult : falseResult;
  });
}
exports.iif = iif;
