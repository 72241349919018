"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sampleTime = void 0;
var async_1 = require("../scheduler/async");
var sample_1 = require("./sample");
var interval_1 = require("../observable/interval");
function sampleTime(period, scheduler) {
  if (scheduler === void 0) {
    scheduler = async_1.asyncScheduler;
  }
  return sample_1.sample(interval_1.interval(period, scheduler));
}
exports.sampleTime = sampleTime;
