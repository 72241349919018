import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'plainTextToHtml'
})
export class PlainTextToHtmlPipe implements PipeTransform {
    private domSanitizer = inject(DomSanitizer);

    transform(value: string, kind = 'internal'): string {
        if (!value)
            return '';

        // SECURITY: XSS RISKS
        // Angular does not officially expose an HTML escape mechanism,
        // so we'll do so here. It is only safe to use outputs from this within HTML
        // content contexts (ie `<div>$var</div>`) and not in HTML attribute contexts
        // or Javascript contexts. Thankfully Angular's security system will still require
        // innerHTML binding and use of the DomSanitizer service.

        value = value.replace(/&/g, '&amp;');
        value = value.replace(/</g, '&lt;');
        value = value.replace(/>/g, '&gt;');
        value = value.replace(/"/g, '&quot;');
        value = value.replace(/'/g, '&#x27;');

        ////////////////////

        value = value.replace(/\r?\n/g, '<br/>\n');

        return value;
    }
}
