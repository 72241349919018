import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Shell } from './shell';

@Component({
    template: ``,
    styles: [``]
})
export class DialogBackerComponent {
    constructor(
        private shell: Shell,
        private router: Router
    ) {
    }

    subscriptions = new Subscription();
    dialogClosedTimer;
    ngOnInit() {
        let dialogStarted = false;
        this.subscriptions.add(this.shell.dialogChanged.subscribe(dialog => {
            clearTimeout(this.dialogClosedTimer);
            if (dialogStarted && !dialog) {
                this.dialogClosedTimer = setTimeout(() => {
                    this.router.navigateByUrl('/');
                }, 500);
            } else if (dialog) {
                dialogStarted = true;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}