"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pluck = void 0;
var map_1 = require("./map");
function pluck() {
  var properties = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    properties[_i] = arguments[_i];
  }
  var length = properties.length;
  if (length === 0) {
    throw new Error('list of properties cannot be empty.');
  }
  return map_1.map(function (x) {
    var currentProp = x;
    for (var i = 0; i < length; i++) {
      var p = currentProp === null || currentProp === void 0 ? void 0 : currentProp[properties[i]];
      if (typeof p !== 'undefined') {
        currentProp = p;
      } else {
        return undefined;
      }
    }
    return currentProp;
  });
}
exports.pluck = pluck;
