import { Subject, Observable } from "rxjs";

export interface Notice {
    message: string;
    severity: string;
}

export class NoticeService {
    public post(message: string, severity: string) {
        this._notices.push({ message, severity });
    }

    public error(message: string) {
        this.post(message, 'danger');
    }

    public warning(message: string) {
        this.post(message, 'warning');
    }

    public success(message: string) {
        this.post(message, 'success');
    }

    public info(message: string) {
        this.post(message, 'info');
    }

    private _notices: Notice[] = [];

    private _dismissed: Subject<void> = new Subject<void>();

    public get dismissed(): Observable<void> {
        return this._dismissed;
    }

    public dismissNotices() {
        this._dismissed.next();
    }

    public dequeueNotices() {
        let notices = this._notices;
        this._notices = [];
        return notices;
    }
}