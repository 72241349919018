"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Injectable = void 0;
/**
 * Mark a service as participating in dependency injection.
 * This is a stub that ensures Typescript will emit metadata
 * for such classes so that DI frameworks can handle providing
 * dependencies
 *
 * @returns
 */
function Injectable() {
  return function (target) {};
}
exports.Injectable = Injectable;
