"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.publishReplay = void 0;
var ReplaySubject_1 = require("../ReplaySubject");
var multicast_1 = require("./multicast");
var isFunction_1 = require("../util/isFunction");
function publishReplay(bufferSize, windowTime, selectorOrScheduler, timestampProvider) {
  if (selectorOrScheduler && !isFunction_1.isFunction(selectorOrScheduler)) {
    timestampProvider = selectorOrScheduler;
  }
  var selector = isFunction_1.isFunction(selectorOrScheduler) ? selectorOrScheduler : undefined;
  return function (source) {
    return multicast_1.multicast(new ReplaySubject_1.ReplaySubject(bufferSize, windowTime, timestampProvider), selector)(source);
  };
}
exports.publishReplay = publishReplay;
