"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeInterval = exports.timeInterval = void 0;
var async_1 = require("../scheduler/async");
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function timeInterval(scheduler) {
  if (scheduler === void 0) {
    scheduler = async_1.asyncScheduler;
  }
  return lift_1.operate(function (source, subscriber) {
    var last = scheduler.now();
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      var now = scheduler.now();
      var interval = now - last;
      last = now;
      subscriber.next(new TimeInterval(value, interval));
    }));
  });
}
exports.timeInterval = timeInterval;
var TimeInterval = function () {
  function TimeInterval(value, interval) {
    this.value = value;
    this.interval = interval;
  }
  return TimeInterval;
}();
exports.TimeInterval = TimeInterval;
