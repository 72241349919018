@if (devTools) {
  <div class="devtools">
    Hosted
    <pre [title]="frameUrl">{{ frameUrl }}</pre>
  </div>
}

<iframe
  #frame
  [src]="frameUrl | trustResourceUrl"
  ></iframe>