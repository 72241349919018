import { Shell } from './shell';

export function NamedDialog(name: string) {
    return (target) => {
        if (Reflect.defineMetadata) {
            Reflect.defineMetadata('page:id', name, target);
            Reflect.defineMetadata('dialog:name', name, target);
        }
        Shell.registerBuiltInDialog(name, target);
    };
}