"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromSubscribable = void 0;
var Observable_1 = require("../Observable");
function fromSubscribable(subscribable) {
  return new Observable_1.Observable(function (subscriber) {
    return subscribable.subscribe(subscriber);
  });
}
exports.fromSubscribable = fromSubscribable;
