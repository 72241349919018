"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SocketRPC = exports.RpcEvent = exports.RpcCallable = void 0;
var rxjs_1 = require("rxjs");
var uuid_1 = require("uuid");
var durable_socket_1 = require("./durable-socket");
function isRequest(message) {
  return message.type === 'request';
}
function isResponse(message) {
  return message.type === 'response';
}
function isEvent(message) {
  return message.type === 'event';
}
function RpcCallable(options) {
  return function (target, propertyKey) {
    if (Reflect.defineMetadata) {
      Reflect.defineMetadata('rpc:type', 'call', target, propertyKey);
      Reflect.defineMetadata('rpc:callableOptions', options !== null && options !== void 0 ? options : {}, target, propertyKey);
    } else {
      console.warn("[Banta] Warning: reflect-metadata must be loaded for Banta to work properly");
    }
  };
}
exports.RpcCallable = RpcCallable;
function RpcEvent() {
  return function (target, propertyKey) {
    if (Reflect.defineMetadata) Reflect.defineMetadata('rpc:type', 'event', target, propertyKey);else console.warn("[Banta] Warning: reflect-metadata must be loaded for Banta to work properly");
  };
}
exports.RpcEvent = RpcEvent;
var SocketRPC = /** @class */function () {
  function SocketRPC() {
    this._callMap = new Map();
    this._eventMap = new Map();
    this.retryOnReconnectQueue = [];
    this._peer = this.createPeer({
      immediate: false
    });
    this._immediatePeer = this.createPeer({
      immediate: true
    });
    this._idempotentPeer = this.createPeer({
      idempotent: true
    });
  }
  SocketRPC.prototype.createPeer = function (options) {
    var self = this;
    var methodMap = new Map();
    return new Proxy({}, {
      get: function (target, methodName, receiver) {
        if (methodMap.has(methodName)) return methodMap.get(methodName);
        var method = function () {
          var parameters = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            parameters[_i] = arguments[_i];
          }
          return self.call.apply(self, __spreadArray([options, String(methodName)], parameters, false));
        };
        methodMap.set(methodName, method);
        return method;
      }
    });
  };
  SocketRPC.prototype.bind = function (socket) {
    return __awaiter(this, void 0, void 0, function () {
      var _this = this;
      return __generator(this, function (_a) {
        if (this._socket) throw new Error("Already bound");
        this._socket = socket;
        this._socket.onmessage = function (ev) {
          return _this.onReceiveMessage(JSON.parse(ev.data));
        };
        this._socket.onerror = function (ev) {
          return console.error("[Banta/RPC] Socket reports error.");
        };
        if (this._socket instanceof durable_socket_1.DurableSocket) {
          console.log("[Banta/RPC] Detected DurableSocket, enabling enhancements");
          this._socket.addEventListener('lost', function () {
            return _this.handleStateLost();
          });
          this._socket.addEventListener('ready', function () {
            return _this.resendQueuedRequests();
          });
        }
        return [2 /*return*/, this];
      });
    });
  };
  Object.defineProperty(SocketRPC.prototype, "peer", {
    get: function () {
      return this._peer;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SocketRPC.prototype, "immediatePeer", {
    /**
     * Get a peer proxy which performs all of its calls in "immediate mode". Immediate mode
     * bypasses the message queue and attempts to send the message on the socket immediately even if
     * it appears the socket is not ready. This is needed when sending messages to Banta during the 'restore'
     * event handler of DurableSocket.
     */
    get: function () {
      return this._immediatePeer;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SocketRPC.prototype, "idempotentPeer", {
    /**
     * Get a peer proxy which performs all of its calls in "idempotent mode". Idempotent requests
     * can be resent if connection fails after the initial request was sent, because it is assumed the
     * server will correctly discard the request if it has already been processed.
     */
    get: function () {
      return this._idempotentPeer;
    },
    enumerable: false,
    configurable: true
  });
  SocketRPC.prototype.getEventInternal = function (name) {
    if (!this._eventMap.has(name)) {
      this._eventMap.set(name, new rxjs_1.Subject());
    }
    return this._eventMap.get(name);
  };
  SocketRPC.prototype.rawSend = function (message, immediate) {
    if (immediate === void 0) {
      immediate = false;
    }
    if (this._socket instanceof durable_socket_1.DurableSocket) {
      if (immediate) {
        this._socket.sendImmediately(JSON.stringify(message));
      } else {
        this._socket.send(JSON.stringify(message));
      }
    } else {
      this._socket.send(JSON.stringify(message));
    }
  };
  SocketRPC.prototype.sendEvent = function (name, object) {
    this.rawSend({
      type: 'event',
      name: name,
      object: object
    });
  };
  SocketRPC.prototype.reconnect = function () {
    if (this._socket instanceof durable_socket_1.DurableSocket) this._socket.reconnect();
  };
  SocketRPC.prototype.call = function (options, method) {
    var _this = this;
    var parameters = [];
    for (var _i = 2; _i < arguments.length; _i++) {
      parameters[_i - 2] = arguments[_i];
    }
    var rpcRequest = {
      type: 'request',
      id: (0, uuid_1.v4)(),
      method: method,
      parameters: parameters
    };
    return new Promise(function (resolve, reject) {
      _this.startCall({
        id: rpcRequest.id,
        request: rpcRequest,
        options: options,
        idempotent: options.idempotent,
        handler: function (response) {
          if (response.error) reject(response.error);else resolve(response.value);
        }
      });
    });
  };
  SocketRPC.prototype.startCall = function (request) {
    var _a;
    // Short circuit: If we are not currently connected and the call is idempotent, go directly to 
    // retry queue.
    if (this._socket instanceof durable_socket_1.DurableSocket && !this._socket.isReady && request.options.idempotent) {
      console.warn("[Banta/RPC] Call ".concat(request.request.method, "() is being scheduled to send when connection is restored."));
      this.retryOnReconnectQueue.push(request);
      return;
    }
    this._callMap.set(request.id, request);
    this.rawSend(request.request, (_a = request.options.immediate) !== null && _a !== void 0 ? _a : false);
  };
  SocketRPC.prototype.resendQueuedRequests = function () {
    var _this = this;
    var calls = this.retryOnReconnectQueue.splice(0);
    console.log("Resending ".concat(calls.length, " idempotent call requests..."));
    calls.forEach(function (req) {
      return _this.startCall(req);
    });
  };
  SocketRPC.prototype.handleStateLost = function () {
    var failed = 0;
    var rescheduled = 0;
    for (var _i = 0, _a = Array.from(this._callMap.entries()); _i < _a.length; _i++) {
      var _b = _a[_i],
        id = _b[0],
        request = _b[1];
      if (request.idempotent) {
        this.retryOnReconnectQueue.push(request);
        rescheduled += 1;
        continue;
      }
      request.handler({
        id: id,
        type: 'response',
        error: new Error("Connection was lost"),
        value: undefined
      });
      failed += 1;
    }
    this._callMap.clear();
    if (failed > 0) console.error("[Banta/RPC] Failed ".concat(failed, " in-flight requests due to connection failure"));
    if (rescheduled > 0) console.warn("[Banta/RPC] Rescheduled ".concat(rescheduled, " in-flight requests due to connection failure"));
  };
  SocketRPC.prototype.onReceiveMessage = function (message) {
    return __awaiter(this, void 0, void 0, function () {
      var value, error, e_1, _a, request, handler;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (!isRequest(message)) return [3 /*break*/, 7];
            if (!(this.getRpcType(message.method) === 'call' && typeof this[message.method] === 'function')) return [3 /*break*/, 5];
            value = void 0;
            error = void 0;
            _b.label = 1;
          case 1:
            _b.trys.push([1, 3,, 4]);
            return [4 /*yield*/, this[message.method].apply(this, message.parameters)];
          case 2:
            value = _b.sent();
            return [3 /*break*/, 4];
          case 3:
            e_1 = _b.sent();
            if (e_1 instanceof Error) {
              error = {
                message: e_1.message,
                stack: e_1.stack
              };
            } else {
              error = e_1;
            }
            return [3 /*break*/, 4];
          case 4:
            this.rawSend({
              type: 'response',
              id: message.id,
              value: value,
              error: error
            });
            return [2 /*return*/];
          case 5:
            this.rawSend({
              type: 'response',
              id: message.id,
              error: {
                code: 'invalid-call',
                message: "No such method '".concat(message.method, "'")
              }
            });
            _b.label = 6;
          case 6:
            return [2 /*return*/];
          case 7:
            if (isResponse(message)) {
              _a = this._callMap.get(message.id), request = _a.request, handler = _a.handler;
              if (!handler) {
                console.error("Received response to unknown request '".concat(message.id, "'"));
                return [2 /*return*/];
              }
              this._callMap.delete(message.id);
              handler(message);
              return [2 /*return*/];
            }
            if (isEvent(message)) {
              if (this.getRpcType(message.name) === 'event') {
                this[message.name](message.object);
                return [2 /*return*/];
              } else {
                console.error("Unsupported event type '".concat(message.name, "' received."));
                return [2 /*return*/];
              }
            }
            if (message.type === 'ping') {
              this.rawSend({
                type: 'pong'
              });
              return [2 /*return*/];
            }
            console.error("Unknown message type from server '".concat(message.type, "'"));
            return [2 /*return*/];
        }
      });
    });
  };
  SocketRPC.prototype.getRpcType = function (name) {
    return Reflect.getMetadata('rpc:type', this.constructor.prototype, name) || 'none';
  };
  SocketRPC.prototype.getRpcCallableOptions = function (name) {
    var _a;
    return (_a = Reflect.getMetadata('rpc:callableOptions', this.constructor.prototype, name)) !== null && _a !== void 0 ? _a : {};
  };
  SocketRPC.prototype.close = function () {
    this._socket.close();
  };
  return SocketRPC;
}();
exports.SocketRPC = SocketRPC;
