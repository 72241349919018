"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.identity = void 0;
function identity(x) {
  return x;
}
exports.identity = identity;
