import { Injectable } from '@angular/core';
import { ApiAppConfig } from '@tytapp/api';
import { environment } from '@tytapp/environment';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AppConfig {
    private _fireAppStatusReady: Function;
    private _appStatusReady = new Promise(resolve => this._fireAppStatusReady = resolve);
    private _appStatus: ApiAppConfig;
    private _environment = environment?.name ?? 'development';

    private _appStatusChanged = new ReplaySubject<ApiAppConfig>(1);
    readonly appStatusChanged = this._appStatusChanged.asObservable();

    public get appStatus() {
        return this._appStatus;
    }

    public get appStatusReady() {
        return this._appStatusReady;
    }

    public set appStatus(value) {
        this._appStatus = value;
        this._appStatusChanged.next(value);
    }

    public async featureEnabled(feature: string): Promise<boolean> {
        await this.appStatusReady;

        if (!this._appStatus)
            return false;

        return this.featureEnabledSync(feature);
    }

    public featureEnabledSync(feature: string): boolean {
        if (!this._appStatus?.features)
            return false;

        return this._appStatus.features.indexOf(feature) >= 0;
    }

    public fireAppStatusReady() {
        this._fireAppStatusReady();
    }

    public get environment() {
        return this._environment;
    }
}