import { Injectable, Inject, RendererFactory2, Renderer2, RendererType2 } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { environment } from '@tytapp/environment';

import { LoggerService } from './logger.service';
import { AppConfig } from './app-config';

@Injectable()
export class Head {
    constructor(
        @Inject(DOCUMENT)
        private _doc: any,
        private rendererFactory: RendererFactory2,
        private logger: LoggerService,
        private appConfig: AppConfig
    ) {
        this.renderer = this.rendererFactory.createRenderer(_doc, null);
    }

    private renderer: Renderer2;

    private _previousCanonicalLink;

    clearCanonicalLink() {
        try {
            if (this._previousCanonicalLink)
                this.renderer.removeChild(this.renderer.parentNode(this._previousCanonicalLink), this._previousCanonicalLink);
            this._previousCanonicalLink = null;
        } catch (e) {
            this.logger.error(`Caught error while resetting canonical URL: ${e}`);
        }
    }

    setCanonicalLink(url: string) {
        url = `${environment.urls.webRoot}${url}`;
        try {
            this.clearCanonicalLink();
            this._previousCanonicalLink = this.addLink('canonical', undefined, url);
        } catch (e) {
            this.logger.error(`Caught error while setting canonical URL to '${url}' (skipping): ${e}`);
        }
    }

    addLink(rel: string, type: string, href: string, title: string = null): any {
        let link = this.renderer.createElement('link');

        if (rel)
            this.renderer.setAttribute(link, 'rel', rel);

        if (type)
            this.renderer.setAttribute(link, 'type', type);

        if (href)
            this.renderer.setAttribute(link, 'href', href);

        if (title)
            this.renderer.setAttribute(link, 'title', title);

        this.renderer.appendChild(this._doc.head, link);

        return link;
    }

    removeLink(link: any) {
        this.renderer.removeChild(this._doc.head, link);
    }

    addRSSLink(href: string, title: string) {
        return this.addLink('alternate', 'application/rss+xml', href, title);
    }
}
