"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeMapTo = void 0;
var mergeMap_1 = require("./mergeMap");
var isFunction_1 = require("../util/isFunction");
function mergeMapTo(innerObservable, resultSelector, concurrent) {
  if (concurrent === void 0) {
    concurrent = Infinity;
  }
  if (isFunction_1.isFunction(resultSelector)) {
    return mergeMap_1.mergeMap(function () {
      return innerObservable;
    }, resultSelector, concurrent);
  }
  if (typeof resultSelector === 'number') {
    concurrent = resultSelector;
  }
  return mergeMap_1.mergeMap(function () {
    return innerObservable;
  }, concurrent);
}
exports.mergeMapTo = mergeMapTo;
