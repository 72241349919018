"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DurableSocket = void 0;
/**
 * Provides a durable WebSocket. Such a socket will automatically handle reconnection including
 * exponential backoff and jitter. It will also enqueue messages while the socket is down and
 * send them once connection is restored.
 *
 * Drop in compatible with WebSocket, with some additional events:
 * - 'lost': fired when the connection goes down
 * - 'restore': fired when the connection is restored after being down
 *
 * The standard open and close events only fire when the connection is initially established
 * and when the socket is intentionally ended by calling close().
 */
var DurableSocket = /** @class */function () {
  function DurableSocket(url, protocols, sessionId) {
    var _this = this;
    this.url = url;
    this.protocols = protocols;
    this.lastPong = 0;
    this.enablePing = true;
    this.pingInterval = 10000;
    this.pingKeepAliveInterval = 25000;
    this._closed = false;
    this._open = false;
    this.reconnectTime = 500; // 2
    this.maxReconnectTime = 30000; // 10000
    this.maxAttempts = 0;
    this.jitter = 0.05;
    this._ready = false;
    this._messageQueue = [];
    this._attempt = 0;
    this._subscribers = new Map();
    this._sessionId = sessionId;
    this.connect();
    this.ready = new Promise(function (resolve, reject) {
      _this.addEventListener('open', function () {
        return resolve(_this);
      });
      _this.addEventListener('close', function (e) {
        if (e.code === 503) {
          //console.error(`Failed to connect to service!`);
          reject(e);
        }
      });
    });
    var wasRestored = function () {};
    this.addEventListener('lost', function () {
      return _this.ready = new Promise(function (res, _) {
        return wasRestored = function () {
          return res(_this);
        };
      });
    });
    this.addEventListener('ready', function (e) {
      return wasRestored();
    });
  }
  /**
   * Wait until this connection is ready to receive a message. If connection is lost, this method will
   * return a new promise that will resolve when connection is restored.
   * @returns
   */
  DurableSocket.prototype.waitUntilReady = function () {
    return this.ready;
  };
  DurableSocket.prototype.connect = function () {
    var _this = this;
    if (this._socket) {
      this.safelyClose(this._socket);
      this._socket = null;
    }
    var socket = new WebSocket(this.urlWithSessionId, this.protocols);
    this._socket = socket;
    this._socket.onopen = function (ev) {
      return _this.handleConnect(socket, ev);
    };
    this._socket.onerror = function (ev) {
      return _this.dispatchSocketEvent(socket, ev);
    };
    this._socket.onclose = function (ev) {
      return _this.handleLost(socket);
    };
    this._socket.onmessage = function (ev) {
      return _this.handleMessage(socket, ev);
    };
  };
  Object.defineProperty(DurableSocket.prototype, "urlWithSessionId", {
    get: function () {
      if (this.sessionId) return "".concat(this.url).concat(this.url.includes('?') ? '&' : '?', "sessionId=").concat(this.sessionId);
      return this.url;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "sessionId", {
    /**
     * Get the session ID assigned to this session by the server.
     * This session ID will be included when reconnecting to allow for
     * state retention even after reconnecting.
     */
    get: function () {
      return this._sessionId;
    },
    enumerable: false,
    configurable: true
  });
  DurableSocket.prototype.safelyClose = function (socket) {
    try {
      socket === null || socket === void 0 ? void 0 : socket.close();
    } catch (e) {
      console.warn("[Banta/DurableSocket] While safely closing an old socket: ".concat(e.message, " [this is unlikely to be a problem]"));
    }
  };
  DurableSocket.prototype.handleConnect = function (socket, ev) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var first, e_1;
      var _this = this;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (this._socket !== socket) {
              this.safelyClose(socket);
              return [2 /*return*/];
            }
            first = !this._open;
            if (!first) return [3 /*break*/, 2];
            return [4 /*yield*/, this.dispatchSocketEvent(socket, ev)];
          case 1:
            _b.sent();
            this._open = true;
            _b.label = 2;
          case 2:
            if (!!first) return [3 /*break*/, 7];
            console.log("[Banta/DurableSocket] Connection is restored [".concat(this.url, "]. Restoring state..."));
            _b.label = 3;
          case 3:
            _b.trys.push([3, 5,, 6]);
            return [4 /*yield*/, this.dispatchSocketEvent(socket, {
              type: 'restore',
              bubbles: false,
              cancelable: false,
              cancelBubble: false,
              composed: false,
              currentTarget: this,
              defaultPrevented: false,
              eventPhase: 0,
              isTrusted: true,
              returnValue: undefined,
              srcElement: undefined,
              target: this,
              timeStamp: Date.now(),
              composedPath: function () {
                return [];
              },
              initEvent: undefined,
              preventDefault: function () {
                this.defaultPrevented = true;
              },
              stopPropagation: function () {},
              stopImmediatePropagation: function () {},
              AT_TARGET: 2,
              BUBBLING_PHASE: 3,
              CAPTURING_PHASE: 1,
              NONE: 0 //Event.NONE
            })];
          case 4:
            _b.sent();
            return [3 /*break*/, 6];
          case 5:
            e_1 = _b.sent();
            console.error("[Banta/DurableSocket] Error restoring state: ".concat(e_1.message, ". Stack: ").concat(e_1.stack));
            console.error("[Banta/DurableSocket] Treating connection as failed due to state restoration error.");
            this.setNotReady();
            this.safelyClose(this._socket);
            try {
              (_a = this._socket) === null || _a === void 0 ? void 0 : _a.close();
            } catch (e) {
              console.error("[Banta/DurableSocket] Failed to close socket after ping failure: ".concat(e.message, " [").concat(this.url, "]"));
            }
            return [2 /*return*/];
          case 6:
            console.log("[Banta/DurableSocket] State restored successfully.");
            _b.label = 7;
          case 7:
            this.setReady(socket);
            this._attempt = 0;
            this._messageQueue.splice(0).forEach(function (m) {
              return _this._socket.send(m);
            });
            this.lastPong = Date.now();
            if (this.enablePing) {
              clearInterval(this.pingTimer);
              this.pingTimer = setInterval(function () {
                var _a;
                if (_this._closed) {
                  clearInterval(_this.pingTimer);
                  return;
                }
                try {
                  _this.sendImmediately(JSON.stringify({
                    type: 'ping'
                  }));
                } catch (e) {
                  console.error("[Banta/DurableSocket] Failed to send ping message. Connection is broken. [".concat(_this.url, "]"));
                  _this.setNotReady();
                  _this.safelyClose(_this._socket);
                  try {
                    (_a = _this._socket) === null || _a === void 0 ? void 0 : _a.close();
                  } catch (e) {
                    console.error("[Banta/DurableSocket] Failed to close socket after ping failure: ".concat(e.message, " [").concat(_this.url, "]"));
                  }
                  return;
                }
                if (_this.lastPong < Date.now() - _this.pingKeepAliveInterval) {
                  console.log("[Banta/DurableSocket] No keep-alive response in ".concat(_this.pingKeepAliveInterval, "ms. Forcing reconnect... [").concat(_this.url, "]"));
                  try {
                    _this.handleLost(socket);
                  } catch (e) {
                    console.error("[Banta/DurableSocket] Failed to close socket after timeout waiting for pong: ".concat(e.message, " [").concat(_this.url, "]"));
                  }
                }
              }, this.pingInterval);
            }
            return [2 /*return*/];
        }
      });
    });
  };
  DurableSocket.prototype.handleMessage = function (socket, ev) {
    if (this._socket !== socket) {
      this.safelyClose(socket);
      return;
    }
    var message = JSON.parse(ev.data);
    if (message.type === 'pong') {
      this.lastPong = Date.now();
      return;
    } else if (message.type === 'setSessionId') {
      this._sessionId = message.id;
    }
    this.dispatchSocketEvent(socket, ev);
  };
  DurableSocket.prototype.handleLost = function (socket) {
    var _a;
    if (this._socket !== socket) {
      this.safelyClose(socket);
      return;
    }
    if (this._closed) return;
    if (this._ready) {
      console.log("[Banta/DurableSocket] Connection Lost [".concat(this.url, "]"));
    }
    this.setNotReady();
    this._attempt += 1;
    clearInterval(this.pingTimer);
    this.pingTimer = null;
    (_a = this._socket) === null || _a === void 0 ? void 0 : _a.close();
    this._socket = null;
    this.dispatchEvent({
      type: 'lost',
      bubbles: false,
      cancelable: false,
      cancelBubble: false,
      composed: false,
      currentTarget: this,
      defaultPrevented: false,
      eventPhase: 0,
      isTrusted: true,
      returnValue: undefined,
      srcElement: undefined,
      target: this,
      timeStamp: Date.now(),
      composedPath: function () {
        return [];
      },
      initEvent: undefined,
      preventDefault: function () {
        this.defaultPrevented = true;
      },
      stopPropagation: function () {},
      stopImmediatePropagation: function () {},
      AT_TARGET: 2,
      BUBBLING_PHASE: 3,
      CAPTURING_PHASE: 1,
      NONE: 0 //Event.NONE
    });
    if (this.maxAttempts > 0 && this._attempt >= this.maxAttempts) {
      this.close(503, 'Service Unavailable');
      return;
    }
    this.attemptToReconnect();
  };
  Object.defineProperty(DurableSocket.prototype, "actualReconnectTime", {
    get: function () {
      var reconnectTime = Math.min(this.maxReconnectTime, this.reconnectTime * this._attempt * 1.5);
      return Math.min(this.maxReconnectTime, reconnectTime + Math.random() * this.jitter * reconnectTime);
    },
    enumerable: false,
    configurable: true
  });
  DurableSocket.prototype.attemptToReconnect = function () {
    var _this = this;
    if (this._ready) return;
    console.log("[Banta/DurableSocket] Waiting ".concat(this.actualReconnectTime, "ms before reconnect (attempt ").concat(this._attempt, ") [").concat(this.url, "]"));
    if (this._reconnectTimeout) {
      console.warn("[Banta/DurableSocket] Warning: Attempt to schedule reconnect when there is already a reconnect timeout outstanding!");
      clearTimeout(this._reconnectTimeout);
    }
    setTimeout(function () {
      if (_this._ready) return;
      _this.connect();
      clearTimeout(_this._reconnectTimeout);
      _this._reconnectTimeout = undefined;
    }, this.actualReconnectTime);
  };
  DurableSocket.prototype.reconnect = function () {
    var _a;
    (_a = this._socket) === null || _a === void 0 ? void 0 : _a.close();
    console.log("[Banta/DurableSocket] Connection is no longer ready.");
    this.setNotReady();
  };
  DurableSocket.prototype.setNotReady = function () {
    if (this._ready) console.log("[Banta/DurableSocket] Connection is no longer ready.");
    this._ready = false;
  };
  DurableSocket.prototype.setReady = function (socket) {
    if (this._ready) console.warn("[Banta/DurableSocket] Connection marked ready, but it was already marked ready");else console.log("[Banta/DurableSocket] Connection is now ready.");
    if (!this._ready) {
      this._ready = true;
      this.dispatchSocketEvent(socket, {
        type: 'ready',
        bubbles: false,
        cancelable: false,
        cancelBubble: false,
        composed: false,
        currentTarget: this,
        defaultPrevented: false,
        eventPhase: 0,
        isTrusted: true,
        returnValue: undefined,
        srcElement: undefined,
        target: this,
        timeStamp: Date.now(),
        composedPath: function () {
          return [];
        },
        initEvent: undefined,
        preventDefault: function () {
          this.defaultPrevented = true;
        },
        stopPropagation: function () {},
        stopImmediatePropagation: function () {},
        AT_TARGET: 2,
        BUBBLING_PHASE: 3,
        CAPTURING_PHASE: 1,
        NONE: 0 //Event.NONE
      });
    }
  };
  Object.defineProperty(DurableSocket.prototype, "binaryType", {
    get: function () {
      return this._socket.binaryType;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "bufferedAmount", {
    get: function () {
      return this._socket.bufferedAmount;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "extensions", {
    get: function () {
      return this._socket.extensions;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "CLOSED", {
    get: function () {
      return WebSocket.CLOSED;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "CLOSING", {
    get: function () {
      return WebSocket.CLOSING;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "CONNECTING", {
    get: function () {
      return WebSocket.CONNECTING;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "OPEN", {
    get: function () {
      return WebSocket.OPEN;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "onclose", {
    get: function () {
      return this._onclose;
    },
    set: function (value) {
      if (this._onclose) this.removeEventListener('close', this._onclose);
      this._onclose = value;
      if (value) this.addEventListener('close', value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "onerror", {
    get: function () {
      return this._onerror;
    },
    set: function (value) {
      if (this._onclose) this.removeEventListener('error', this._onerror);
      this._onerror = value;
      if (value) this.addEventListener('error', value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "onmessage", {
    get: function () {
      return this._onmessage;
    },
    set: function (value) {
      if (this._onclose) this.removeEventListener('message', this._onmessage);
      this._onmessage = value;
      if (value) this.addEventListener('message', value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "onopen", {
    get: function () {
      return this._onopen;
    },
    set: function (value) {
      if (this._onclose) this.removeEventListener('open', this._onopen);
      this._onopen = value;
      if (value) this.addEventListener('open', value);
    },
    enumerable: false,
    configurable: true
  });
  ;
  Object.defineProperty(DurableSocket.prototype, "protocol", {
    get: function () {
      return this._socket.protocol;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(DurableSocket.prototype, "readyState", {
    get: function () {
      return this._socket.readyState;
    },
    enumerable: false,
    configurable: true
  });
  DurableSocket.prototype.close = function (code, reason) {
    var _a;
    this._closed = true;
    (_a = this._socket) === null || _a === void 0 ? void 0 : _a.close(code, reason);
    this._socket = null;
    this.dispatchEvent({
      type: 'close',
      bubbles: false,
      cancelable: false,
      cancelBubble: false,
      composed: false,
      currentTarget: this,
      defaultPrevented: false,
      eventPhase: 0,
      isTrusted: true,
      returnValue: undefined,
      srcElement: undefined,
      target: this,
      timeStamp: Date.now(),
      composedPath: function () {
        return [];
      },
      initEvent: undefined,
      preventDefault: function () {
        this.defaultPrevented = true;
      },
      stopPropagation: function () {},
      stopImmediatePropagation: function () {},
      AT_TARGET: 2,
      BUBBLING_PHASE: 3,
      CAPTURING_PHASE: 1,
      NONE: 0 //Event.NONE
    });
  };
  Object.defineProperty(DurableSocket.prototype, "isReady", {
    get: function () {
      return this._ready;
    },
    enumerable: false,
    configurable: true
  });
  DurableSocket.prototype.send = function (data) {
    if (!this._ready) {
      this._messageQueue.push(data);
    } else {
      this._socket.send(data);
    }
  };
  /**
   * Send a request immediately regardless of ready state. This should only be used during a 'restore' event handler.
   * @param data
   */
  DurableSocket.prototype.sendImmediately = function (data) {
    this._socket.send(data);
  };
  DurableSocket.prototype.addEventListener = function (type, listener, options) {
    var _a;
    this._subscribers.set(type, __spreadArray(__spreadArray([], (_a = this._subscribers.get(type)) !== null && _a !== void 0 ? _a : [], true), [listener], false));
  };
  DurableSocket.prototype.removeEventListener = function (type, listener, options) {
    var _a;
    this._subscribers.set(type, __spreadArray([], ((_a = this._subscribers.get(type)) !== null && _a !== void 0 ? _a : []).filter(function (x) {
      return x !== listener;
    }), true));
  };
  DurableSocket.prototype.dispatchSocketEvent = function (socket, event) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var subs;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (this._socket !== socket) {
              this.safelyClose(socket);
              return [2 /*return*/];
            }
            subs = (_a = this._subscribers.get(event.type)) !== null && _a !== void 0 ? _a : [];
            return [4 /*yield*/, Promise.all(subs.map(function (f) {
              return f(event);
            }))];
          case 1:
            _b.sent();
            return [2 /*return*/, !event.defaultPrevented];
        }
      });
    });
  };
  DurableSocket.prototype.dispatchEvent = function (event) {
    var _a;
    // This one is for WebSocket compatibility. It should not be used internally.
    var subs = (_a = this._subscribers.get(event.type)) !== null && _a !== void 0 ? _a : [];
    subs.forEach(function (f) {
      return f(event);
    });
    return !event.defaultPrevented;
  };
  return DurableSocket;
}();
exports.DurableSocket = DurableSocket;
