import { Location } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { environment } from '@tytapp/environment';
import * as marked from 'marked';
import twemoji from 'twemoji';


const underline = {
    name: 'underline',
    level: 'inline', // Is this a block-level or inline-level tokenizer?
    start(src) { return src.match(/\+\+/)?.index; }, // Hint to Marked.js to stop and check for a match
    tokenizer(src, tokens) {
        const rule = /^\+\+(.*?)\+\+/; // Regex for the complete token
        const match = rule.exec(src);
        if (match) {
            return { // Token to generate
                type: 'underline', // Should match "name" above
                raw: match[0], // Text to consume from the source
                text: this.lexer.inlineTokens(match[1].trim()), // Additional custom properties
            };
        }
    },
    renderer(token) {
        return `<u>${this.parser.parseInline(token.text)}</u>`;
    }
};

marked.marked.use({
    extensions: [underline]
});

@Pipe({
    name: 'markdownToHtml'
})
export class MarkdownToHtmlPipe implements PipeTransform {
    location = inject(Location);

    constructor() {

        this.internalContentRenderer = new marked.Renderer();
        const internalLinkRenderer = this.internalContentRenderer.link;
        this.internalContentRenderer.link = (token) => {
            let { href, title, text } = token;

            if (href.startsWith('#'))
                href = `${this.location.path(false)}${href}`;
            let html = internalLinkRenderer.call(this.internalContentRenderer, token);

            if (!href.startsWith('/') && !href.startsWith(environment.urls.webRoot))
                html = html.replace(/^<a /, '<a target="_blank" rel="noopener nofollow" ');

            if (href === text)
                html = html.replace(/^<a /, '<a class="has-url-text" ');

            return html;
        };

        this.userContentRenderer = new marked.Renderer();
        const externalLinkRenderer = this.userContentRenderer.link;
        this.userContentRenderer.link = (token) => {
            if (token.href.startsWith('#'))
                token.href = `${this.location.path(false)}${token.href}`;
            const html = externalLinkRenderer.call(this.userContentRenderer, token);

            if (!token.href.startsWith('mailto') && !token.href.startsWith('#')) {
                if (!token.href.startsWith(environment.urls.webRoot)) {
                    return html.replace(/^<a /, '<a target="_blank" rel="noopener nofollow" ');
                } else if (this.allLinksTargetBlank) {
                    return html
                        .replace(/^<a /, '<a target="_blank" ')
                        .replace(/(href="[^"]+)(")/, '$1?no-terms$2');
                }
            }

            return html;
        };
    }

    internalContentRenderer: marked.Renderer;
    userContentRenderer: marked.Renderer;
    allLinksTargetBlank: boolean;

    transform(value: string, kind = 'internal'): string {
        if (!value)
            return '';

        let renderer: marked.Renderer;

        if (kind.includes(':linksOpenInNewTab')) {
            this.allLinksTargetBlank = true;
            kind = kind.replace(/:linksOpenInNewTab/, '');
        }

        if (kind === 'internal') {
            renderer = this.internalContentRenderer
        } else {
            renderer = this.userContentRenderer;
        }

        value = marked.marked.parse(value, { renderer }) as string;
        value = twemoji.parse(value, { base: '/assets/twemoji/' });

        return value;
    }
}
