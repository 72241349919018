import { Location, isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { REQUEST, RESPONSE } from '@tytapp/express.tokens';

import { LoggerService } from './logger.service';

export interface FormHandlingComponent {
    submit();
}

export function FormInput(inputName: string = null) {
    return (target: any, propertyKey: any) => {
        if (Reflect.defineMetadata)
            Reflect.defineMetadata('form:input-name', propertyKey, target, inputName || propertyKey);
    };
}

@Injectable()
export class FormHandler {
    private request = inject(REQUEST, { optional: true });
    private response = inject(RESPONSE, { optional: true });
    private platformId = inject(PLATFORM_ID);
    private location = inject(Location);
    private logger = inject(LoggerService);

    readonly isServer: boolean = isPlatformServer(this.platformId);

    public get currentPath() {
        return this.request
            ? this.request.path
            : this.location.path();
    }

    async handle(cmp: FormHandlingComponent) {
        // Handle server-side form
        if (!this.isSubmission())
            return;

        if (this.request['__formHandled'])
            return;
        this.request['__formHandled'] = true;

        this.applyData(cmp, this.getData());
        await cmp.submit();
    }

    applyData(cmp: FormHandlingComponent, data: any) {
        for (let key of Object.keys(data)) {
            let propertyKey = Reflect.getMetadata('form:input-name', cmp, key);

            if (!propertyKey) {
                if (this.request)
                    this.logger.warning(`[FormHandler] Unknown parameter '${key}' is being ignored during request ${this.request.method} ${this.request.url}`);
                continue;
            }

            cmp[propertyKey] = data[key];
        }
    }

    isSubmission() {

        if (isPlatformServer(this.platformId) && !this.request) {
            throw new Error(`Error: We are on the server platform but no request object is available.`);
        }

        if (!this.request) {
            return false;
        }

        return this.request.method.toLowerCase() == 'post';
    }

    getData() {
        if (!this.request)
            throw new Error('Cannot get data on client side');
        return this.request.body;
    }

    redirect(url: string) {
        if (!this.response)
            throw new Error('Cannot redirect on client side');
        this.response.redirect(url);
    }
}