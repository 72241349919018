import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    @Input()
    @HostBinding('class.inline')
    inline: boolean = false;

    @Input()
    color: string = '#07d';
}
