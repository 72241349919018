import { Pipe } from '@angular/core';

/**
 * Takes a date string and parses it as UTC
 */
@Pipe({ name: 'dateAsUTC' })
export class DateAsUTCDirective {
    constructor(
    ) {
    }

    transform(value: string | Date) {
        let date = value instanceof Date ? value : new Date(value);
        return new Date(date.getTime() + date.getTimezoneOffset() * 1000 * 60);
    }
}