"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pairwise = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function pairwise() {
  return lift_1.operate(function (source, subscriber) {
    var prev;
    var hasPrev = false;
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      var p = prev;
      prev = value;
      hasPrev && subscriber.next([p, value]);
      hasPrev = true;
    }));
  });
}
exports.pairwise = pairwise;
