"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeoutWith = void 0;
var async_1 = require("../scheduler/async");
var isDate_1 = require("../util/isDate");
var timeout_1 = require("./timeout");
function timeoutWith(due, withObservable, scheduler) {
  var first;
  var each;
  var _with;
  scheduler = scheduler !== null && scheduler !== void 0 ? scheduler : async_1.async;
  if (isDate_1.isValidDate(due)) {
    first = due;
  } else if (typeof due === 'number') {
    each = due;
  }
  if (withObservable) {
    _with = function () {
      return withObservable;
    };
  } else {
    throw new TypeError('No observable provided to switch to');
  }
  if (first == null && each == null) {
    throw new TypeError('No timeout provided.');
  }
  return timeout_1.timeout({
    first: first,
    each: each,
    scheduler: scheduler,
    with: _with
  });
}
exports.timeoutWith = timeoutWith;
