"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.count = void 0;
var reduce_1 = require("./reduce");
function count(predicate) {
  return reduce_1.reduce(function (total, value, i) {
    return !predicate || predicate(value, i) ? total + 1 : total;
  }, 0);
}
exports.count = count;
