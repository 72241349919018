import { Observable, Subscription } from "rxjs";

export class SubscriptionSet {
    private _subscriptions: Subscription[] = [];
    subscribe<T>(observable: Observable<T>, handler: (t: T) => void) {
        this._subscriptions.push(observable.subscribe(handler));
    }

    add(subscription: Subscription) {
        this._subscriptions.push(subscription);
    }

    unsubscribeAll() {
        this._subscriptions.forEach(x => x.unsubscribe());
        this._subscriptions = [];
    }
}