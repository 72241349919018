"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.animationFrames = void 0;
var Observable_1 = require("../../Observable");
var performanceTimestampProvider_1 = require("../../scheduler/performanceTimestampProvider");
var animationFrameProvider_1 = require("../../scheduler/animationFrameProvider");
function animationFrames(timestampProvider) {
  return timestampProvider ? animationFramesFactory(timestampProvider) : DEFAULT_ANIMATION_FRAMES;
}
exports.animationFrames = animationFrames;
function animationFramesFactory(timestampProvider) {
  return new Observable_1.Observable(function (subscriber) {
    var provider = timestampProvider || performanceTimestampProvider_1.performanceTimestampProvider;
    var start = provider.now();
    var id = 0;
    var run = function () {
      if (!subscriber.closed) {
        id = animationFrameProvider_1.animationFrameProvider.requestAnimationFrame(function (timestamp) {
          id = 0;
          var now = provider.now();
          subscriber.next({
            timestamp: timestampProvider ? now : timestamp,
            elapsed: now - start
          });
          run();
        });
      }
    };
    run();
    return function () {
      if (id) {
        animationFrameProvider_1.animationFrameProvider.cancelAnimationFrame(id);
      }
    };
  });
}
var DEFAULT_ANIMATION_FRAMES = animationFramesFactory();
