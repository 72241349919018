import { Injectable } from '@angular/core';

@Injectable()
export class NetworkInformation {

    get connection(): [string, number] {
        var connectionType = 'Unknown';
        var downlinkMax: number = Infinity;

        if (navigator['connection']) {
            connectionType = navigator['connection'].type;

            if (navigator['connection']['downlinkMax']) {
                downlinkMax = navigator['connection']['downlinkMax'];
            }
        }

        return [connectionType, downlinkMax];
    }
}