"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ignoreElements = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
var noop_1 = require("../util/noop");
function ignoreElements() {
  return lift_1.operate(function (source, subscriber) {
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, noop_1.noop));
  });
}
exports.ignoreElements = ignoreElements;
