import { Directive, ElementRef, inject } from '@angular/core';
import { IFrameResizer } from './iframe-resizer';
import { isClientSide } from '@tytapp/environment-utils';

@Directive({
    selector: 'iframe:not(.disable-iframe-resizer)'
})
export class IframeAutoResizeDirective {
    iframeResizer = inject(IFrameResizer);
    hostElement = inject(ElementRef) as ElementRef<HTMLIFrameElement>;

    ngAfterViewInit() {
        if (isClientSide())
            this.iframeResizer.install(this.hostElement.nativeElement);
    }

}