"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.observeNotification = exports.Notification = exports.NotificationKind = void 0;
var empty_1 = require("./observable/empty");
var of_1 = require("./observable/of");
var throwError_1 = require("./observable/throwError");
var isFunction_1 = require("./util/isFunction");
var NotificationKind;
(function (NotificationKind) {
  NotificationKind["NEXT"] = "N";
  NotificationKind["ERROR"] = "E";
  NotificationKind["COMPLETE"] = "C";
})(NotificationKind = exports.NotificationKind || (exports.NotificationKind = {}));
var Notification = function () {
  function Notification(kind, value, error) {
    this.kind = kind;
    this.value = value;
    this.error = error;
    this.hasValue = kind === 'N';
  }
  Notification.prototype.observe = function (observer) {
    return observeNotification(this, observer);
  };
  Notification.prototype.do = function (nextHandler, errorHandler, completeHandler) {
    var _a = this,
      kind = _a.kind,
      value = _a.value,
      error = _a.error;
    return kind === 'N' ? nextHandler === null || nextHandler === void 0 ? void 0 : nextHandler(value) : kind === 'E' ? errorHandler === null || errorHandler === void 0 ? void 0 : errorHandler(error) : completeHandler === null || completeHandler === void 0 ? void 0 : completeHandler();
  };
  Notification.prototype.accept = function (nextOrObserver, error, complete) {
    var _a;
    return isFunction_1.isFunction((_a = nextOrObserver) === null || _a === void 0 ? void 0 : _a.next) ? this.observe(nextOrObserver) : this.do(nextOrObserver, error, complete);
  };
  Notification.prototype.toObservable = function () {
    var _a = this,
      kind = _a.kind,
      value = _a.value,
      error = _a.error;
    var result = kind === 'N' ? of_1.of(value) : kind === 'E' ? throwError_1.throwError(function () {
      return error;
    }) : kind === 'C' ? empty_1.EMPTY : 0;
    if (!result) {
      throw new TypeError("Unexpected notification kind " + kind);
    }
    return result;
  };
  Notification.createNext = function (value) {
    return new Notification('N', value);
  };
  Notification.createError = function (err) {
    return new Notification('E', undefined, err);
  };
  Notification.createComplete = function () {
    return Notification.completeNotification;
  };
  Notification.completeNotification = new Notification('C');
  return Notification;
}();
exports.Notification = Notification;
function observeNotification(notification, observer) {
  var _a, _b, _c;
  var _d = notification,
    kind = _d.kind,
    value = _d.value,
    error = _d.error;
  if (typeof kind !== 'string') {
    throw new TypeError('Invalid notification, missing "kind"');
  }
  kind === 'N' ? (_a = observer.next) === null || _a === void 0 ? void 0 : _a.call(observer, value) : kind === 'E' ? (_b = observer.error) === null || _b === void 0 ? void 0 : _b.call(observer, error) : (_c = observer.complete) === null || _c === void 0 ? void 0 : _c.call(observer);
}
exports.observeNotification = observeNotification;
