import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'newlinesToHtml'
})
export class NewlinesToHtmlPipe implements PipeTransform {

    constructor() { }

    transform(value: string): any {
        return value.replace(/\n/g, "<br/>\n");
    }
}