"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.concatMapTo = void 0;
var concatMap_1 = require("./concatMap");
var isFunction_1 = require("../util/isFunction");
function concatMapTo(innerObservable, resultSelector) {
  return isFunction_1.isFunction(resultSelector) ? concatMap_1.concatMap(function () {
    return innerObservable;
  }, resultSelector) : concatMap_1.concatMap(function () {
    return innerObservable;
  });
}
exports.concatMapTo = concatMapTo;
