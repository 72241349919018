"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.map = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function map(project, thisArg) {
  return lift_1.operate(function (source, subscriber) {
    var index = 0;
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      subscriber.next(project.call(thisArg, value, index++));
    }));
  });
}
exports.map = map;
