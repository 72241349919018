"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combineLatestInit = exports.combineLatest = void 0;
var Observable_1 = require("../Observable");
var argsArgArrayOrObject_1 = require("../util/argsArgArrayOrObject");
var from_1 = require("./from");
var identity_1 = require("../util/identity");
var mapOneOrManyArgs_1 = require("../util/mapOneOrManyArgs");
var args_1 = require("../util/args");
var createObject_1 = require("../util/createObject");
var OperatorSubscriber_1 = require("../operators/OperatorSubscriber");
var executeSchedule_1 = require("../util/executeSchedule");
function combineLatest() {
  var args = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    args[_i] = arguments[_i];
  }
  var scheduler = args_1.popScheduler(args);
  var resultSelector = args_1.popResultSelector(args);
  var _a = argsArgArrayOrObject_1.argsArgArrayOrObject(args),
    observables = _a.args,
    keys = _a.keys;
  if (observables.length === 0) {
    return from_1.from([], scheduler);
  }
  var result = new Observable_1.Observable(combineLatestInit(observables, scheduler, keys ? function (values) {
    return createObject_1.createObject(keys, values);
  } : identity_1.identity));
  return resultSelector ? result.pipe(mapOneOrManyArgs_1.mapOneOrManyArgs(resultSelector)) : result;
}
exports.combineLatest = combineLatest;
function combineLatestInit(observables, scheduler, valueTransform) {
  if (valueTransform === void 0) {
    valueTransform = identity_1.identity;
  }
  return function (subscriber) {
    maybeSchedule(scheduler, function () {
      var length = observables.length;
      var values = new Array(length);
      var active = length;
      var remainingFirstValues = length;
      var _loop_1 = function (i) {
        maybeSchedule(scheduler, function () {
          var source = from_1.from(observables[i], scheduler);
          var hasFirstValue = false;
          source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
            values[i] = value;
            if (!hasFirstValue) {
              hasFirstValue = true;
              remainingFirstValues--;
            }
            if (!remainingFirstValues) {
              subscriber.next(valueTransform(values.slice()));
            }
          }, function () {
            if (! --active) {
              subscriber.complete();
            }
          }));
        }, subscriber);
      };
      for (var i = 0; i < length; i++) {
        _loop_1(i);
      }
    }, subscriber);
  };
}
exports.combineLatestInit = combineLatestInit;
function maybeSchedule(scheduler, execute, subscription) {
  if (scheduler) {
    executeSchedule_1.executeSchedule(subscription, scheduler, execute);
  } else {
    execute();
  }
}
