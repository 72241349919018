"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeMap = void 0;
var map_1 = require("./map");
var innerFrom_1 = require("../observable/innerFrom");
var lift_1 = require("../util/lift");
var mergeInternals_1 = require("./mergeInternals");
var isFunction_1 = require("../util/isFunction");
function mergeMap(project, resultSelector, concurrent) {
  if (concurrent === void 0) {
    concurrent = Infinity;
  }
  if (isFunction_1.isFunction(resultSelector)) {
    return mergeMap(function (a, i) {
      return map_1.map(function (b, ii) {
        return resultSelector(a, b, i, ii);
      })(innerFrom_1.innerFrom(project(a, i)));
    }, concurrent);
  } else if (typeof resultSelector === 'number') {
    concurrent = resultSelector;
  }
  return lift_1.operate(function (source, subscriber) {
    return mergeInternals_1.mergeInternals(source, subscriber, project, concurrent);
  });
}
exports.mergeMap = mergeMap;
