import { inject, Injectable, Type } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { isServerSide } from '@tytapp/environment-utils';

import { DialogBackerComponent } from './dialog-backer.component';
import { DialogComponent } from './dialog-component';

import { Shell } from './shell';

type DialogParams<T> = { [Index in keyof T]: T[Index] | `:${string}` };

export function DialogRoute<T extends DialogComponent>(path: string, dialogClass: Type<T>, params: Extract<DialogParams<Parameters<T['init']>>, any[]>, canActivate?: any[]): Route {
    return {
        path,
        component: DialogBackerComponent,
        canActivate: [...(canActivate ?? []), DialogGuard2(dialogClass, params)]
    }
}

export function DialogGuard2<T extends DialogComponent>(dialogClass: Type<T>, params: Extract<DialogParams<Parameters<T['init']>>, any[]>) {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const shell = inject(Shell);
        const router = inject(Router);

        const resolveParam = (param: any) => {
            if (typeof param === 'string' && param.startsWith(':')) {
                return route.paramMap.get(param.slice(1));
            }

            return param;
        };

        return (async () => {
            let firstRoute = shell.firstRoute;

            setTimeout(async () => {
                let routeName = route.data.dialog;
                let resolvedParams = <Parameters<T['init']>>params.map(p => resolveParam(p));
                if (firstRoute) {
                    shell.animateToDialog(dialogClass, ...resolvedParams);
                } else {
                    shell.showDialog(dialogClass, ...resolvedParams);
                }
            }, 1);

            if (route.data.allowNavigate || firstRoute)
                return true;

            if (isServerSide())
                router.navigateByUrl('/home');
            return false;
        })();
    };
}

@Injectable()
export class DialogGuard  {
    constructor(
        private shell: Shell,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let firstRoute = this.shell.firstRoute;

        setTimeout(async () => {
            let routeName = route.data.dialog;
            if (firstRoute) {
                this.shell.animateToDialog(routeName, route.params);
            } else {
                this.shell.showDialog(routeName, route.params);
            }
        }, 1);

        if (route.data.allowNavigate || firstRoute)
            return true;

        if (isServerSide())
            this.router.navigateByUrl('/home');
        return false;
    }
}
