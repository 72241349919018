import { Directive, ElementRef, Input, Optional } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[autosize]'
})
export class AutosizeDirective {
    constructor(
        private elementRef: ElementRef<HTMLTextAreaElement>,
        @Optional() private model: NgModel
    ) {
        elementRef.nativeElement.addEventListener('keydown', e => this.preventMoving(e));
    }

    /**
     * Specify where the scroll container is. This is necessary to stop
     * the scroll position from jumping when we remove the height of the
     * element while determining the best automatic size.
     */
    @Input()
    scrollContainer: 'document' | 'body' | HTMLElement = 'document';

    private get _scrollContainer() {
        if (this.scrollContainer === 'document')
            return document.documentElement;
        else if (this.scrollContainer === 'body')
            return document.body;
        else
            return this.scrollContainer;
    }

    get element() {
        return this.elementRef.nativeElement;
    }

    applyHeight() {
        let top = document.documentElement.scrollTop;
        this.element.style.height = '';
        this.element.style.height = `${this.element.scrollHeight}px`;
        document.documentElement.scrollTop = top;
    }

    ngOnInit() {
        if (this.model) {
            this.model.valueChanges.subscribe(v => {
                this.applyHeight();
            });
        }
    }

    /**
     * Chrome (and other browsers) will try change the document scroll position when you hit enter. It is
     * trying to put the cursor at the top of the screen, which is covered by our fixed header. This behavior
     * is confusing for users, and makes it hard to use the editor when the textarea is very long.
     *
     * To avoid this, we take over the newline behavior so that the browser doesn't have an opportunity to do this.
     *
     * https://stackoverflow.com/questions/56329625/preventing-textarea-scroll-behaviour-in-chrome-after-newline-added/56330204#56330204
     * @param e KeyboardEvent
     */
    preventMoving(e: KeyboardEvent) {
        let key = (e.keyCode ? e.keyCode : e.which);
        let textarea = this.element;

        if(key == 13) {
            e.preventDefault();

            var startPos = textarea.selectionStart;
            var endPos = textarea.selectionEnd;
            let text = "\n";

            textarea.value = textarea.value.substring(0, startPos) +
              text +
              textarea.value.substring(endPos, textarea.value.length);
            textarea.selectionStart = startPos + text.length;
            textarea.selectionEnd = startPos + text.length;

            this.applyHeight();
            this.model.update.next(textarea.value);
        }
    }
}