import { Pipe } from "@angular/core";

@Pipe({
    name: 'summarize'
})
export class SummarizePipe {
    transform(value: string) {
        if (!value)
            return value;

        value = value.replace(/https?:\/\/[^ ]+/g, '');
        value = value.replace(/[^ ]{30,1000}/g, '');

        value = value.substr(0, 250);
        return value;
    }
}