"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.every = void 0;
var lift_1 = require("../util/lift");
var OperatorSubscriber_1 = require("./OperatorSubscriber");
function every(predicate, thisArg) {
  return lift_1.operate(function (source, subscriber) {
    var index = 0;
    source.subscribe(OperatorSubscriber_1.createOperatorSubscriber(subscriber, function (value) {
      if (!predicate.call(thisArg, value, index++, source)) {
        subscriber.next(false);
        subscriber.complete();
      }
    }, function () {
      subscriber.next(true);
      subscriber.complete();
    }));
  });
}
exports.every = every;
