import { Injectable, InjectionToken, inject } from '@angular/core';
import { Router } from '@angular/router';
import { isClientSide } from '@tytapp/environment-utils';
import { RESPONSE } from '@tytapp/express.tokens';

import { LoggerService } from './logger.service';

export const SSR_REDIRECTION = new InjectionToken<RedirectionSSR>('SSR_REDIRECTION');

interface RedirectionSSR {
    url: string;
}

@Injectable()
export class Redirection {
    private router = inject(Router);
    private response = inject(RESPONSE, { optional: true });
    private ssrRedirection = inject(SSR_REDIRECTION, { optional: true });
    private logger = inject(LoggerService);

    /**
     * Perform a universal (CSR/SSR) navigation from the current URL to the given URL, assuming that this navigation is
     * being performed as part of responding to an existing navigation (ie a redirect).
     * - Retain UTM tagging and Google click / Facebook click query parameters for analytics tracking
     * - Handle SSR states (302 Found) correctly
     * - Perform local (Angular) navigations when possible to avoid full app refreshes (including handling query parameters)
     *
     * If you wish to take advantage of the query parameter handling, but do not want the new navigation to be performed
     * as a redirection, make sure to pass replaceUrl as false.
     *
     * @param url
     * @param replaceUrl Whether this is an actual redirect action, or whether this is just a universal navigation.
     *                   Probably should only be used by the app's external link rewriting functionality (see
     *                   NavComponent.rewriteExternalLinks())
     * @returns
     */
    go(url: string, replaceUrl = true) {
        if (!url)
            return;

        let external = /^https?:/.test(url) || /^\/\//.test(url);

        // When we are performing an actual redirect (and thus the navigated URL should be replaced),
        // we need to consider query parameters for campaign tracking for facebook and Google Analytics.
        // For instance if /live?utm_campaign=blah is requested and we redirect to /live/streams/12345
        // (without the utm_campaign), Google Analytics will lose the UTM information within the
        // resulting pageview events. Same is true of the Facebook ads SDK and the fbclk query parameter.
        // This is because these run on the client side only.

        if (replaceUrl) {
            let fromUrl = this.router.routerState.snapshot.url;

            let passthroughQueryParams = ['gclid', 'fbclid', /^utm_/];

            let fromUrlObject = new URL(`https://example.com${fromUrl}`);
            let urlObject: URL;
            if (external)
                urlObject = new URL(url);
            else
                urlObject = new URL(`https://example.com${url}`);

            fromUrlObject.searchParams
                .forEach((value, key) => {
                    if (urlObject.searchParams.has(key))
                        return;

                    if (passthroughQueryParams.some(p => typeof p === 'string' ? key === p : p.test(key)))
                        urlObject.searchParams.set(key, value)
                })
            ;

            if (external)
                url = urlObject.toString();
            else
                url = urlObject.pathname + urlObject.search;
        }

        if (external) {
            // External link

            if (this.ssrRedirection) {
                this.ssrRedirection.url = url;
            } else if (this.response) {
                if (this.response.headersSent) {
                    this.logger.error('Failed to redirect: Headers already sent.');
                    return;
                }
                this.response.redirect(url);
            } else if (isClientSide()) {
                if (replaceUrl)
                    window.location.replace(url);
                else
                    window.location.assign(url);
            } else {
                this.logger.error(' x Cannot redirect: No viable redirect mechanism is available.');
            }


        } else {
            // Internal link.

            if (this.ssrRedirection) {
                this.ssrRedirection.url = url;
            } else if (this.response) {
                if (this.response.headersSent) {
                    this.logger.error('Failed to redirect: Headers already sent.');
                    return;
                }
                this.response.redirect(url);
            } else if (isClientSide()) {
                this.router.navigateByUrl(url, { replaceUrl });
            } else {
                this.logger.error(' x Cannot redirect [internal]: No viable redirect mechanism is available.');
            }
        }
    }
}
