import { Directive, HostListener, inject } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Apply to an element to cause all links within that element to be handled as router links
 * (even if they were inserted as non-Angular HTML).
 */
@Directive({
    selector: '[linksToRouter]'
})
export class LinksToRouterDirective {
    router = inject(Router);

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        const {
            button,
            altKey, ctrlKey, metaKey, shiftKey,
            target,
        } = event;

        if (
            button === 0 &&
            !(altKey || ctrlKey || metaKey || shiftKey) &&
            target instanceof HTMLAnchorElement &&
            target.origin === location.origin
        ) {
            event.preventDefault();
            this.router.navigateByUrl(target.pathname);
        }
    }
}
