"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.joinAllInternals = void 0;
var identity_1 = require("../util/identity");
var mapOneOrManyArgs_1 = require("../util/mapOneOrManyArgs");
var pipe_1 = require("../util/pipe");
var mergeMap_1 = require("./mergeMap");
var toArray_1 = require("./toArray");
function joinAllInternals(joinFn, project) {
  return pipe_1.pipe(toArray_1.toArray(), mergeMap_1.mergeMap(function (sources) {
    return joinFn(sources);
  }), project ? mapOneOrManyArgs_1.mapOneOrManyArgs(project) : identity_1.identity);
}
exports.joinAllInternals = joinAllInternals;
